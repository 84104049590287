import SkeletonComponent from '@atoms/SkeletonComponent';
import appStyles from '@root/appStyles';
import { View } from 'native-base';
import React from 'react';

const ShimmerMultiColumnNominal: React.FC = () => (
  <View>
    {[...Array(4)].map(o => (
      <View key={o} flexDirection="row">
        <SkeletonComponent style={appStyles.flex1} />
        <SkeletonComponent style={appStyles.flex1} />
      </View>
    ))}
  </View>
);

export default ShimmerMultiColumnNominal;
