import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  btnSmsStyles: {
    height: 48,
    marginLeft: scale(8),
  },
});
export default styles;
