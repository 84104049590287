import colors from '@theme/colors';
import { StyleSheet, ViewStyle } from 'react-native';

interface Errors {
  amount?: string;
  [key: string]: any;
}

export const getAmountInputStyle = (errors: Errors): ViewStyle => ({
  flexDirection: 'row',
  borderRadius: 8,
  borderWidth: 1,
  borderColor: errors && errors.amount ? colors.error.rose : colors.neutral.darkMist,
  alignItems: 'center',
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default styles;
