import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  btnSmsStyles: {
    borderColor: colors.secondary.orchid,
    borderWidth: 1,
    height: 48,
    marginTop: scale(10),
  },
});
export default styles;
