import colors from '@theme/colors';
import { scale, verticalScale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  errorStyles: { marginHorizontal: 16, marginTop: 24 },
  otpDefault: {
    borderColor: colors.disabled.smoke,
    borderRadius: scale(12),
    borderWidth: 1,
    color: colors.neutral.charcoal,
    fontFamily: 'Nunito-Bold',
    fontSize: scale(24),
    height: scale(62),
    lineHeight: verticalScale(36),
    paddingTop: 8,
    width: scale(62),
  },
  otpGroup: {
    height: scale(62),
  },
  otpHightLight: {
    borderColor: colors.secondary.orchid,
  },

  parent: {
    backgroundColor: colors.neutral.cotton,
    flex: 1,
    marginHorizontal: scale(16),
    marginTop: scale(150),
  },
  phoneInputs: {
    fontSize: scale(30),
    marginTop: scale(20),
  },
  pinTitleContainer: {
    alignSelf: 'center',
    fontSize: scale(16),
    marginVertical: 24,
  },
  pinTitleDescription: {
    alignSelf: 'center',
    fontSize: scale(14),
    marginVertical: 24,
  },
});

export default styles;
