import { MaterialIcons } from '@expo/vector-icons';
import navigationConstants from '@navigation/navigationConstants';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { View, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import styles from './styles';

type NavigationProp = {
  navigate: (screen: string, params?: Record<string, any>) => void;
};

function SecurityPinBanner(): JSX.Element {
  const navigation = useNavigation<NavigationProp>();
  const onPressSecurityBannerCallBack = () =>
    navigation.navigate(navigationConstants.GG_CREATE_PIN, { type: 'create', source: 'pin_creation' });

  const { t } = useTranslation('common');

  return (
    <TouchableOpacity testID="btnSecurityPinBanner" onPress={onPressSecurityBannerCallBack}>
      <View style={styles.bannerContainer}>
        <MaterialIcons name="lock-open" size={24} color={colors.warning.sunflower} />
        <View style={appStyles.marginLeft(8)}>
          <Text variant="sm-bold" color={colors.neutral.charcoal}>
            {t('securityPin.bannerTitle')}
          </Text>
          <Text variant="sm-bold" color={colors.secondary.orchid} underline>
            {t('securityPin.setPinText')}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}

export default SecurityPinBanner;
