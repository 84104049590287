import config from '@config';
import { trackEngagement } from '@utils/engagement/engagementUtils';
import { analytics, logEvent, setUserId, setUserProperties } from '@utils/setup.web';

const AmplitudeHelper = {
  init: () => {
    ///  amplitude.init(config.AMPLITUDE_API_KEY);
  },
  logEvent: (eventName, data) => {
    logEvent(analytics, eventName, data);
    trackEngagement(data, eventName);
  },
  setUserId: (userID, employerID) => {
    setUserId(analytics, userID.toString());
    setUserProperties(analytics, {
      environment: config.ENVIRONMENT,
      employerId: employerID,
    });
  },
  setLocation: async () => {
    // lat, lng
    // amplitude.setUserProperties({
    //   Latitude: lat,
    //   Longitude: lng,
    // });
  },
  setUserProperties: data => {
    setUserProperties(analytics, data);
  },
  // setCustomAttributes: data => {},
  clearUserProperties: () => {
    setUserId(analytics, '');
    // amplitude.regenerateDeviceId();
    // amplitude.clearUserProperties();
  },
};

export default AmplitudeHelper;
