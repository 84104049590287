import { Skeleton } from '@rneui/base';
import { Stack, StackProps } from 'native-base';
import React from 'react';
import styles from './styles';

interface SkeletonComponentProps extends StackProps {
  testID?: string;
}

const SkeletonComponent: React.FC<SkeletonComponentProps> = props => {
  return (
    <Stack testID="skeletonComponent" row align="center" spacing={4} {...props}>
      <Skeleton animation="wave" style={styles.skeletonParent} />
    </Stack>
  );
};

export default SkeletonComponent;
