import ScreenNames from '@navigation/navigationConstants';
import secureStore from '@utils/secureStore';
import { Platform } from 'react-native';
import SplashScreen from 'react-native-splash-screen';

export async function initialScreen(): Promise<string> {
  // check if token exists in storage, if yes return HOME, else LOGIN
  try {
    const accessToken: string | null = await secureStore.getItemAsync('accessToken');

    if (accessToken && accessToken.length > 0) {
      return ScreenNames.HOME;
    }
    return ScreenNames.LOGIN;
  } catch (e) {
    return ScreenNames.LOGIN;
  }
}

export function shouldHideSplashScreen(): void {
  if (Platform.OS !== 'web') {
    SplashScreen.hide();
  }
}
