import BackdropLoader from '@atoms/BackdropLoader';
import ConnectivityListener from '@atoms/ConnectivityListener';
import AccessFromPartner from '@molecules/AccessFromPartner';
import LoanDetails from '@molecules/Loan/LoanDetails';
import LoanListing from '@molecules/Loan/LoanListing';
import { createNativeStackNavigator, NativeStackNavigationOptions } from '@react-navigation/native-stack';
import ChatScreen from '@screens/ChatScreen';
import GGAddBankScreen from '@screens/GGAddBankScreen';
import GGAddPhoneScreen from '@screens/GGAddPhoneScreen';
import GGAllBanks from '@screens/GGAllBanks';
import GGAutoDisburseTerms from '@screens/GGAutoDisburseTerms';
import GGBankListWithDefaultBank from '@screens/GGBankListWithDefaultBank';
import GGBankTransfer from '@screens/GGBankTransfer';
import GGBPSuccess from '@screens/GGBillPayments/GGBPSuccess';
import GGEditTransaction from '@screens/GGBillPayments/GGEditTransactions';
import GGNominals from '@screens/GGBillPayments/GGNominals';
import GGPascabayar from '@screens/GGBillPayments/GGPascabayar';
import GGPdam from '@screens/GGBillPayments/GGPdam';
import GGRegions from '@screens/GGBillPayments/GGRegions';
import GGSubProducts from '@screens/GGBillPayments/GGSubProducts';
import GGTagihanPLN from '@screens/GGBillPayments/GGTagihanPLN';
import GGVoucherDetails from '@screens/GGBillPayments/GGVoucherDetails';
import GGCreatePin from '@screens/GGCreatePin';
import GGEnrolledPhones from '@screens/GGEnrolledPhones';
import GGGoldTerms from '@screens/GGGold/GGGoldTerms';
import GoldKTP from '@screens/GGGold/GoldKTP';
import LoanInfo from '@screens/GGLoan/LoanInfo';
import LoanTerms from '@screens/GGLoan/LoanTerms';
import GGLogin from '@screens/GGLogin';
import GGOTP from '@screens/GGOTP';
import GGPhoneChange from '@screens/GGPhoneNumberChange';
import GGPhoneChangeConfirm from '@screens/GGPhoneNumberConfirm';
import GGReferralPage from '@screens/GGReferralPage';
import GGSalaryInformation from '@screens/GGSalaryInformation';
import GGTermsAndConditions from '@screens/GGTermsAndConditions';
import GGWithdrawDetails from '@screens/GGWithdrawDetails';
import GGWithdrawSuccess from '@screens/GGWithdrawSuccess';
import GGLoan from '@screens/Loan/GGLoan';
import DeptDesignList from '@screens/Profile/DeptDesignList';
import PicLanding from '@screens/Profile/PicListing';
import PICTerms from '@screens/Profile/PICTerms';
import UserProfile from '@screens/Profile/UserProfile';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { boldFont, regularFont } from '@theme/theme';
import { scale } from '@utils/normalize';
import React, { lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Platform } from 'react-native';
import ScreenNames from './navigationConstants';
import TabNavigator from './TabNavigation';

const AppStack = createNativeStackNavigator();

const styles = StyleSheet.create({
  headerBackStyle: {
    color: colors.neutral.charcoal,
  },
  headerStyle: { elevation: 0 },
  headerTitleStyle: {
    color: colors.neutral.charcoal,
    fontFamily: boldFont,
    fontSize: scale(16),
    fontStyle: 'bold',
    fontWeight: '700',
    lineHeight: scale(24),
  },
});

export function getHeaderProps(sHeaderString: string | null, headerShown = true): NativeStackNavigationOptions {
  return {
    headerShadowVisible: false,
    headerStyle: { backgroundColor: colors.secondary.orchid },
    headerBackTitleStyle: { color: colors.neutral.cotton, fontFamily: regularFont },
    headerTitle: sHeaderString,
    headerTintColor: colors.neutral.cotton,
    headerTitleStyle: {
      color: colors.neutral.cotton,
      fontFamily: boldFont,
      fontSize: scale(16),
      fontStyle: 'bold',
      lineHeight: scale(24),
    },
    headerShown,
  };
}

function getInitialRouteName(employeeId: string | number | null): string {
  if (employeeId && employeeId.toString().length > 0) {
    return ScreenNames.HOME;
  }
  return ScreenNames.LOGIN;
}

const StackNavigation: React.FC = () => {
  const { loading, employeeId, headerText } = getStore();
  const { t } = useTranslation('common');

  const WebComponent = lazy(() => import('@screens/GGGold/GoldWebView'));

  return (
    <>
      <BackdropLoader loading={loading} />
      <AccessFromPartner />
      <AppStack.Navigator
        presentation="modal"
        initialRouteName={getInitialRouteName(employeeId)}
        screenOptions={{ animation: 'fade' }}>
        <AppStack.Screen name={ScreenNames.LOGIN} component={GGLogin} options={{ headerShown: false }} />
        <AppStack.Screen
          name={ScreenNames.OTP}
          component={GGOTP}
          options={{
            headerShadowVisible: false,
            headerStyle: styles.headerStyle,
            headerTitle: t('otp.headerText'),
            headerBackTitleStyle: styles.headerBackStyle,
            headerTitleStyle: styles.headerTitleStyle,
          }}
        />
        <AppStack.Screen name={ScreenNames.HOME} component={TabNavigator} options={{ headerShown: false }} />
        <AppStack.Screen
          name={ScreenNames.TERMS_AND_CONDITION}
          component={GGTermsAndConditions}
          options={{
            headerStyle: styles.headerStyle,
            headerTitle: t('tnc.tncHeader'),
            headerBackTitleStyle: styles.headerBackStyle,
            headerTitleStyle: styles.headerTitleStyle,
          }}
        />
        <AppStack.Screen
          name={ScreenNames.ENROLLED_PHONES}
          component={GGEnrolledPhones}
          options={getHeaderProps(t('phoneTransfer.phoneTitle'))}
        />
        <AppStack.Screen
          name={ScreenNames.SALARY_INFORMATION}
          component={GGSalaryInformation}
          options={getHeaderProps(t('profile.salaryInfo'))}
        />
        <AppStack.Screen
          name={ScreenNames.BANK_LIST}
          component={GGBankListWithDefaultBank}
          options={getHeaderProps()}
        />
        <AppStack.Screen name={ScreenNames.SUB_PRODUCTS} component={GGSubProducts} options={getHeaderProps()} />
        <AppStack.Screen name={ScreenNames.NOMINALS} component={GGNominals} options={getHeaderProps()} />
        <AppStack.Screen name={ScreenNames.GGPDAM} component={GGPdam} options={getHeaderProps()} />
        <AppStack.Screen
          name={ScreenNames.GG_PASCABAYAR}
          component={GGPascabayar}
          options={getHeaderProps('Pascabayar')}
        />
        <AppStack.Screen name={ScreenNames.GG_TAGIHAN_PLN} component={GGTagihanPLN} options={getHeaderProps()} />
        <AppStack.Screen name={ScreenNames.GG_REGIONS} component={GGRegions} options={getHeaderProps()} />
        <AppStack.Screen
          name={ScreenNames.GG_VOUCHER_DETAILS}
          component={GGVoucherDetails}
          options={getHeaderProps()}
        />
        <AppStack.Screen name={ScreenNames.GG_BP_SUCCESS} component={GGBPSuccess} options={getHeaderProps()} />
        <AppStack.Screen name={ScreenNames.GG_CHAT_BOT} component={ChatScreen} options={getHeaderProps('Support')} />
        <AppStack.Screen
          name={ScreenNames.GG_WITHDRAW_DETAILS}
          component={GGWithdrawDetails}
          options={getHeaderProps(t('withdrawalPage.header'))}
        />
        <AppStack.Screen
          name={ScreenNames.GG_WITHDRAW_SUCCESS}
          component={GGWithdrawSuccess}
          // TODO replace with headerText
          options={getHeaderProps(headerText)}
        />
        <AppStack.Screen name={ScreenNames.GG_CREATE_PIN} component={GGCreatePin} options={{ headerShown: false }} />
        <AppStack.Screen
          name={ScreenNames.GG_VERIFY_PIN}
          component={GGCreatePin}
          options={() => ({
            headerTitle: 'Verfication Pin',
            // ...optionStyle,
            headerShown: false,
            presentation: 'modal',
          })}
        />
        <AppStack.Screen
          name={ScreenNames.GG_VERIFY_PIN_WITH_EXPIRY}
          component={GGCreatePin}
          options={() => ({
            headerTitle: 'Verfication Pin',
            // ...optionStyle,
            headerShown: false,
            presentation: 'modal',
          })}
        />
        <AppStack.Screen
          name={ScreenNames.GG_ADD_PHONE_SCREEN}
          component={GGAddPhoneScreen}
          options={getHeaderProps(t('login.SECURITY_PIN_ACTIVE_PHONE_ENROLL_BUTTON1'))}
        />
        <AppStack.Screen
          name={ScreenNames.GG_EDIT_TRANSACTION}
          component={GGEditTransaction}
          options={getHeaderProps(t('transactionDetailsScreen.detailTransaksi'))}
        />
        <AppStack.Screen
          name={ScreenNames.GG_AUTO_DISBURSE_TERMS}
          component={GGAutoDisburseTerms}
          options={getHeaderProps(t('autoDisburse.navTitle'))}
        />
        <AppStack.Screen
          name="GGGoldTerms"
          component={GGGoldTerms}
          options={getHeaderProps(t('treasury.termsAndConditions'))}
        />
        <AppStack.Screen name="GoldKTP" component={GoldKTP} options={getHeaderProps(t('treasury.ktpHeaderTitle'))} />
        {Platform.OS === 'android' && (
          <AppStack.Screen
            name={ScreenNames.GG_GOLDWEBVIEW}
            component={WebComponent}
            options={getHeaderProps(t('treasury.webViewTitle'))}
          />
        )}
        <AppStack.Screen
          name={ScreenNames.REFERRAL_PAGE}
          component={GGReferralPage}
          options={getHeaderProps(t('referral.referralPageTitle'))}
        />
        <AppStack.Screen
          name={ScreenNames.USER_PROFILE}
          component={UserProfile}
          options={getHeaderProps(null, false)}
        />
        <AppStack.Screen
          name={ScreenNames.DEPT_DESIGN_LIST}
          component={DeptDesignList}
          options={getHeaderProps(t('userProfile.deptDesginTitle'))}
        />
        <AppStack.Screen
          name={ScreenNames.PIC_LISTING}
          component={PicLanding}
          options={getHeaderProps(t('userProfile.picLandingTitle'))}
        />
        <AppStack.Screen
          name={ScreenNames.PIC_TERMS}
          component={PICTerms}
          options={getHeaderProps(t('userProfile.termsAndCondition'))}
        />
        <AppStack.Screen
          name={ScreenNames.GG_ADD_BANK_SCREEN}
          component={GGAddBankScreen}
          options={getHeaderProps(t('addBank.bankTitle'))}
        />
        <AppStack.Screen
          name={ScreenNames.GG_BANK_TRANSFER}
          component={GGBankTransfer}
          options={getHeaderProps('Transfer')}
        />
        <AppStack.Screen
          name={ScreenNames.GG_ALL_BANKS}
          component={GGAllBanks}
          options={getHeaderProps(t('addBank.bankTitlePlaceholder'))}
        />
        <AppStack.Screen
          name={ScreenNames.GG_LOAN_INFO}
          component={LoanInfo}
          options={getHeaderProps(t('Proses Pengajuan e-Kasbon'))}
        />
        <AppStack.Screen
          name={ScreenNames.GG_LOAN}
          component={GGLoan}
          options={{
            headerShown: false,
            presentation: 'transparentModal',
            cardStyle: { backgroundColor: 'transparent' },
            cardOverlayEnabled: true,
          }}
        />
        <AppStack.Screen
          name={ScreenNames.GG_LOANLISTING}
          component={LoanListing}
          options={getHeaderProps(t('e-Kasbon'))}
        />
        <AppStack.Screen
          name={ScreenNames.GG_LOANDETAILS}
          component={LoanDetails}
          options={getHeaderProps(t('e-Kasbon'))}
        />
        <AppStack.Screen
          name={ScreenNames.GG_LOANTERMS}
          component={LoanTerms}
          options={getHeaderProps(t('loan.termsAndCondition'))}
        />
        <AppStack.Screen
          name={ScreenNames.GG_PHONE_NUMBER_CHANGE}
          component={GGPhoneChange}
          options={() => ({
            headerTitle: 'Phone Number Change',
            // ...optionStyle,
            headerShown: false,
            presentation: 'modal',
          })}
        />
        <AppStack.Screen
          name={ScreenNames.GG_PHONE_NUMBER_CHANGE_CONFIM}
          component={GGPhoneChangeConfirm}
          options={() => ({
            headerTitle: 'Phone Number Change Confirm',
            // ...optionStyle,
            headerShown: false,
            presentation: 'modal',
          })}
        />
      </AppStack.Navigator>

      <ConnectivityListener />
    </>
  );
};

export default StackNavigation;
