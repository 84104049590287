import { apiLogout, apiPhoneNumberChangeConfirm } from '@api/apiCall';
import BtnPhoneChangeNext from '@atoms/BtnPhoneChangeNext';
import LoginInfoSheet from '@molecules/LoginInfoSheet';
import { NavigationProps } from '@navigation/types';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { SECURITY_PIN_INFO_MODAL } from '@root/constants';
import { getStore } from '@store/storeUtils';
import { useMutation } from '@tanstack/react-query';
import colors from '@theme/colors';
import OTPInputView from '@twotalltotems/react-native-otp-input';
import { returnBottomSheetContent } from '@utils/utils';
import { Text } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, SafeAreaView } from 'react-native';

import { onAppEventHandlers, onUserInteractionHandlers } from './phoneChangeConfirmUtils';

import styles from './styles';

export interface OtpConfirmPayload {
  otpPin: number;
  token: string;
}

const GGPhoneChangeConfirm: React.FC = () => {
  const navigation = useNavigation<NavigationProps>();
  // Global
  const { t } = useTranslation('common');
  const { securityPinOpen, setLoading } = getStore();

  // Local States
  const [infoModal, setInfoModal] = useState({
    visible: false,
    data: returnBottomSheetContent(
      SECURITY_PIN_INFO_MODAL.VERIFY_PIN_LOCKED,
      SECURITY_PIN_INFO_MODAL.VERIFY_PIN_LOCKED,
    ),
    errorCode: 'VERIFY_PIN_LOCKED',
  });
  const [otpPin, setOtp] = useState('');
  const [errTxtMsg, setErrTxtMsg] = useState('');
  const [disableOTP, setDisableOTP] = useState(false);
  const [isNextEnable, setNextEnable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChangeSuccess, setIsChangeSuccess] = useState(false);
  const [attempts, setAttempts] = useState(0);

  const {
    mutate: submitOtp,
    isLoading: isSubmitOtpLoading,
    isSuccess: isSubmitOtpSuccess,
    data: submitOtpData,
    isError: isSubmitOtpError,
    error: submitOtpError,
  } = useMutation({
    mutationFn: (payload: OtpConfirmPayload) => apiPhoneNumberChangeConfirm(payload),
  });

  const {
    // isLoading,
    mutate: performLogout,
    // isSuccess,
    // data: LogoutData,
    // isError,
    // error,
  } = useMutation({ mutationFn: () => apiLogout() });

  const { onOtpChange, onSubmitOtp, onPrimaryPressModal } = onUserInteractionHandlers({
    navigation,
    infoModal,
    setDisableOTP,
    setOtp,
    setErrTxtMsg,
    setNextEnable,
    submitOtp,
    securityPinOpen,
    otpPin,
    performLogout,
    isChangeSuccess,
    setIsLoading,
    setAttempts,
  });

  const { onErrorSubmitOtp, onSuccessSubmitOtp } = onAppEventHandlers({
    t,
    setInfoModal,
    setErrTxtMsg,
    setDisableOTP,
    setIsLoading,
    setOtp,
    setNextEnable,
    isSubmitOtpError,
    isSubmitOtpSuccess,
    setIsChangeSuccess,
    submitOtpData,
    submitOtpError: submitOtpError as unknown as string[],
    attempts,
  });

  function shouldShowErrorMessage(sErrorMessage: string[]) {
    if (sErrorMessage && sErrorMessage.length > 0) {
      return (
        <View style={styles.errorStyles}>
          <Text
            testID="txtErrorToastMsg"
            alignSelf="center"
            textAlign="center"
            color={colors.error.rose}
            variant="sm-normal">
            {sErrorMessage}
          </Text>
        </View>
      );
    }
    return null;
  }

  // Reactive
  useEffect(() => {
    setLoading(isSubmitOtpLoading);
  }, [isSubmitOtpLoading]);

  useEffect(() => {
    onErrorSubmitOtp();
  }, [isSubmitOtpError, submitOtpError]);

  useEffect(() => {
    onSuccessSubmitOtp();
  }, [isSubmitOtpSuccess, submitOtpData]);

  // Components
  return (
    <SafeAreaView style={appStyles.safeAreaView}>
      <View style={styles.parent}>
        <View>
          <Text style={styles.pinTitleContainer} testID="txtPlain" sentry-label="txtPlain" variant="lg-normal">
            {t('phoneNumberChange.enterOtp')}
          </Text>
        </View>

        <View style={styles.phoneInputs}>
          <OTPInputView
            pinCount={5}
            code={otpPin}
            style={styles.otpGroup}
            autoFocusOnLoad={false}
            onCodeFilled={() => setNextEnable(true)}
            editable={!disableOTP}
            clearInputs={errTxtMsg.length > 0 || otpPin.length === 0}
            codeInputFieldStyle={styles.otpDefault}
            codeInputHighlightStyle={styles.otpHightLight}
            onCodeChanged={onOtpChange}
          />

          {shouldShowErrorMessage([].concat(errTxtMsg))}
          <BtnPhoneChangeNext isDisabled={!isNextEnable} isLoading={isLoading} performNext={onSubmitOtp} />
        </View>

        <View>
          <Text style={styles.pinTitleDescription} testID="txtPlain" sentry-label="txtPlain" variant="lg-normal">
            {t('phoneNumberChange.enterOtpDescription')}
          </Text>
        </View>
        <LoginInfoSheet onPressPrimary={onPrimaryPressModal} loginInfo={infoModal} onClose={onPrimaryPressModal} />
      </View>
    </SafeAreaView>
  );
};

export default GGPhoneChangeConfirm;
