import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';

type LogParams = Record<string, any>;

function logCustomTooltip(logParams: LogParams): void {
  const event = AMPLITUDE_CONSTANTS.TransactionPage.tooltip_clicked;
  AmplitudeHelper.logEvent(event.name, logParams);
}

export default logCustomTooltip;
