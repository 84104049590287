import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  errorContainer: { marginHorizontal: 18, marginVertical: 10 },
  errorStyles: { marginHorizontal: 16, marginTop: 24 },
  errorTextStyle: { color: colors.error.rose, fontSize: 12, textAlign: 'center' },

  parent: {
    backgroundColor: colors.neutral.cotton,
    flex: 1,
    marginHorizontal: scale(16),
    marginTop: scale(150),
  },
  phoneInputs: {
    fontSize: scale(30),
    marginTop: scale(20),
  },
  textStyles: {
    fontSize: scale(16),
  },
});

export default styles;
