import Account from '@assets/Svg/account.svg';
import AccuredSalary from '@assets/Svg/accuredSalary.svg';
import AccountSuspended from '@assets/Svg/art_accountSuspended.svg';
import LockPeriodImg from '@assets/Svg/art_lockPeriod.svg';
// POINTS
// Reasons Icons Expense
// import Absent from '@assets/Svg/Reasons/Expense/Absenteeism.svg';
// import Apparel from '@assets/Svg/Reasons/Expense/Apparel.svg';
// import AutoRepairs from '@assets/Svg/Reasons/Expense/AutoRepairs.svg';
// import Beauty from '@assets/Svg/Reasons/Expense/Beauty.svg';
// import BillUtility from '@assets/Svg/Reasons/Expense/BillUtility.svg';
// import BPJS from '@assets/Svg/Reasons/Expense/BPJS.svg';
// import Childneeds from '@assets/Svg/Reasons/Expense/Childneeds.svg';
// import Donation from '@assets/Svg/Reasons/Expense/Donation.svg';
// import Education from '@assets/Svg/Reasons/Expense/Education.svg';
// import Electronic from '@assets/Svg/Reasons/Expense/Electronic.svg';
// import Entertainment from '@assets/Svg/Reasons/Expense/Entertainment.svg';
// import FamilyFriends from '@assets/Svg/Reasons/Expense/FamilyFriends.svg';
// import FoodsDrinks from '@assets/Svg/Reasons/Expense/FoodsDrinks.svg';
// import Gift from '@assets/Svg/Reasons/Expense/gift.svg';
// import Groceries from '@assets/Svg/Reasons/Expense/Groceries.svg';
// import Health from '@assets/Svg/Reasons/Expense/Health.svg';
// import HouseRenovation from '@assets/Svg/Reasons/Expense/HouseRenovation.svg';
// import HouseRent from '@assets/Svg/Reasons/Expense/HouseRent.svg';
// import Installment from '@assets/Svg/Reasons/Expense/Installment.svg';
// import Investment from '@assets/Svg/Reasons/Expense/Investment.svg';
// import LateFine from '@assets/Svg/Reasons/Expense/LateFine.svg';
// import Otherexpense from '@assets/Svg/Reasons/Expense/Otherexpense.svg';
// import Salarywithdrawal from '@assets/Svg/Reasons/Expense/Salarywithdrawal.svg';
// import Savings from '@assets/Svg/Reasons/Expense/Savings.svg';
// import StartupCapital from '@assets/Svg/Reasons/Expense/StartupCapital.svg';
// import Tax from '@assets/Svg/Reasons/Expense/Tax.svg';
// import Transportation from '@assets/Svg/Reasons/Expense/Transportation.svg';
// import Vacation from '@assets/Svg/Reasons/Expense/Vacation.svg';
// import SalaryAdjustment from '@assets/Svg/Reasons/Expense/salaryAdjustment.svg';
// import LoanExp from '@assets/Svg/Reasons/Expense/Loan.svg';

// Reasons Icons Income
// import Bonus from '@assets/Svg/Reasons/Income/BonusAllowance.svg';
// import Borrow from '@assets/Svg/Reasons/Income/Borrowmoney.svg';
// import BusinessProfit from '@assets/Svg/Reasons/Income/BusinessProfit.svg';
// import Comission from '@assets/Svg/Reasons/Income/Commission.svg';
// import InvestmentIncome from '@assets/Svg/Reasons/Income/Investment.svg';
// import OtherIncome from '@assets/Svg/Reasons/Income/Otherincome.svg';
// import Overtime from '@assets/Svg/Reasons/Income/Overtime.svg';
// import Salary from '@assets/Svg/Reasons/Income/Salary.svg';
// import SalaryReciept from '@assets/Svg/Reasons/Income/salaryReciept.svg';

// New Dashboard

// Bottom Nav
import BillPayment from '@assets/Svg/BottomNav/BillPayment.svg';
import EWA from '@assets/Svg/BottomNav/EWA.svg';
import Home from '@assets/Svg/BottomNav/Home.svg';
import Others from '@assets/Svg/BottomNav/Others.svg';
import Transaction from '@assets/Svg/BottomNav/Transaction.svg';

// Rewards
import PhoneSelector from '@assets/Svg/BPIcons/phoneSelector.svg';
import BulletPoint from '@assets/Svg/bullet.svg';
import ChatSmile from '@assets/Svg/chat_smile.svg';
import CopyIcon from '@assets/Svg/clipboardCopy.svg';
import Clock from '@assets/Svg/clock.svg';
import Coin2 from '@assets/Svg/coin.svg';
import Congrats from '@assets/Svg/congrats.svg';
import ArtInformation from '@assets/Svg/DynamicAccounts/art_information.svg';
import EmptyWallet from '@assets/Svg/empty-wallet-time.svg';
import Fixing from '@assets/Svg/fixing.svg';
import Gift2 from '@assets/Svg/gift.svg';

import GiftNew from '@assets/Svg/giftNew.svg';
import GoBack from '@assets/Svg/goBack.svg';
import GoForward from '@assets/Svg/goForward.svg';
import Basket from '@assets/Svg/grocery.svg';

// Onboarding

// New Login

// Bottomsheet
// import { normalize, normalizeHeight } from '../sharedStyle/helper';

// // Dynamic Account
// import SDIcon from '@assets/Svg/DynamicAccounts/sdAccount.svg';
// import WorkAllowanceIcon from '@assets/Svg/DynamicAccounts/workAllowance.svg';
// import UangKas from '@assets/Svg/DynamicAccounts/uangkas.svg';
// import SalaryAccount from '@assets/Svg/DynamicAccounts/salaryAccount.svg';
// import DisabledSalaryAccount from '@assets/Svg/DynamicAccounts/disabledSalaryAccount.svg';

// Menu icons

// Profile

// Referral
// Permissions Modal

// Loan
import LoanApproved from '@assets/Svg/loan/art_approved.svg';
import LoanRejected from '@assets/Svg/loan/art_rejected.svg';
import LoanVerification from '@assets/Svg/loan/art_verification.svg';

import Cash from '@assets/Svg/loan/cash.svg';
import CashTwo from '@assets/Svg/loan/cashTwo.svg';

import VerifiedUser from '@assets/Svg/loan/verified_user.svg';
import Suspend from '@assets/Svg/Login/BottomSheet/art_accountSuspended.svg';
import ComeBack from '@assets/Svg/Login/BottomSheet/art_comebackLater.svg';
// Bank Module
import DataFound from '@assets/Svg/Login/BottomSheet/art_dataFound.svg';
import DeleteModalIcon from '@assets/Svg/Login/BottomSheet/art_deleteGeneral.svg';
import ErrorData from '@assets/Svg/Login/BottomSheet/art_errorData.svg';
import LogoutHand from '@assets/Svg/Login/BottomSheet/art_logout.svg';
import Otp from '@assets/Svg/Login/BottomSheet/art_OTP.svg';
import Unregistered from '@assets/Svg/Login/BottomSheet/art_phoneNumer_Unregistered.svg';
import PinLock from '@assets/Svg/Login/BottomSheet/art_PIN.svg';
import Registered from '@assets/Svg/Login/BottomSheet/art_Registered.svg';
import Success from '@assets/Svg/Login/BottomSheet/art_successGeneral.svg';
import Waiting from '@assets/Svg/Login/BottomSheet/art_waiting.svg';
import DuplicateModalIcon from '@assets/Svg/Login/BottomSheet/processReject.svg';
import Whatsapp from '@assets/Svg/Login/whatsapp.svg';
import BantuanMenu from '@assets/Svg/MenuIcons/bantuan.svg';
import BillPayments from '@assets/Svg/MenuIcons/billpayments.svg';
import GoldMenu from '@assets/Svg/MenuIcons/finance.svg';
import HomeIcon from '@assets/Svg/MenuIcons/home.svg';
import LoanMenu from '@assets/Svg/MenuIcons/loan.svg';
import Other from '@assets/Svg/MenuIcons/other.svg';
import ProfileMenu from '@assets/Svg/MenuIcons/profile.svg';
import TransactionMenu from '@assets/Svg/MenuIcons/transactionsMenu.svg';
import PermissionIcon from '@assets/Svg/NewDashboard/permissionIcon.svg';
import SalaryDashboard from '@assets/Svg/NewDashboard/salary.svg';
import MessageNoti from '@assets/Svg/otp/messageNoti.svg';
import SmsOTP from '@assets/Svg/otp/sms.svg';
import WhatsappOTP from '@assets/Svg/otp/wa.svg';
import Pause from '@assets/Svg/pause.svg';

// Phone Enrollment
import Pending from '@assets/Svg/pending.svg';
import PhoneChange from '@assets/Svg/PhoneChange/phoneChange.svg';
import PhoneIcon from '@assets/Svg/phoneEnroll/ic_phone.svg';
import Coin from '@assets/Svg/point_coins.svg';
import Confetti from '@assets/Svg/Profile/confetti.svg';
import ConfettiScrapes from '@assets/Svg/Profile/confettiScrapes.svg';
import ReferralGift from '@assets/Svg/Profile/gift.svg';

// zakat
import ProfileAvatar from '@assets/Svg/Profile/happyFace.svg';
import WaBantuan from '@assets/Svg/Profile/whatsapp.svg';
import Question from '@assets/Svg/question.svg';
import GiftTwo from '@assets/Svg/referral/gift_two.svg';
import PointingArrow from '@assets/Svg/referral/pointingArrow.svg';
import ReferralPrgOne from '@assets/Svg/referral/ref_prog_one.svg';
import ReferralPrgThree from '@assets/Svg/referral/ref_prog_three.svg';
import ReferralPrgTwo from '@assets/Svg/referral/ref_prog_two.svg';
import RewardIcon from '@assets/Svg/rewardIcon.svg';
import Calculator from '@assets/Svg/salaryInfo/calculator.svg';
import CheckSalaryInfo from '@assets/Svg/salaryInfo/check.svg';
import PhoneCheck from '@assets/Svg/salaryInfo/phone-check.svg';
import PhoneLock from '@assets/Svg/salaryInfo/phone-lock.svg';
import PhoneVocal from '@assets/Svg/salaryInfo/phone-vocal.svg';
import RefreshSalaryInfo from '@assets/Svg/salaryInfo/refresh.svg';
import PadLock from '@assets/Svg/securityPin/padLock.svg';
import Swipe from '@assets/Svg/swipe.svg';
import Bappebti from '@assets/Svg/treasury/bappebti.svg';
import ChartLine from '@assets/Svg/treasury/benifits/chart_line.svg';
import Lock from '@assets/Svg/treasury/benifits/lock.svg';
import MoneyFilled from '@assets/Svg/treasury/benifits/money_filled.svg';
import ButtonGold from '@assets/Svg/treasury/ic_gold.svg';
import TreasuryLogo from '@assets/Svg/treasury/treasurylogo.svg';
import Update from '@assets/Svg/update.svg';
import ConfettiLeft from '@assets/Svg/userProfile/confettiLeft.svg';
import ConfettiRight from '@assets/Svg/userProfile/confettinRight.svg';
import HappyFacesx from '@assets/Svg/userProfile/happyFacex.svg';
import InviteWhatapp from '@assets/Svg/userProfile/inviteWhatsapp.svg';
import PicPoin from '@assets/Svg/userProfile/poin.svg';
import ProfilePicture from '@assets/Svg/userProfile/profilePicture.svg';
import SmallProfilePic from '@assets/Svg/userProfile/smallProfilePic.svg';
import YellowStarBadge from '@assets/Svg/userProfile/yellowStarBadge.svg';
import WalletColor from '@assets/Svg/walletColor.svg';
import WalletColorSmall from '@assets/Svg/walletColorSmall.svg';
import GGWhiteLogo from '@assets/Svg/welcome/gg_logo_white.svg';

// Salary info Screen

import React from 'react';
// import PropTypes from 'prop-types';

// export const Wallet = ({ color = '#979797' }) => {
//   const xml = `
//     <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M2.25 5.25C2.25 4.42157 2.92157 3.75 3.75 3.75H14.25C15.0785 3.75 15.75 4.42157 15.75 5.25V6.75H12.75C11.5073 6.75 10.5 7.75733 10.5 9C10.5 10.2427 11.5073 11.25 12.75 11.25H15.75V12.75C15.75 13.5785 15.0785 14.25 14.25 14.25H3.75C2.92157 14.25 2.25 13.5785 2.25 12.75V5.25Z" fill="${color}"/>
//     <path fill-rule="evenodd" clip-rule="evenodd" d="M11.25 9C11.25 8.17155 11.9216 7.5 12.75 7.5H15.75C16.1642 7.5 16.5 7.83578 16.5 8.25V9.75C16.5 10.1642 16.1642 10.5 15.75 10.5H12.75C11.9216 10.5 11.25 9.82845 11.25 9ZM13.5 9C13.5 9.41422 13.1642 9.75 12.75 9.75C12.3358 9.75 12 9.41422 12 9C12 8.58578 12.3358 8.25 12.75 8.25C13.1642 8.25 13.5 8.58578 13.5 9Z" fill="${color}"/>
//     </svg>
//   `;
//   return <SvgXml xml={xml} width={18} height={18} />;
// };

// Wallet.propTypes = {
//   color: PropTypes.string,
// };
// Wallet.defaultProps = {
//   color: '',
// };

export const SVG_ICONS = {
  ChatLogo: { chatLogo: <ChatSmile /> },
  Pending: <Pending />,
  Success: <Success />,
  Clock: <Clock />,
  bottomNav: {
    home: <Home />,
    transaction: <Transaction />,
    ewa: <EWA />,
    billpayment: <BillPayment />,
    others: <Others />,
  },
  rewards: {
    gift: <RewardIcon height={11} width={11} />,
    copyIcon: <CopyIcon />,
    bulletPoint: <BulletPoint />,
  },
  dashboard: {
    salaryWhite: <SalaryDashboard fill="#ffffff" />,
    salary: <SalaryDashboard />,
  },
  walletColor: <WalletColor />,
  walletColorSmall: <WalletColorSmall />,
  congrats: <Congrats />,
  account: <Account />,
  basket: <Basket />,
  accuredSalary: <AccuredSalary />,
  emptyWallet: <EmptyWallet fill="#000" />,

  coin: <Coin2 height={24} width={24} />,
  coin2: <Coin2 height={12} width={12} />,
  coin3: <Coin2 width={15} height={15} viewBox="0 10 250 250" />,
  coin4: <Coin2 height={18} width={18} />,
  coin6: <Coin2 height={32} width={32} />,
  coin5: <PicPoin />,
  goBack: <GoBack />,
  goForward: <GoForward />,
  swipe: <Swipe />,
  pause: <Pause />,
  gift: <Gift2 />,
  giftSmall: <Gift2 />,
  question: <Question />,
  update: <Update />,
  giftNew: <GiftNew height={25} width={25} />,
  welcome: {
    whiteGGLogo: <GGWhiteLogo height={40} width={50} />,
  },
  otp: {
    messageNoti: <MessageNoti />,
    whatsappOTP: <WhatsappOTP />,
    smsOTP: <SmsOTP />,
  },
  whatsapp: <Whatsapp />,
  bottomSheet: {
    suspend: <Suspend />,
    comeback: <ComeBack />,
    error: <ErrorData />,
    otp: <Otp />,
    unregistered: <Unregistered />,
    registered: <Registered />,
    waiting: <Waiting />,
    logout: <LogoutHand />,
    success: <Success />,
    pinLock: <PinLock />,
    dataFound: <DataFound />,
    LockPeriodImg: <LockPeriodImg />,
    AccountSuspended: <AccountSuspended />,
  },

  dynamicAccounts: {
    artInfo: <ArtInformation />,
    // disabledSalaryAccount: <DisabledSalaryAccount />,
    // salaryAccount: <SalaryAccount />,
    // sdAccount: <SDIcon />,
    // workAllowanceIcon: <WorkAllowanceIcon />,
    // uangKas: <UangKas />,
  },
  profilePage: {
    profileAvatar: <ProfileAvatar />,
    waBantuan: WaBantuan,
    confetti: <Confetti />,
    confettiScrapes: <ConfettiScrapes />,
    referralGift: <ReferralGift />,
  },

  referral: {
    refProgOne: <ReferralPrgOne />,
    refProgTwo: <ReferralPrgTwo />,
    refProgThree: <ReferralPrgThree />,
    pointingArrow: <PointingArrow fill="#3863F0" />,
    pointingArrowRed: <PointingArrow fill="#F56B57" />,
    giftTwo: <GiftTwo />,
  },
  permissionIcon: <PermissionIcon />,
  loan: {
    approved: <LoanApproved />,
    rejected: <LoanRejected />,
    verification: <LoanVerification />,
    fullyPaid: <LoanApproved />,
    // loanProducts: <LoanProducts />,
    cash: <Cash />,
    cashTwo: <CashTwo />,
    verifiedUser: <VerifiedUser />,
  },
  deleteModalIcon: <DeleteModalIcon />,
  duplicateModalIcon: <DuplicateModalIcon />,
  phoneEnroll: {
    // phoneBook: <PhoneBook />,
    // phoneBookError: <PhoneBookError />,
    phoneIcon: PhoneIcon,
  },
  fixingImage: <Fixing />,
  userProfile: {
    happyFacesx: <HappyFacesx />,
    profilePicture: <ProfilePicture />,
    smallProfilePic: <SmallProfilePic />,
    yellowStarBadge: <YellowStarBadge />,
    confettiRight: <ConfettiRight />,
    confettiLeft: <ConfettiLeft />,
    inviteWhatapp: <InviteWhatapp />,
  },
  securityPin: {
    padLock: PadLock,
  },
  phoneChange: {
    phoneChange: PhoneChange,
  },
  treasury: {
    bappebti: <Bappebti />,
    buttonGold: <ButtonGold />,
    treasuryLogo: <TreasuryLogo />,
    chartLine: <ChartLine />,
    lock: <Lock />,
    moneyFilled: <MoneyFilled />,
  },
  BPIcons: { phoneSelector: <PhoneSelector width={32} height={32} /> },
  salaryInfo: {
    calculator: <Calculator />,
    checkSalaryInfo: <CheckSalaryInfo />,
    refreshSalaryInfo: <RefreshSalaryInfo />,
    phoneCheck: <PhoneCheck />,
    phoneLock: <PhoneLock />,
    phoneVocal: <PhoneVocal />,
  },
};

// export const ReasonsIncreased = {
//   6: <Groceries height={30} width={30} fill="#3863F0" />,
//   8: <FoodsDrinks height={30} width={30} fill="#3863F0" />,
//   4: <Health height={30} width={30} fill="#3863F0" />,
//   2: <BillUtility height={30} width={30} fill="#3863F0" />,
//   1: <HouseRent height={30} width={30} fill="#3863F0" />,
//   3: <Education height={30} width={30} fill="#3863F0" />,
//   7: <Apparel height={30} width={30} fill="#3863F0" />,
//   12: <AutoRepairs height={30} width={30} fill="#3863F0" />,
//   11: <Transportation height={30} width={30} fill="#3863F0" />,
//   5: <Tax height={30} width={30} fill="#3863F0" />,
//   9: <Gift height={30} width={30} fill="#3863F0" />,
//   13: <HouseRenovation height={30} width={30} fill="#3863F0" />,
//   16: <Investment height={30} width={30} fill="#3863F0" />,
//   15: <StartupCapital height={30} width={30} fill="#3863F0" />,
//   14: <Vacation height={30} width={30} fill="#3863F0" />,
//   17: <Entertainment height={30} width={30} fill="#3863F0" />,
//   18: <Otherexpense height={30} width={30} fill="#3863F0" />,
//   19: <Donation height={30} width={30} fill="#3863F0" />,
//   20: <Salarywithdrawal height={30} width={30} fill="#3863F0" />,
//   21: <FamilyFriends height={30} width={30} fill="#3863F0" />,
//   22: <Childneeds height={30} width={30} fill="#3863F0" />,
//   23: <Installment height={30} width={30} fill="#3863F0" />,
//   24: <Savings height={30} width={30} fill="#3863F0" />,
//   25: <Electronic height={30} width={30} fill="#3863F0" />,
//   26: <Beauty height={30} width={30} fill="#3863F0" />,
//   27: <LateFine height={30} width={30} fill="#3863F0" />,
//   28: <Absent height={30} width={30} fill="#3863F0" />,
//   29: <BPJS height={30} width={30} fill="#3863F0" />,
//   30: <Salary height={30} width={30} fill="#3863F0" />,
//   31: <Bonus height={30} width={30} fill="#3863F0" />,
//   32: <InvestmentIncome height={30} width={30} fill="#3863F0" />,
//   34: <Comission height={30} width={30} fill="#3863F0" />,
//   35: <Overtime height={30} width={30} fill="#3863F0" />,
//   36: <Borrow height={30} width={30} fill="#3863F0" />,
//   37: <OtherIncome height={30} width={30} fill="#3863F0" />,
//   33: <BusinessProfit height={30} width={30} fill="#3863F0" />,
//   38: <SalaryAdjustment height={30} width={30} />,
//   39: <SalaryReciept height={30} width={30} />,
//   40: <SalaryReciept height={30} width={30} />,
//   41: <SalaryAdjustment height={30} width={30} />,
//   42: <SalaryReciept height={30} width={30} />,
//   43: <SalaryAdjustment height={30} width={30} />,
//   47: <LoanExp height={30} width={30} fill="#3863F0" />,
// };

// // FILL IS ADDED HERE AS DISCUSSED WITH ALVER ON 27.10.2022

// export const Reasons = {
//   1: <HouseRent fill="#F23015" />,
//   2: <BillUtility fill="#F23015" />,
//   3: <Education fill="#F23015" />,
//   4: <Health fill="#F23015" />,
//   5: <Tax fill="#F23015" />,
//   6: <Groceries fill="#F23015" />,
//   7: <Apparel fill="#F23015" />,
//   8: <FoodsDrinks fill="#F23015" />,
//   9: <Gift fill="#F23015" />,
//   11: <Transportation fill="#F56B57" />,
//   12: <AutoRepairs fill="#F23015" />,
//   13: <HouseRenovation fill="#F23015" />,
//   16: <Investment fill="#F23015" />,
//   15: <StartupCapital fill="#F23015" />,
//   14: <Vacation fill="#F23015" />,
//   17: <Entertainment fill="#F23015" />,
//   18: <Otherexpense fill="#F23015" />,
//   19: <Donation fill="#F23015" />,
//   20: <Salarywithdrawal fill="#F23015" />,
//   21: <FamilyFriends fill="#F23015" />,
//   22: <Childneeds fill="#F23015" />,
//   23: <Installment fill="#F23015" />,
//   24: <Savings fill="#F23015" />,
//   25: <Electronic fill="#F23015" />,
//   26: <Beauty fill="#F23015" />,
//   27: <LateFine fill="#F23015" />,
//   28: <Absent fill="#F23015" />,
//   29: <BPJS fill="#F23015" />,
//   30: <Salary fill="#56893E" />,
//   31: <Bonus fill="#56893E" />,
//   32: <InvestmentIncome fill="#56893E" />,
//   34: <Comission fill="#56893E" />,
//   35: <Overtime fill="#56893E" />,
//   36: <Borrow fill="#56893E" />,
//   37: <OtherIncome fill="#56893E" />,
//   33: <BusinessProfit fill="#56893E" />,
//   38: <SalaryAdjustment height={30} width={30} />,
//   39: <SalaryReciept height={30} width={30} />,
//   40: <SalaryReciept height={30} width={30} />,
//   41: <SalaryAdjustment height={30} width={30} />,
//   42: <SalaryReciept height={30} width={30} />,
//   43: <SalaryAdjustment height={30} width={30} />,
//   47: <LoanExp fill="#F23015" />,
// };

export const Points = {
  coin: <Coin height={30} width={30} />,
};

// export const CustomReasons = {
//   6: Groceries,
//   8: FoodsDrinks,
//   4: Health,
//   2: BillUtility,
//   1: HouseRent,
//   3: Education,
//   7: Apparel,
//   12: AutoRepairs,
//   11: Transportation,
//   5: Tax,
//   9: Gift,
//   13: HouseRenovation,
//   16: Investment,
//   15: StartupCapital,
//   14: Vacation,
//   17: Entertainment,
//   18: Otherexpense,
//   19: Donation,
//   20: Salarywithdrawal,
//   21: FamilyFriends,
//   22: Childneeds,
//   23: Installment,
//   24: Savings,
//   25: Electronic,
//   26: Beauty,
//   27: LateFine,
//   28: Absent,
//   29: BPJS,
//   30: Salary,
//   31: Bonus,
//   32: InvestmentIncome,
//   34: Comission,
//   35: Overtime,
//   36: Borrow,
//   37: OtherIncome,
//   33: BusinessProfit,
//   38: SalaryAdjustment,
//   39: SalaryReciept,
//   40: SalaryReciept,
//   41: SalaryAdjustment,
//   47: LoanExp,
// };

export const MenuIcons = {
  home: HomeIcon,
  transaction: TransactionMenu,
  billpayment: BillPayments,
  other: Other,
  loan: LoanMenu,
  bantuan: BantuanMenu,
  profile: ProfileMenu,
  gold: GoldMenu,
};
