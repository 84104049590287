import SkeletonComponent from '@atoms/SkeletonComponent';
import { View } from 'native-base';
import React from 'react';
import ShimmerBPTile from '../ShimmerBPTile';

export const ShimmerBPCategory: React.FC = () => (
  <View flexDirection="row">
    <ShimmerBPTile />
    <ShimmerBPTile />
    <ShimmerBPTile />
    <ShimmerBPTile />
  </View>
);

const ShimmerBPList: React.FC = () => (
  <View>
    <SkeletonComponent />
    <ShimmerBPCategory />
    <ShimmerBPCategory />
    <ShimmerBPCategory />
    <ShimmerBPCategory />
  </View>
);

export default ShimmerBPList;
