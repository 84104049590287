import { fetchLoanProduucts } from '@api/useQueryCalls';
import InfoBar from '@atoms/Loan/InfoBar';
import ListTitle from '@atoms/Loan/ListTitle';
import LoanHeader from '@atoms/Loan/LoanHeader';
import LoanItem from '@atoms/Loan/LoanItem';
import ToastError from '@atoms/ToastError';
import useBeforeUnload from '@customHooks/useBeforeUnload';
import navigationConstants from '@navigation/navigationConstants';
import { shouldShowError } from '@organisms/HomeTab/homeUtils';
import { NavigationProp, RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { getStore } from '@store/storeUtils';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { Divider, useToast } from 'native-base';
import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, ScrollView } from 'react-native';
import { logHeaderLink, logProductPress } from './analyticsLoanListing';
import styles from './style';

export function renderHeaderComponent(t: (key: string) => string, navigation: NavigationProp<any>) {
  const onPressHeaderLink = () => {
    logHeaderLink();
    navigation.navigate(navigationConstants.GG_LOAN_INFO);
  };

  return () => (
    <>
      <LoanHeader
        headerText={t('loan.loanHeaderText')}
        onLinkPress={onPressHeaderLink}
        subHeader={t('loan.pelajari')}
      />
      <InfoBar t={t} />
      <Divider style={styles.dividerStyle} />
      <ListTitle t={t} />
    </>
  );
}

export function handleUnload(navigation: NavigationProp<any>) {
  return () => navigation.goBack();
}

export function loanProductPressHandler(
  isSuspended: boolean,
  toast: any,
  t: (key: string) => string,
  navigation: NavigationProp<any>,
) {
  return (item: any) => {
    logProductPress(item);
    if (isSuspended) {
      return toast.show({
        render: () => <ToastError data={t('loan.toastErrorLoan')} />,
        placement: 'top',
      });
    }

    return navigation.navigate(navigationConstants.GG_LOANDETAILS, {
      loanData: item,
    });
  };
}

export const keyExtractorHelper = (item: any, index: number): string => index.toString();

interface RouteParams {
  source?: string;
}

const LoanListing: React.FC = () => {
  const navigation = useNavigation<NavigationProp<any>>();
  const route = useRoute<RouteProp<{ params: RouteParams }, 'params'>>();

  useBeforeUnload(handleUnload(navigation));

  const { setInitialData, setLoading, isSuspended } = getStore();
  const {
    isLoading: loanListLoading,
    isError: isLoanError,
    error: loanError,
    data: loans,
  } = fetchLoanProduucts(() => { });

  const toast = useToast();
  const { t } = useTranslation('common');

  useEffect(() => {
    setLoading(loanListLoading);
  }, [loanListLoading]);

  useEffect(() => {
    shouldShowError(isLoanError, loanError, toast, t, navigation, setInitialData);
  }, [isLoanError, loanError]);

  useEffect(() => {
    const e = AMPLITUDE_CONSTANTS.Loans.loan_offers_page;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: route?.params?.source,
    });
  }, []);

  const renderItem = useCallback(
    ({ item }) => (
      <LoanItem
        principalAmt={item.principal}
        receivable={item.principal - item.upfrontFee}
        tenure={item.tenure}
        installmentAmount={item.installmentValue}
        onPressItem={innerToast => loanProductPressHandler(isSuspended, innerToast, t, navigation)(item)}
        t={t}
      />
    ),
    [isSuspended, t],
  );

  return (
    <ScrollView style={styles.scrollViewContainer}>
      <FlatList
        testID="loanFlatList"
        contentContainerStyle={styles.flatlistContainerStyle}
        data={loans?.eligibleLoanProducts || []}
        keyExtractor={keyExtractorHelper}
        renderItem={renderItem}
        ListHeaderComponent={renderHeaderComponent(t, navigation)}
      />
    </ScrollView>
  );
};

export default LoanListing;
