import { getStore } from '@store/storeUtils';
import { Text } from 'native-base';
import React from 'react';

type TextCurrencyProps = {
  testID?: string;
  amountCurrency?: number | string;
  showCurrencySymbol?: boolean;
  roundOff?: boolean;
  suffix?: string;
};

function TextCurrency({
  testID = 'txtCurrency',
  amountCurrency = 0,
  showCurrencySymbol = true,
  roundOff = false,
  suffix = '',
  ...props
}: TextCurrencyProps) {
  let numberValue = isNaN(amountCurrency) ? parseInt(amountCurrency, 10) : amountCurrency;

  if (roundOff) numberValue = Math.floor(numberValue);

  const { tenantSettings } = getStore();
  const { currencySymbol, numberFormatting, currencyShortName } = tenantSettings;

  return (
    <Text testID={testID} sentry-label={testID} {...props}>
      {showCurrencySymbol ? currencySymbol : null}
      {numberValue.toLocaleString(numberFormatting, { currency: currencyShortName, string: 'currency' })}
      {suffix}
    </Text>
  );
}

export default TextCurrency;
