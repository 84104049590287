import { Text } from 'native-base';
import React from 'react';
import { TextInput, View, ViewStyle, TextStyle } from 'react-native';
import styles from './style';

interface TextInputProps {
  textInputProps?: Record<string, any>;
  title?: string;
  isMandatory?: boolean;
  containerStyle?: ViewStyle;
  inputContainerStyle?: ViewStyle;
  isError?: boolean;
  errorMessage?: string;
  textInputStyle?: TextStyle;
}

const TextInputComponent: React.FC<TextInputProps> = ({
  title = '',
  isMandatory = false,
  textInputProps = {},
  containerStyle = {},
  inputContainerStyle = {},
  isError = false,
  errorMessage = '',
  textInputStyle = {},
}) => (
  <View style={[styles.mainContainer, containerStyle]}>
    <Text variant="sm-normal" style={styles.titleText}>
      {title}
      <Text style={styles.star}>{isMandatory ? '*' : ''}</Text>
    </Text>
    <View style={[styles.inputContainer, isError && styles.errorContainerStyle, inputContainerStyle]}>
      <TextInput style={[styles.textInput, textInputStyle]} {...textInputProps} />
    </View>
    {isError && (
      <Text variant="sm-normal" style={styles.errorText}>
        {errorMessage}
      </Text>
    )}
  </View>
);

export default TextInputComponent;
