import { NavigationProps } from '@navigation/types';
import { SECURITY_PIN_INFO_MODAL } from '@root/constants';
import { ModalState } from '@root/types';
import { goBackToHome, returnBottomSheetContent } from '@utils/utils';
import { TFunction } from 'i18next';
import type React from 'react';
import { OtpConfirmPayload } from '.';

const MAX_OTP_CONFIRM_ATTEMPTS = 3;

export function onUserInteractionHandlers(params: {
  navigation: NavigationProps;
  infoModal: ModalState;
  setDisableOTP: React.Dispatch<React.SetStateAction<boolean>>;
  setOtp: React.Dispatch<React.SetStateAction<string>>;
  setErrTxtMsg: React.Dispatch<React.SetStateAction<string>>;
  setNextEnable: React.Dispatch<React.SetStateAction<boolean>>;
  submitOtp: (payload: OtpConfirmPayload) => void;
  performLogout: () => void;
  securityPinOpen: { token: string };
  otpPin: string;
  isChangeSuccess: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAttempts: React.Dispatch<React.SetStateAction<number>>;
}) {
  const {
    navigation,
    infoModal,
    setDisableOTP,
    setOtp,
    setErrTxtMsg,
    setNextEnable,
    submitOtp,
    performLogout,
    securityPinOpen,
    otpPin,
    isChangeSuccess,
    setIsLoading,
    setAttempts,
  } = params;

  const onOtpChange = code => {
    setOtp(code);
    setErrTxtMsg('');
    if (code.length < 5) {
      setNextEnable(false);
    }
  };

  const onSubmitOtp = () => {
    setDisableOTP(true);
    setIsLoading(true);
    setAttempts(attempts => attempts + 1);
    return submitOtp({
      token: securityPinOpen.token,
      otpPin: Number(otpPin),
    });
  };

  const onPrimaryPressModal = () => {
    if (isChangeSuccess) {
      performLogout();
    } else if (infoModal.errorCode === 'VERIFY_PIN_LOCKED') {
      goBackToHome(navigation);
    }
  };

  return { onOtpChange, onSubmitOtp, onPrimaryPressModal };
}

export function onAppEventHandlers(params: {
  t: TFunction;
  setInfoModal: React.Dispatch<React.SetStateAction<ModalState>>;
  setErrTxtMsg: React.Dispatch<React.SetStateAction<string>>;
  setDisableOTP: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsChangeSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setNextEnable: React.Dispatch<React.SetStateAction<boolean>>;
  setOtp: React.Dispatch<React.SetStateAction<string>>;
  isSubmitOtpError: boolean;
  isSubmitOtpSuccess: boolean;
  submitOtpData: any;
  submitOtpError: string[];
  attempts: number;
}) {
  const {
    t,
    setInfoModal,
    setErrTxtMsg,
    setDisableOTP,
    setIsLoading,
    setNextEnable,
    setOtp,
    isSubmitOtpError,
    isSubmitOtpSuccess,
    setIsChangeSuccess,
    submitOtpData,
    submitOtpError,
    attempts,
  } = params;

  const onErrorSubmitOtp = () => {
    if (isSubmitOtpError && submitOtpError) {
      setIsLoading(false);
      setDisableOTP(false);
      setOtp('');
      setNextEnable(false);
      if (submitOtpError[0] === 'GG-PHONE-09' || submitOtpError[0] === 'GG-PHONE-12') {
        return setInfoModal({
          visible: true,
          data: returnBottomSheetContent(
            SECURITY_PIN_INFO_MODAL.VERIFY_PIN_LOCKED,
            SECURITY_PIN_INFO_MODAL.VERIFY_PIN_LOCKED,
          ),
          errorCode: 'VERIFY_PIN_LOCKED',
        });
      }
      let text = t(`errorCodes.${submitOtpError}`);
      text = text.replace('{{attempts}}', String(MAX_OTP_CONFIRM_ATTEMPTS - attempts));
      setErrTxtMsg(text);
    }
  };

  const onSuccessSubmitOtp = () => {
    if (isSubmitOtpSuccess && submitOtpData) {
      setIsLoading(false);
      setDisableOTP(true);
      setIsChangeSuccess(true);

      return setInfoModal({
        visible: true,
        data: returnBottomSheetContent(
          SECURITY_PIN_INFO_MODAL.PHONE_CHANGE_SUCCESS,
          SECURITY_PIN_INFO_MODAL.PHONE_CHANGE_SUCCESS,
        ),
        errorCode: 'PHONE_CHANGE_SUCCESS',
      });
    }
  };
  return { onErrorSubmitOtp, onSuccessSubmitOtp };
}
