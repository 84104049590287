import SkeletonComponent from '@atoms/SkeletonComponent';
import appStyles from '@root/appStyles';
import React from 'react';
import { View } from 'react-native';

const ShimmerSingleColumnNominal: React.FC = () => (
  <View>
    {[...Array(4)].map(o => (
      <SkeletonComponent key={o} style={appStyles.flex1} />
    ))}
  </View>
);

export default ShimmerSingleColumnNominal;
