import { apiPhoneNumberChange } from '@api/apiCall';
import BtnPhoneChangeNext from '@atoms/BtnPhoneChangeNext';
import LoginInfoSheet from '@molecules/LoginInfoSheet';
import LoginPhoneInput from '@molecules/LoginPhoneInput';
import { NavigationProps } from '@navigation/types';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { SECURITY_PIN_INFO_MODAL } from '@root/constants';
import { getStore } from '@store/storeUtils';
import { useMutation } from '@tanstack/react-query';
import { returnBottomSheetContent } from '@utils/utils';
import { Text } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, SafeAreaView } from 'react-native';

import { onUserInteractionHandlers, onAppEventHandlers } from './phoneChangeUtils';
import styles from './styles';

export interface PhoneChangePayload {
  token: string;
  otpType: string;
  mobileNumber: string;
}

const GGPhoneChange: React.FC = () => {
  const navigation = useNavigation<NavigationProps>();
  // Global
  const { t } = useTranslation('common');
  const { tenantSettings, setLoading, securityPinOpen } = getStore();
  const { countryCode } = tenantSettings;

  // Local States
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isError, setError] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [infoModal, setInfoModal] = useState({
    visible: false,
    data: returnBottomSheetContent(
      SECURITY_PIN_INFO_MODAL.VERIFY_PIN_LOCKED,
      SECURITY_PIN_INFO_MODAL.VERIFY_PIN_LOCKED,
    ),
    errorCode: 'VERIFY_PIN_LOCKED',
  });

  // Mutation
  const {
    mutate: submitPhoneNumber,
    isLoading: isPhoneChangeLoading,
    isSuccess: isPhoneChangeSuccess,
    data: phoneChangeSuccessData,
    isError: isPhoneChangeError,
    error: phoneChangeError,
  } = useMutation({
    mutationFn: (payload: PhoneChangePayload) => apiPhoneNumberChange(payload),
  });

  // User started events
  const { onChangePhoneNumber, onSubmitPhoneNumber, onPrimaryPressModal } = onUserInteractionHandlers({
    navigation,
    t,
    isError,
    infoModal,
    countryCode,
    securityPinOpen,
    setError,
    setPhoneNumber,
    setIsDisabled,
    setIsLoading,
    submitPhoneNumber,
    phoneNumber,
  });

  // Events the application triggered
  const { onErrorPhoneNumber, onSuccessPhoneNumber } = onAppEventHandlers({
    navigation,
    t,
    setInfoModal,
    setError,
    setIsDisabled,
    setIsLoading,
    isPhoneChangeError,
    isPhoneChangeSuccess,
    phoneChangeSuccessData,
    phoneChangeError: phoneChangeError as unknown as string[],
  });

  // Reactive
  useEffect(() => {
    setLoading(isPhoneChangeLoading);
  }, [isPhoneChangeLoading]);

  useEffect(() => {
    onErrorPhoneNumber();
  }, [isPhoneChangeError, phoneChangeError]);

  useEffect(() => {
    onSuccessPhoneNumber();
  }, [isPhoneChangeSuccess, phoneChangeSuccessData]);

  // Components
  return (
    <SafeAreaView style={appStyles.safeAreaView}>
      <View style={styles.parent}>
        <View>
          <Text style={styles.textStyles} testID="txtPlain" sentry-label="txtPlain" variant="lg-normal">
            {t('phoneNumberChange.enterPhoneText')}
          </Text>
        </View>
        <View style={styles.phoneInputs}>
          <LoginPhoneInput
            placeholderText="XXXXXXXXXXX"
            sError={isError}
            phoneNumber={phoneNumber}
            setPhoneNumber={onChangePhoneNumber}
          />
          <View style={{ marginTop: 24 }}>
            <BtnPhoneChangeNext isDisabled={isDisabled} isLoading={isLoading} performNext={onSubmitPhoneNumber} />
          </View>
        </View>

        <LoginInfoSheet onPressPrimary={onPrimaryPressModal} loginInfo={infoModal} onClose={onPrimaryPressModal} />
      </View>
    </SafeAreaView>
  );
};

export default GGPhoneChange;
