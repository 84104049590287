import { Ionicons } from '@expo/vector-icons';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { Modal, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BPTnCHeader: React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <Modal.Header flexDirection="row">
      <Ionicons name="ios-document-text-sharp" size={24} color={colors.secondary.orchid} />
      <Text color={colors.secondary.orchid} variant="xl-bold" style={appStyles.marginLeft(8)}>
        {t('reqTxSheet.tncHeader')}
      </Text>
    </Modal.Header>
  );
};

export default BPTnCHeader;
