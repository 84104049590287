import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import { SVG_ICONS } from '@utils/SvgIcons';
import { View, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';

const AppliedReferral: React.FC = () => {
  const { validatedReferralCode } = getStore() as { validatedReferralCode?: string };
  const { t } = useTranslation('common');

  if (validatedReferralCode) {
    return (
      <View flexDirection="row" alignItems="center" alignContent="center" style={appStyles.marginTop(21)}>
        <View flex={1} flexDirection="row">
          <View>{SVG_ICONS.referral.giftTwo}</View>
          <View flexWrap="wrap" flexDirection="row" flex={1} style={appStyles.marginLeft(7)}>
            <Text testID="appliedReferralContainer1" variant="sm-normal" justifyContent="center">
              {t('referral.appliedReferral1')}
            </Text>
            <Text variant="sm-bold">{`${t('referral.appliedReferral2')} `}</Text>
            <Text testID="appliedReferralContainer3" variant="sm-normal" justifyContent="center">
              {t('referral.appliedReferral3')}
            </Text>
          </View>
        </View>
      </View>
    );
  }
  return null;
};

export default React.memo(AppliedReferral);
