import colors from '@theme/colors';
import { Button, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

interface BtnPhoneChangeNextProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  performNext?: () => void;
}

const BtnPhoneChangeNext: React.FC<BtnPhoneChangeNextProps> = ({
  isLoading = false,
  isDisabled = false,
  performNext = () => {},
}) => {
  const { t } = useTranslation('common');

  return (
    <Button
      isLoading={isLoading}
      isDisabled={isDisabled}
      variant="outline"
      testID="btnPhoneChangeNext"
      sentry-label="btnPhoneChangeNext"
      style={styles.btnSmsStyles}
      onPress={() => performNext()}>
      <Text testID="txtPhoneChangeNext" variant="sm-bold" color={colors.secondary.orchid}>
        {t('phoneNumberChange.nextButtonText')}
      </Text>
    </Button>
  );
};

export default BtnPhoneChangeNext;
