import { bungkusKado } from '@assets/png/imagesPng';
import useFeatureFlag from '@customHooks/useFeatureFlag';
import { MaterialIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { FEATURE_FLAGS } from '@root/constants';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { scale } from '@utils/normalize';
import { onRedirectionHandler } from '@utils/utils';
import { Text } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, TouchableOpacity, View, ViewStyle } from 'react-native';
import Animated, { useSharedValue, useAnimatedStyle, withRepeat, withTiming, Easing } from 'react-native-reanimated';
import styles from './styles';

interface SuccessPageBanner {
  title: string;
  [key: string]: any;
}

interface FeatureFlags {
  [FEATURE_FLAGS.CHAT_GPT]?: {
    show: boolean;
  };
  [key: string]: any;
}

export const onHideBannerHandler =
  (
    successPageBanners: SuccessPageBanner[],
    setShowBanner: (show: boolean) => void,
    stopAnimation: () => void,
  ): (() => void) =>
  () => {
    const e1 = AMPLITUDE_CONSTANTS.interactionBanner.floatingtext_closed;
    AmplitudeHelper.logEvent(e1.name, {
      [e1.attributes.source]: AMPLITUDE_EVENT_SOURCES.home_page,
      [e1.attributes.banner_name]: successPageBanners[0].title,
    });

    setShowBanner(false);
    stopAnimation();
  };

export const cleanUpHandler =
  (stopAnimation: () => void): (() => void) =>
  () => {
    stopAnimation();
  };

export const getElementStyle = (isFeatureAvailale: FeatureFlags): ViewStyle | undefined =>
  isFeatureAvailale[FEATURE_FLAGS.CHAT_GPT]?.show ? { alignSelf: undefined } : undefined;

const GGFloatingReferral: React.FC = () => {
  const translateY = useSharedValue(0);
  const DURATION = 420;
  const [showBanner, setShowBanner] = useState(true);
  const { successPageBanners } = getStore();
  const navigation = useNavigation();
  const { t } = useTranslation('common');
  const [isFeatureAvailale] = useFeatureFlag({
    featureName: [FEATURE_FLAGS.CHAT_GPT],
  });

  useEffect(() => {
    translateY.value = withRepeat(withTiming(-10, { duration: DURATION, easing: Easing.linear }), -1, true);

    return cleanUpHandler(() => {
      translateY.value = 0;
    });
  }, [translateY]);

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ translateY: translateY.value }],
  }));

  const e = AMPLITUDE_CONSTANTS.interactionBanner.floatingtext_clicked;
  if (showBanner && successPageBanners?.length > 0)
    return (
      <Animated.View
        testID="floatingView"
        style={[styles.container, animatedStyle, getElementStyle(isFeatureAvailale)]}>
        <View style={appStyles.flexRow}>
          <TouchableOpacity
            testID="floatingTouchBtn"
            onPress={onRedirectionHandler(successPageBanners, navigation, e.name, {
              [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.home_page,
              [e.attributes.banner_name]: successPageBanners[0].title,
            })}
            style={appStyles.flexRow}>
            <View style={styles.avatarContainer}>
              <Image source={bungkusKado} style={styles.imageStyle} />
            </View>
            <View>
              <View style={appStyles.flexRow}>
                <Text variant="sm-bold">{t('referral.floatingText1')}</Text>
                <Text variant="sm-bold" color={colors.primary.carnation}>
                  {' '}
                  {t('referral.floatingText2')}
                </Text>
              </View>
              <Text variant="sm-bold" color={colors.secondary.orchid} style={styles.textStyle}>
                {t('referral.floatingText3')}
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            testID="floatingClosePress"
            onPress={onHideBannerHandler(successPageBanners, setShowBanner, () => {
              translateY.value = 0;
            })}
            style={styles.closeIconContainer}>
            <MaterialIcons name="close" testID="floatingClosePress1" size={scale(24)} />
          </TouchableOpacity>
        </View>
      </Animated.View>
    );

  return null;
};

export default GGFloatingReferral;
