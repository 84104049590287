import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { View } from 'native-base';
import React from 'react';

interface DotComponentProps extends React.ComponentProps<typeof View> { }

function DotComponent(props: DotComponentProps): JSX.Element {
  return <View backgroundColor={colors.neutral.darkMist} style={appStyles.dotStyles} {...props} />;
}

export default DotComponent;
