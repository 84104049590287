import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import { NetInfoState } from '@react-native-community/netinfo';
import appStyles from '@root/appStyles';
import * as Sentry from '@sentry/react';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { restartAppOnError } from '@utils/helper';
import { restartWebOnError } from '@utils/helper.web';
import { formatUnderScoreLowerCase } from '@utils/utils';
import { Text, View } from 'native-base';
import React from 'react';
import { Platform } from 'react-native';
import styles from './styles';

function getNetworkStrengthBasedOnState(state: NetInfoState): string | undefined {
  if (state.type === 'wifi') {
    return state?.details?.strength?.toString();
  }
  if (state.type === 'cellular') {
    return state?.details?.cellularGeneration;
  }
  return undefined;
}

export function validateConnectionChange(
  connectionState: NetInfoState | null,
  setConnectionState: (state: NetInfoState) => void,
  setShowNoConnection: (show: boolean) => void,
) {
  return (state: NetInfoState) => {
    if (state.type !== connectionState?.type) {
      AmplitudeHelper.logEvent('eng_diag_network', {
        networkType: state.type,
        networkStrength: getNetworkStrengthBasedOnState(state),
      });

      setConnectionState(state);
      setShowNoConnection(state.type === 'none');
    }
  };
}

function restartAppOrWebOnError(errorMessage: string): void {
  const source = 'toast';
  if (Platform.OS === 'web') {
    restartWebOnError(errorMessage, source);
  } else {
    restartAppOnError(errorMessage, source);
  }
}

function connectionComponent(showNoConnection: boolean, t: (key: string) => string) {
  if (showNoConnection) {
    const e = AMPLITUDE_CONSTANTS.internalServerErrorToast.internal_server_error_toast;
    const errorMessage = t('common.noInternetConnection');
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.failure_message]: formatUnderScoreLowerCase(errorMessage),
    });
    Sentry.addBreadcrumb({
      category: 'info',
      message: errorMessage,
      level: 'info',
    });
    return (
      <View testID="toastParent" style={styles.toastParent}>
        <Ionicons
          name="warning-outline"
          testID="iconClose"
          size={24}
          color={colors.neutral.cotton}
          style={appStyles.marginLeft(8)}
        />
        <Text
          testID="txtNoInternet"
          alignSelf="stretch"
          flex={1}
          color={colors.neutral.cotton}
          variant="sm-bold"
          style={styles.connectionTextStyles}>
          {errorMessage}
        </Text>
        <MaterialIcons
          testID="iconRefresh"
          name="refresh"
          size={24}
          color={colors.neutral.cotton}
          onPress={() => {
            restartAppOrWebOnError(errorMessage);
          }}
          style={{ ...appStyles.marginLeft(8), ...appStyles.marginRight(12) }}
        />
      </View>
    );
  }
  return null;
}

export default connectionComponent;
