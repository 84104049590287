import { apiSecurityPinOpenVerify, apiSecurityPinSimpleRegistration, apiSecurityPinVerify } from '@api/apiCall';
import { fetchSecurityPinOpen } from '@api/useQueryCalls';
import { securityPin as pinImage } from '@assets/png/imagesPng';
import useFeatureFlag from '@customHooks/useFeatureFlag';
import LoginInfoSheet from '@molecules/LoginInfoSheet';
import navigationConstants from '@navigation/navigationConstants';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { FEATURE_FLAGS, SECURITY_PIN_INFO_MODAL } from '@root/constants';
import { getStore } from '@store/storeUtils';
import { useMutation } from '@tanstack/react-query';
import colors from '@theme/colors';
import OTPInputView from '@twotalltotems/react-native-otp-input';
import { returnBottomSheetContent } from '@utils/utils';
import { View, Text, Button } from 'native-base';
// import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Pressable } from 'react-native';
import { logHelpClicked, logOnPage } from './analyticsCreatePiin';
import {
  MODAL_KEYS,
  getModalKeyBasedOnFeatureFlags,
  getTitleName,
  otherPinHandlers,
  pinHandlerFunctions,
  shoulRenderInstructions as shouldRenderInstructions,
  shouldRenderOnOpenPinError,
  shouldRenderOnVerifyOpenSuccess,
  shouldRenderOnVerifyPinError,
  shouldRenderOnVerifyPinSuccess,
  shouldRenderSuccessPinCreation,
  shouldShowErrorText,
  verifyAndResetPinHandlers,
} from './pinUtils';
import styles from './styles';

type RootStackParamList = {
  [navigationConstants.GG_CREATE_PIN]: {
    type: 'create';
    onVerifyCallBack?: () => void;
    onVerifyValues?: Record<string, any> | Array<any>;
  };
  [navigationConstants.GG_VERIFY_PIN]: {
    type: 'verifyPin';
    onVerifyCallBack?: () => void;
    onVerifyValues?: Record<string, any> | Array<any>;
  };
  [navigationConstants.GG_VERIFY_PIN_WITH_EXPIRY]: {
    type: 'verifyPinWithExpiry';
    onVerifyCallBack?: () => void;
    onVerifyValues?: Record<string, any> | Array<any>;
  };
};

type SecurityPinRouteProp = RouteProp<RootStackParamList>;

interface SecurityPinOpenResponse {
  attempts: number;
  remainingAttempts: number;
  token: string;
}

const GGCreatePin: React.FC = () => {
  const [otpNew, setOtp] = useState('');
  const [confirmCopy, setConfirmCopy] = useState('');
  const [invalidConfirms, setInvalidConfirms] = useState(0);
  const [clearInputs, setClearInputs] = useState(false);
  const [errTxtMsg, setErrTxtMsg] = useState('');
  const [verifyInvalid, setVerifyInvalid] = useState(false);

  const route = useRoute<SecurityPinRouteProp>();
  const navigation = useNavigation();
  const { t } = useTranslation('common');
  const [isFeatureAvailale] = useFeatureFlag({
    featureName: [FEATURE_FLAGS.BANK_SEL_TRNSF, FEATURE_FLAGS.PHONE_ENROLL],
  });
  const { pinRegister: securityPin, setLoading, setShowPinRegister, securityPinOpen, setSecurityPinOpen } = getStore();

  const {
    isLoading,
    isSuccess,
    data,
    mutate: securityPinRegistration,
  } = useMutation({
    mutationFn: payload => apiSecurityPinSimpleRegistration(payload),
  });

  // Verify Pin (i.e Phone & Bank Enrollment)
  const {
    isLoading: isLoadingVerify,
    isSuccess: isSuccessVerify,
    data: dataVerify,
    isError: isErrorVerify,
    error: errorVerify,
    mutate: securityPinVerify,
  } = useMutation({ mutationFn: payloadVerify => apiSecurityPinVerify(payloadVerify) });

  // Verify Pin with Expiry (i.e Phone Number Change)
  const {
    isLoading: isLoadingOpenVerify,
    isSuccess: isSuccessOpenVerify,
    data: dataOpenVerify,
    isError: isErrorOpenVerify,
    error: errorOpenVerify,
    mutate: securityPinOpenVerify,
  } = useMutation({ mutationFn: payloadVerify => apiSecurityPinOpenVerify(payloadVerify) });

  let typeScreenSetter = route.params?.type;
  const { onVerifyCallBack, onVerifyValues } = route.params;
  if (route.name === navigationConstants.GG_CREATE_PIN) {
    typeScreenSetter = 'create';
  } else if (route.name === navigationConstants.GG_VERIFY_PIN) {
    typeScreenSetter = 'verifyPin';
  } else if (route.name === navigationConstants.GG_VERIFY_PIN_WITH_EXPIRY) {
    typeScreenSetter = 'verifyPinWithExpiry';
  }

  const [typeScreen, setTypeScreen] = useState<'create' | 'confirm' | 'verifyPin' | 'verifyPinWithExpiry'>(
    typeScreenSetter,
  );

  const otpRef = useRef(null);
  const [disableOTP, setDisableOTP] = useState(false);

  const [infoModal, setInfoModal] = useState({
    visible: false,
    data: returnBottomSheetContent(
      SECURITY_PIN_INFO_MODAL[MODAL_KEYS.GG_01_FALLBACK],
      SECURITY_PIN_INFO_MODAL[MODAL_KEYS.GG_01_FALLBACK],
    ),
    errorCode: MODAL_KEYS.GG_01_FALLBACK,
  });

  const queryPinOpen = fetchSecurityPinOpen({
    screenName: 'changePhoneNumber',
  });

  const { errorHandler, onCodeChangedHandler, onPrimaryPress, onPressSecondaryHandler, onCloseModal } =
    pinHandlerFunctions({
      setOtp,
      setVerifyInvalid,
      errTxtMsg,
      setErrTxtMsg,
      setClearInputs,
      route,
      navigation,
      setInfoModal,
      infoModal,
      setTypeScreen,
      MODAL_KEYS,
      onVerifyCallBack,
      onVerifyValues,
    });

  const getModalNameKey = getModalKeyBasedOnFeatureFlags(isFeatureAvailale, MODAL_KEYS)();

  const {
    verifyPinHandler,
    verifyPinErrorHandling,
    resetPinHandler,
    verifyPinOpenHandler,
    verifyPinOpenErrorHandling,
  } = verifyAndResetPinHandlers({
    securityPin,
    setShowPinRegister,
    setVerifyInvalid,
    route,
    t,
    errorHandler,
    setInfoModal,
    securityPinVerify,
    securityPinOpenVerify,
    securityPinOpen,
    setSecurityPinOpen,
  });
  const { getPageNumber, onNextPress, onCodeFilledHandler } = otherPinHandlers({
    typeScreen,
    route,
    verifyPinHandler,
    verifyPinOpenHandler,
    setDisableOTP,
    confirmCopy,
    invalidConfirms,
    setInfoModal,
    setInvalidConfirms,
    errorHandler,
    setConfirmCopy,
    otpNew,
    setTypeScreen,
    securityPinRegistration,
    t,
    securityPinOpen,
  });

  useEffect(() => {
    logOnPage(getPageNumber, route);
  }, [route.params]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (typeScreen === 'verifyPinWithExpiry') {
      queryPinOpen.refetch();
      setLoading(true);
    }
  }, []);

  useEffect(() => {
    shouldRenderSuccessPinCreation(isSuccess, data, setInfoModal, getModalNameKey, setShowPinRegister);
  }, [isSuccess, data]);

  // Verify Pin with Expiry (i.e Phone Number Change)
  useEffect(() => {
    const _data = queryPinOpen.data as unknown as SecurityPinOpenResponse;
    setSecurityPinOpen({
      attempts: _data?.attempts,
      remainingAttempts: _data?.remainingAttempts,
      token: _data?.token,
    });
    setLoading(false);
  }, [queryPinOpen.isSuccess, queryPinOpen.data]);

  useEffect(() => {
    setLoading(isLoadingVerify);
  }, [isLoadingVerify]);

  useEffect(() => {
    setLoading(isLoadingOpenVerify);
  }, [isLoadingOpenVerify]);

  useEffect(() => {
    shouldRenderOnVerifyPinError(isErrorVerify, errorVerify, verifyPinErrorHandling);
  }, [isErrorVerify, errorVerify]);

  useEffect(() => {
    shouldRenderOnOpenPinError(queryPinOpen.isError, queryPinOpen.error, verifyPinOpenErrorHandling);
  }, [queryPinOpen.isError, queryPinOpen.error]);

  useEffect(() => {
    shouldRenderOnVerifyPinError(isErrorOpenVerify, errorOpenVerify, verifyPinOpenErrorHandling);
  }, [isErrorOpenVerify, errorOpenVerify]);

  useEffect(() => {
    shouldRenderOnVerifyPinSuccess(
      isSuccessVerify,
      dataVerify,
      route,
      onVerifyCallBack,
      onVerifyValues,
      otpNew,
      setShowPinRegister,
    );
  }, [isSuccessVerify, dataVerify]);

  useEffect(() => {
    shouldRenderOnVerifyOpenSuccess(isSuccessOpenVerify, dataOpenVerify, route, onVerifyCallBack, setShowPinRegister);
  }, [isSuccessOpenVerify, dataOpenVerify]);

  function shouldRenderButton(show) {
    if (show)
      return (
        <Button testId="lanjutkanButton" onPress={onNextPress} isDisabled={disableOTP || otpNew.length < 6}>
          {t('common.continue')}
        </Button>
      );
    return null;
  }

  const onHelpHandler = () => {
    resetPinHandler('link');
    logHelpClicked();
  };

  function shouldRenderResetLink(show) {
    if (show)
      return (
        <View flex={1}>
          <View flex={1} />
          <Pressable onPress={onHelpHandler}>
            <Text variant="sm-bold" color={colors.secondary.orchid} alignSelf="center" underline>
              {t('securityPin.resetLinkText')}
            </Text>
          </Pressable>
        </View>
      );

    return null;
  }

  return (
    <View style={styles.createPinContainer}>
      <View style={appStyles.safeAreaView}>
        <Image style={styles.pinImageContainer} source={pinImage} />
        <Text style={styles.pinTitleContainer} variant="lg-bold">
          {getTitleName(typeScreen, t)}
        </Text>
        <OTPInputView
          pinCount={6}
          ref={otpRef}
          code={otpNew}
          style={styles.otpInputContainer}
          clearInputs={clearInputs}
          autoFocusOnLoad={false}
          onCodeFilled={onCodeFilledHandler}
          codeInputFieldStyle={[styles.otpDefault, verifyInvalid && styles.otpFailed]}
          secureTextEntry
          onCodeChanged={onCodeChangedHandler}
          codeInputHighlightStyle={[styles.otpHightLight]}
        />
        {shouldShowErrorText(errTxtMsg)}
        {shouldRenderInstructions(typeScreen === 'create' || typeScreen === 'confirm', isFeatureAvailale, t)}
        {shouldRenderResetLink(typeScreen === 'verifyPin')}
        <LoginInfoSheet
          onPressPrimary={onPrimaryPress}
          onPressSecondary={onPressSecondaryHandler}
          loginInfo={infoModal}
          onClose={onCloseModal}
        />
      </View>
      <View style={styles.btnVerifyCreate}>
        {shouldRenderButton(typeScreen === 'create' || typeScreen === 'confirm')}
      </View>
    </View>
  );
};

// GGCreatePin.propTypes = {};
// GGCreatePin.defaultProps = {};
export default GGCreatePin;
