import SkeletonComponent from '@atoms/SkeletonComponent';
import React from 'react';
import { View } from 'react-native';

const ShimmerProfile: React.FC = () => (
  <View>
    {Array.from({ length: 9 }, (_, index) => (
      <SkeletonComponent key={index} />
    ))}
  </View>
);

export default ShimmerProfile;
