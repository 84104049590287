const sConflictData = 'Konflik data';
const sPayloadBadRequestID = 'Permintaan payload tidak valid';
const sInternalErrorID = 'Kesalahan internal';
const txnIncomeTextInd = 'Total Pendapatan mengacu pada dana yang diberikan oleh perusahaan Kamu';
const sBalanceNotEnoughID = 'Keseimbangan tidak cukup';
const sNotInOpenPeriodID = 'Tidak dalam periode terbuka';
const sAccountDisabledID = 'Akun dinonaktifkan';
const sAccountVerificationRuleID = 'Aturan validasi akun gagal';
const sInvalidReferralID = 'Kode rujukan tidak valid';
const customerIdIndo = 'ID Pelanggan';
const termsAndConditionInd = 'Syarat dan Ketentuan';
const phoneNumberInd = 'Data Pribadi';
const detailTransaksi = 'Detail Transaksi';
const lockPeriodText =
  'Masa transaksimu sedang tidak aktif. Coba cek akun poin, uang kantor atau uang kas kamu jika ada';
const adminFee = 'Biaya Admin';

const id = {
  errorCodes: {
    'GG-BENE-01': sConflictData,
    'GG-BENE-02': sPayloadBadRequestID,
    'GG-BENE-03': 'Sudah ada',
    'GG-BENE-04': 'Jumlah penerima yang terdaftar sudah mencapai batas',
    'GG-BENE-05': 'Penghapusan tidak diizinkan',
    'GG-CON-OTP-01': 'Token OTP diperlukan',
    'GG-CON-OTP-02': 'Token OTP tidak valid',
    'GG-CON-OTP-03': 'Token OTP sudah diverifikasi',
    'GG-CON-OTP-04': 'Kode verifikasi sudah kadaluarsa.\nSilahkan coba lagi atau kirim ulang kode',
    'GG-CON-OTP-05': 'Token OTP tidak ditemukan',
    'GG-CON-OTP-06': 'Kode verifikasi tidak valid.\nSilahkan coba lagi atau kirim ulang kode',
    'GG-CON-OTP-07': 'Kode verifikasi tidak valid.\nSilahkan coba lagi atau kirim ulang kode',
    'GG-CON-OTP-08': 'Terlalu banyak permintaan kode verifikasi.\nMohon tunggu 1 jam untuk mencoba kembali',
    'GG-EMP-01': 'Pegawai tidak ditemukan',
    'GG-EMP-02': 'Status pegawai tidak aktif',
    'GG-EMP-03': 'Pegawai ditangguhkan',
    'GG-EMP-04': 'Pemberi kerja pegawai ditangguhkan',
    'GG-EMP-05': 'Transaksi pegawai terkunci',
    'GG-EMP-06': 'Pemberi kerja pegawai tidak aktif',
    'GG-EMPYER-01': 'Pemberi kerja tidak ditemukan',
    'GG-EMPYER-02': 'Status pemberi kerja tidak aktif',
    'GG-EMPYER-03': 'Pemberi kerja ditangguhkan',
    'GG-EMPYER-04': 'Gagal menyimpan pemberi kerja',
    'GG-EMPYER-05': sConflictData,
    'GG-EMPYER-LOGIN-01': 'Email tidak valid',
    'GG-EMPYER-LOGIN-02': 'Email atau kata sandi salah',
    'GG-EMPYER-LOGIN-03': 'Pemberi kerja ditangguhkan',
    'GG-EMPYER-LOGIN-04': 'Status pemberi kerja tidak aktif',
    'GG-GEN-01': 'Permintaan tidak valid',
    'GG-GEN-02': 'Terlalu banyak permintaan',
    'GG-GEN-03': 'API tidak ditemukan',
    'GG-GEN-04': sInternalErrorID,
    'GG-GEN-05': 'JWT tidak sah',
    'GG-GEN-06': 'AppKey tidak sah',
    'GG-GEN-07': 'Fitur tidak diotorisasi',
    'GG-GEN-08': 'Waktu respons habis',
    'GG-GEN-09': sConflictData,
    'GG-GEN-10': 'Kesalahan parameter batas',
    'GG-GEN-11': 'Kesalahan parameter lewati',
    'GG-GEN-12': 'Parameter batas tidak valid',
    'GG-GEN-13': 'Parameter lewati tidak valid',
    'GG-GOLD-01': 'Data permintaan tidak valid',
    'GG-GOLD-02': 'Profil sudah ada',
    'GG-GOLD-03': 'Profil Treasury tidak ditemukan',
    'GG-OTP-01': 'Nomor telepon tidak valid',
    'GG-OTP-02': 'Nomor telepon diblokir',
    'GG-OTP-03': 'AppKey tidak valid',
    'GG-OTP-04': 'Mengirim ulang habis',
    'GG-OTP-05': 'Kesalahan layanan SMS OTP',
    'GG-OTP-06': 'Jenis pengiriman pesan tidak valid',
    'GG-OTP-07': 'Nomor telepon belum terdaftar',
    'GG-OTP-08': 'Terlalu banyak permintaan kode verifikasi. Mohon tunggu 1 jam untuk mencoba kembali',
    'GG-PHONE-01': sPayloadBadRequestID,
    'GG-PHONE-02': 'Sudah ada',
    'GG-PHONE-03': 'Jumlah nomor telepon yang terdaftar sudah mencapai batas',
    'GG-PHONE-04': 'Penghapusan tidak diizinkan',
    'GG-PRODUCT-01': sInternalErrorID,
    'GG-PRODUCT-02': 'Produk tidak ditemukan',
    'GG-PRODUCT-03': 'Provider diatur oleh fitur bendera',
    'GG-PRODUCT-04': 'Produk tidak tersedia',
    'GG-SIGNUP-01': 'Data sudah ada',
    'GG-SIGNUP-02': sInternalErrorID,
    'GG-SIGNUP-03': sPayloadBadRequestID,
    'GG-SIGNUP-04': 'Nama Pemberi Kerja tidak valid',
    'GG-SIGNUP-05': 'Nama Pengguna tidak valid',
    'GG-SIGNUP-06': 'Payload Nomor Telepon tidak valid',
    'GG-SIGNUP-07': 'Jenis Identitas tidak valid',
    'GG-SIGNUP-08': 'ID Identitas tidak valid',
    'GG-TRX-01': 'Pengguna dalam periode kunci',
    'GG-TRX-02': 'Transaksi pegawai ditangguhkan',
    'GG-TRX-03': 'Kategori pegawai diblokir',
    'GG-TRX-04': 'Akun diblokir untuk kategori',
    'GG-TRX-05': 'Saldo tidak mencukupi',
    'GG-TRX-06': sInternalErrorID,
    'GG-TRX-07': 'Kesalahan provider',
    'GG-TRX-08': 'Produk tidak ditemukan',
    'GG-TRX-09': 'Produk tidak tersedia',
    'GG-USER-01': 'Pengguna tidak ditemukan',
    'GG-USER-02': 'Status pengguna tidak aktif',
    'GG-USER-03': 'Pengguna ditangguhkan',
    'GG-USER-04': 'Gagal mengambil info pengguna',
    'GG-USER-05': 'KTP Pengguna tidak ditemukan',
    'GG-KTP-01': 'Nomor KTP tidak valid',
    'GG-KTP-02': 'Nama KTP tidak valid',
    'GG-KTP-03': 'Panjang nomor KTP melebihi batas',
    'GG-KTP-04': 'Panjang nama KTP melebihi batas',
    'GG-KTP-05': 'Panjang angka KTP kurang dari panjang yang dibutuhkan',
    'GG-RESEND-OTP-01': '',
    'GG-RESEND-OTP-02': '',
    'GG-TRX-10': '',
    'GG-TRX-11': '',
    'GG-TRX-13': '',
    'GG-TRX-14': 'Jumlah yang diminta kurang dari minimum penarikan',
    'GG-PRODUCT-05': 'ID tipe akun yang disediakan tidak valid',
    'GG-PRODUCT-06': 'Muatan permintaan buruk',
    'GG-REASON-TYPE-01': 'Jenis Alasan Filter Invalid',
    'GG-EXT-01': 'Kesalahan API sisi mitra',
    'GG-PARTNER-01': 'diperlukan majikan',
    'GG-PARTNER-02': 'majikan harus menjadi array',
    'GG-PARTNER-03': 'GroupName harus menjadi array',
    'GG-PARTNER-04': 'SecondaryGroupName harus menjadi array',
    'GG-PARTNER-05': 'Dibeletlah harus boolean',
    'GG-PARTNER-06': 'Halaman harus menjadi angka',
    'GG-PARTNER-07': 'Nomor halaman tidak valid',
    'GG-PARTNER-08': 'Batas harus menjadi angka',
    'GG-PARTNER-09': 'Batasi tidak valid',
    'GG-INVOICE-01': 'Tidak ada catatan yang ditemukan untuk generasi RN',
    'GG-PC-01': 'Siklus pembayaran tidak ditemukan',
    'GG-TRXH-01': 'Status bukan array',
    'GG-TRXH-02': 'ID siklus pembayaran bukan string',
    'GG-REQ-WD-01': 'Akun tidak valid',
    'GG-REQ-WD-02': 'Alasan tidak valid',
    'GG-REQ-WD-03': sBalanceNotEnoughID,
    'GG-REQ-WD-04': sNotInOpenPeriodID,
    'GG-REQ-WD-05': sAccountDisabledID,
    'GG-REQ-WD-06': sAccountVerificationRuleID,
    'GG-REQ-WD-07': 'Nomor rekening bank tidak terdaftar / disetujui',
    'GG-REQ-WD-08': 'Nomor rekening bank tidak valid',
    'GG-REQ-WD-09': 'Jumlah permintaan yang tidak valid',
    'GG-REQ-WD-10': 'ID Alasan Tidak Valid',
    'GG-REQ-WD-11': 'validation',
    'GG-REQ-WD-12': sInvalidReferralID,
    'GG-CON-WD-01': 'Transaksi tidak ditemukan',
    'GG-CON-WD-02': 'Akun tidak valid',
    'GG-CON-WD-03': sBalanceNotEnoughID,
    'GG-CON-WD-04': sNotInOpenPeriodID,
    'GG-CON-WD-05': sAccountDisabledID,
    'GG-CON-WD-06': sAccountVerificationRuleID,
    'GG-RF-01': sInvalidReferralID,
    'GG-RF-02': 'Kode rujukan tidak berlaku. Karena ini bukan tnx pertama',
    'GG-RF-03': 'Kamu tidak bisa memakai kode Referral sendiri. Mohon gunakan kode Referral milik temanmu',
    'GG-RF-04': 'Kode tidak valid. Harap cek ulang kode referral dari temanmu.',
    'GG-RF-05': 'Akun teman pemilik kode referral sudah tidak aktif. Mohon gunakan kode lain',
    'GG-RF-06': 'Status teman pemilik kode referral sedang tidak aktif. Mohon gunakan kode lain',
    'GG-POINT-01': 'Akun poin tidak ada',
    'GG-SF-01': 'Profil gaji belum terdaftar',
    'GG-LOAN-01': 'Kode prodcut tidak valid untuk pinjaman',
    'GG-LOAN-02': 'Karyawan tidak memenuhi syarat untuk produk pinjaman',
    'GG-LOAN-03': 'Kode pinjaman harus berupa string',
    'GG-LOAN-04': 'Kode pinjaman tidak boleh kosong',
    'GG-LOAN-05': 'Aplikasi pinjaman sudah ada',
    'GG-LOAN-06': 'ID aplikasi tidak valid',
    'GG-LOAN-07': 'ID aplikasi harus menjadi nomor yang valid dan bukan nol',
    'GG-CON-BP-01': 'ID penyedia tidak ditemukan',
    'GG-CON-BP-02': 'Permintaan telah kedaluwarsa. Tolong tanyakan lagi',
    'GG-REQ-BP-01': 'akun tidak ditemukan',
    'GG-REQ-BP-02': 'Alasan tidak valid',
    'GG-REQ-BP-03': 'Saldo GajiGesa tidak cukup untuk melakukan transaksi ini.',
    'GG-REQ-BP-04': sNotInOpenPeriodID,
    'GG-REQ-BP-05': sAccountDisabledID,
    'GG-REQ-BP-06': sAccountVerificationRuleID,
    'GG-REQ-BP-09': 'Jumlah permintaan yang tidak valid',
    'GG-REQ-BP-10': 'ID Alasan Tidak Valid',
    'GG-REQ-BP-11': 'Nomor akun tidak valid',
    'GG-REQ-BP-12': sInvalidReferralID,
    'GG-REQ-BP-13': 'Deskripsi Alasan Tidak Valid',
    'GG-REQ-BP-14': 'Jumlah pascabayar tidak valid dari Ayoconnect',
    'GG-REQ-BP-15': 'Ups, ada kesalahan terjadi. Silakan coba lagi',
    'GG-REQ-BP-16': 'Penagihan belum tersedia',
    'GG-REQ-BP-17': 'Nomor HP/ ID Pelanggan salah. Silahkan cek kembali',
    'GG-REQ-BP-18': 'Nomor ini tidak pascabayar',
    'GG-REQ-BP-19': 'Transaksi tidak valid BP Catergory',
    'GG-REQ-BP-20': 'Transaksi telah gagal, coba lagi',
    'GG-REQ-BP-21': 'Voucher telah kedaluwarsa. Nantikan pembaruan rutin, oke!',
    'GG-REQ-BP-22': 'Meminta transaksi yang sudah dikonfirmasi',
    'GG-REQ-BP-23': 'Tagihan sudah lunas!',
    'GG-VAL-01':
      'Status Karyawan salah. Status yang diisi wajib aktif, tidak aktif, penarikan ditangguhkan, dan penarikan akrual ditangguhkan',
    'GG-VAL-02': 'Format jumlah minimal salah. Format wajib diiisi dalam angka',
    'GG-VAL-03': 'Format jumlah maksimal salah. Format wajib diisi dalam angka',
    'GG-VAL-04': 'Format tanggal gajian salah. Format wajib diisi dalam teks',
    'GG-VAL-05': 'Tipe akun wajib EWA, Poin, Emas, Penalangan Gaji, Uang Kantor, Uang Kas, dan Penalangan Gaji Offline',
    'GG-VAL-06': 'Format grup karyawan salah. Format wajib diisi dalam teks',
    'GG-VAL-07': 'Format pencarian salah. Format wajib diisi dalam teks',
    'GG-VAL-08': 'Format kode karyawan salah. Format wajib diisi dalam kumpulan teks',
    'GG-VAL-09': 'Format grup karyawan sekunder salah. Format wajib diisi dalam kumpulan teks',
    'GG-VAL-10': 'Format identitas karyawan salah. Format wajib diisi dalam angka',
    'GG-VAL-11': 'Format kategori penangguhan salah. Format wajib diisi dalam teks',
    'GG-VAL-12': 'Format alasan penangguhan salah. Format wajib diisi dalam teks',
    'GG-VAL-13': 'Format email salah',
    'GG-VAL-14': 'Format dari tanggal transaksi salah. Format wajib diisi dalam teks tanggal',
    'GG-VAL-15': 'Format ke tanggal transaksi salah. Format wajib diisi dalam teks tanggal',
    'GG-VAL-16': 'Nilai untuk status transaksi salah. Status transaksi wajib MENUNGGU, SUKSES, DITOLAK, GAGAL',
    'GG-VAL-17': 'Format identitas kategori transaksi salah. Format wajib diisi dalam kumpulan teks',
    'GG-VAL-18': 'Format dari jumlah transaksi salah. Format wajib diisi dalam angka',
    'GG-VAL-19': 'Format ke jumlah transaksi salah. Format wajib diisi dalam angka',
    'GG-VAL-20': 'Format identitas rekan salah. Format wajib diisi dalam angka',
    'GG-VAL-21': 'Format kode karyawan salah. Format wajib diisi dalam teks',
    'GG-VAL-22':
      'Format status pengajuan karyawan salah. Status pengajuan wajib menunggu, sukses, dalam proses, gagal, atau ditolak',
    'GG-VAL-23': 'Tipe kerah wajib "BIRU" atau "PUTIH"',
    'GG-VAL-24': 'Format identitas pengguna salah. Format wajib diisi dalam angka',
    'GG-VAL-25': 'Format nama depan salah. Format wajib diisi dalam teks',
    'GG-VAL-26': 'Format nama belakang salah. Format wajib diisi dalam teks',
    'GG-VAL-27': 'Format jenis pengajuan salah. Format wajib diisi dalam teks',
    'GG-VAL-28': 'Format tanggal lahir salah. Format wajib diisi dalam teks',
    'GG-VAL-29': 'Format nomor handphone salah. Format wajib diisi dalam teks',
    'GG-VAL-30': 'Format jenis kelamin salah. Format wajib diisi dalam teks',
    'GG-VAL-31': 'Format nomor KTP salah. Format wajib diisi dalam teks',
    'GG-VAL-32': 'Format tanggal bergabung salah. Format wajib diisi dalam teks',
    'GG-VAL-33': 'Format nama grup salah. Format wajib diisi dalam teks',
    'GG-VAL-34': 'Format nama departemen salah. Format wajib diisi dalam teks',
    'GG-VAL-35': 'Format nama penunjukkan salah. Format wajib diisi dalam teks',
    'GG-VAL-36':
      'Format identitas tipe gaji salah. Jenis gaji wajib BULANAN, HARIAN, MINGGUAN, UNIT_KERJA, PER JAM, DUA MINGGU SEKALI, BULANAN_TETAP, JENJANG WAKTU_TETAP, DUA BULAN_SEKALI',
    'GG-VAL-37': 'Format jumlah gaji salah. Format wajib diisi dalam angka',
    'GG-VAL-38': 'Format nama bank karyawan salah. Format wajib diisi dalam angka',
    'GG-VAL-39': 'Format nomor akun bank karyawan salah. Format wajib diisi dalam teks',
    'GG-VAL-40': 'Format nama penerima salah. Format wajib diisi dalam teks',
    'GG-VAL-41': 'Format nama file salah. Format wajib diisi dalam teks',
    'GG-VAL-42': 'Format nama karyawan salah. Format wajib diisi dalam teks',
    'GG-VAL-43':
      'Format permintaan karyawan diurutkan berdasarkan salah. Urutan wajib berdasarkan kode karyawan, nama karyawan, atau diupload pada',
    'GG-VAL-44': 'Format urutan permintaan karyawan salah. Pengurutan harus menaik atau menurun',
    'GG-GEN-14': 'Parameter nomor halaman tidak valid',
    'GG-GEN-15': 'Batas tidak boleh kosong',
    'GG-GEN-16': 'Pageno seharusnya tidak kosong',
    'GG-GEN-17': 'Token kedaluwarsa',
    'GG-INVOICE-02': 'Nama grup diperlukan',
    'GG-VAL-47': '',
    'GG-EMP-REQ-01':
      'Title : Perubahan Data Gagal Content : Tidak dapat melakukan perubahan data. Silahkan menghubungi Tim GajiGesa',
    'GG-EMP-REQ-02':
      'Gagal upload karena ada dokumen yang masih diverifikasi. Silahkan coba lagi setelah dokumen disetujui!',
    'GG-EMP-REQ-03': 'Tidak ada perubahan data',
    'GG-EMP-REQ-04': 'Perubahan status karyawan gagal. Silakan pilih dan masukan detail alasan terlebih dahulu!',
    'GG-EMP-REQ-05':
      'Title : Perubahan Data Gagal Content : Tidak dapat melakukan perubahan data. Silahkan menghubungi Tim GajiGesa',
    'GG-EMP-REQ-06': 'Gagal upload karena error sistem. Silahkan coba lagi',
    'GG-EMP-REQ-07': 'ID Karyawan tidak ditemukan',
    'GG-EMP-REQ-08': 'Karyawan tidak ditemukan',
    'GG-EMP-REQ-09': 'Nomor HP tidak valid',
    'GG-GEN-18': 'rekaman tidak ditemukan',
    'GG-KTP-06': 'Kode promo tidak valid',
    'GG-OTP-09': 'Tidak dapat mengakses dasbor pemberi kerja',
    'GG-REF-TKN-01': 'Refresh Token diperlukan',
    'GG-REF-TKN-02': 'Refresh Token kelelahan',
    'GG-REF-TKN-03': 'Refresh Token Tidak Ditemukan',
    'GG-REF-TKN-04': 'Refresh Token kedaluwarsa',
    'GG-EMP-07': 'Tidak dapat meningkatkan status karyawan',
    'GG-EMP-08': 'Karyawan tidak memiliki peran infuencer',
    'GG-EMPYER-06': 'Email majikan tidak ditemukan',
    'GG-EMPYER-07': 'Permintaan ringkasan transaksi tidak ditemukan',
    'GG-BENE-06': 'ID Penerima Penerima Tidak Valid',
    'GG-BENE-07': 'Nomor akun tidak valid',
    'GG-BENE-08': 'ID bank tidak valid',
    'GG-BENE-09': 'Nama penerima yang tidak valid',
    'GG-BENE-10': 'Nama panggilan penerima yang tidak valid',
    'GG-BENE-11': 'Format pin keamanan tidak valid',
    'GG-PHONE-05': 'ID Nomor Telepon Tidak Valid',
    'GG-PHONE-06': 'Nomor ponsel tidak valid',
    'GG-PHONE-07': 'Nama pemilik tidak valid',
    'GG-PHONE-08': 'Format pin keamanan tidak valid',
    'GG-PHONE-09': 'Phone Change token invalid', // general exception
    'GG-PHONE-10': 'Terlalu banyak permintaan kode verifikasi.\nHarap tunggu 10 menit untuk mencoba lagi.', // otp resend exhausted
    'GG-PHONE-11': 'Kode verifikasi tidak valid.\nSilahkan coba lagi atau kirim ulang kode', // otp payload validation
    'GG-PHONE-12': 'Terlalu banyak permintaan kode verifikasi.\nHarap tunggu 10 menit untuk mencoba lagi.', // otp not match exhausted
    'GG-PHONE-13': 'Kode verifikasi tidak valid.Silakan coba lagi. Upaya yang tersisa: {{attempts}}',
    'GG-VAL-52': 'Nomor telepon tidak tersedia', // Phone Change number not unique
    'GG-PRODUCT-07': 'ID kategori tidak valid',
    'GG-GOLD-04': 'ID transaksi tidak valid',
    'GG-RESEND-OTP-03': 'OTP Token kedaluwarsa',
    'GG-RESEND-OTP-04': 'OTP mengirim kembali kelelahan',
    'GG-REQ-WD-13': 'Deskripsi Alasan Tidak Valid',
    'GG-REQ-WD-14': 'Jumlah piutang di bawah 10k',
    'GG-PARTNER-LOGIN-01': 'x-auth-api-key tidak ditemukan',
    'GG-PARTNER-LOGIN-02': 'Majikan tidak ditemukan. Tidak valid x-auth-api-key atau kode kerja',
    'GG-BULK-SD-01': 'Gagal mengunggah file',
    'GG-ATD-01': 'Tidak ada catatan kehadiran yang tersedia untuk tanggal yang diberikan',
    'GG-ATD-02': 'Tanggal kehadiran harus dalam kisaran siklus pembayaran',
    'GG-ATD-03': 'Tidak ada catatan kehadiran untuk diperbarui',
    'GG-ATD-04': 'Hitung ulang keseimbangan gagal',
    'GG-VAL-45': 'Format tidak valid untuk tanggal kehadiran. Tanggal harus berupa string tanggal yang valid',
    'GG-VAL-46': 'Format tidak valid untuk nilai kehadiran. Itu harus "tidak ada" atau "hadir"',
    'GG-PR-CON-01': 'Tidak ada konfigurasi harga yang ditemukan',
    'GG-WHATSAPP-APP-OT-01': 'Status majikan tidak aktif',
    'GG-WHATSAPP-APP-OT-02': 'Nomor ponsel diperlukan',
    'GG-WHATSAPP-APP-OT-03': 'Jumlah diperlukan',
    'GG-SEC-PIN-01': 'Pin keamanan tidak valid',
    'GG-SEC-PIN-02': 'Mengunggah gagal',
    'GG-SEC-PIN-03': 'Registrasi gagal',
    'GG-SEC-PIN-04': 'Pin keamanan tidak terdaftar',
    'GG-SEC-PIN-05': 'Reset Pin Keamanan Diperlukan',
    'GG-SEC-PIN-06': 'Pin keamanan tidak valid',
    'GG-SEC-PIN-09': 'Upaya Pin Keamanan telah habis',
    'GG-SEC-PIN-10': 'Pin keamanan tidak valid',
    SERVER_PING_FAILED: 'Server ping gagal',
    REDIS_PING_FAILED: 'Tidak dapat terhubung ke redis',
    DB_PING_FAILED: 'Tidak dapat terhubung ke database',
    KAFKA_PING_FAILED: 'Tidak dapat terhubung ke kafka',
    NOT_ENOUGH_STORAGE: 'Penyimpanan disk habis. Ruang kosong kurang dari 20%',
    HEAP_MEMORY_EXCEEDED: 'Hump ​​memori melebihi 300MB',
    RSS_MEMORY_EXCEEDED: 'Memori RSS melebihi 300MB',
    'GG-DIS-01': 'Bank penerima tidak valid',
    'GG-DIS-02': 'Rekening bank tidak valid',
    'GG-DIS-03': 'Jumlah tidak valid',
    'GG-DIS-04': 'ID transaksi tidak valid',
    'GG-DIS-05': 'Penyedia pencairan tidak valid',
    'GG-DIS-06': 'Catatan tidak valid',
    'GG-DIS-07': 'URL webhook tidak valid',
    'GG-DIS-08': 'Transaksi sudah dicairkan',
    'GG-MSG-01': 'Saluran tidak valid. itu harus "sms", "whatsapp"',
    'GG-MSG-02': 'Konten harus menjadi string',
    'GG-MSG-03': 'Kirim ke penyedia tidak berhasil. Pemicu coba lagi',
    'GG-NOTIFY-01': 'Saluran tidak valid. itu harus menjadi "perselisihan"',
    'GG-NOTIFY-02': 'Konten harus menjadi string',
    'GG-NOTIFY-03': 'Kirim ke penyedia tidak berhasil. Pemicu coba lagi',
    'GG-NOTIFY-04': 'Webhook tidak valid',
    'GG-NOTIFY-05': 'Avatar Link tidak valid',
    'GG-NOTIFY-06': 'Nama pengirim tidak valid',
    'STATUS-01': 'Akun kamu sedang tidak aktif',
    'STATUS-02': 'Akun tidak diizinkan',
    'STATUS-03': 'Saldo tidak mencukupi',
    'STATUS-04': 'Kamu tidak dalam masa penarikan',
  },
  tnc: {
    headerText: termsAndConditionInd,
    tncHeader: termsAndConditionInd,
    tncLastUpdated: 'Terakhir diperbarui',
    subTitle01: '1. KETENTUAN UMUM',
    subTitle02: '2. PENGGUNAAN LAYANAN',
    subTitle03: '3. AKUN GajiGesa',
    subTitle04: '4. LAYANAN',
    subTitle05: '5. PEMBAYARAN',
    subTitle06: '6. KEBIJAKAN PRIVASI',
    subTitle07: '7. HAK KEPEMILIKAN DAN KEKAYAAN INTELEKTUAL',
    subTitle08: '8. PEMBATALAN AKUN',
    subTitle09: '9. LAYANAN DISEDIAKAN “APA ADANYA” DAN “SEBAGAIMANA TERSEDIA”',
    subTitle10: '10. PEMBATASAN TANGGUNG JAWAB DAN GANTI RUGI',
    subTitle11: '11. HUKUM DAN YURISDIKSI YANG BERLAKU',
    subTitle12: '12. KOMUNIKASI',
    subTitle13: '13. LAIN-LAIN',
    subTitle14: '14. PELAYANAN PELANGGAN',
    dataSubTitle1Data0Text:
      'I. Ketentuan Penggunaan ini menjabarkan ketentuan-ketentuan yang mengatur penggunaan layanan GajiGesa yang disediakan untuk Anda, berupa penjualan dan penyediaan suatu fitur, teknologi, produk, perangkat lunak, dan jasa, atau fungsi lain yang diberikan oleh produk atau jasa tersebut oleh GajiGesa, termasuk namun tidak terbatas pada:',
    dataSubTitle1List0: 'a. Aplikasi GajiGesa(“Aplikasi”);',
    dataSubTitle1List1: 'b. www.gajigesa.com dan app.gajigesa.com (“Situs”);',
    dataSubTitle1List2: 'c. Layanan Produk;',
    dataSubTitle1List3: 'd. Layanan untuk Perusahaan;',
    dataSubTitle1List4: 'e. Layanan untuk Karyawan;',
    dataSubTitle1List5:
      'f. Layanan, konten, pembaruan, fitur, perangkat lunak, dan produk lainnya serta rilis baru yang mungkin kami sediakan dari waktu ke waktu. \nSelanjutnya secara bersama-sama disebut sebagai “Layanan”.',
    dataSubTitle1Data1Text:
      'II. GajiGesa dapat mengubah Ketentuan Penggunaan dari waktu ke waktu, dan GajiGesa akan memberitahukan mengenai perubahan tersebut kepada Pengguna melalui email atau dengan memberikan pesan tertulis pada saat Pengguna menggunakan Layanan. Apabila Pengguna terus menggunakan Layanan setelah menerima pesan tersebut, maka Pengguna dianggap setuju untuk tunduk pada perubahan Ketentuan Penggunaan tersebut.',
    dataSubTitle1Data2Text:
      'III. Dari waktu ke waktu, GajiGesa dapat menambah, mengubah atau menghilangkan seluruh atau sebagian fitur atau fungsi dalam Layanan. Pengguna dapat melakukan instalasi atas versi terbaru untuk menerima perubahan-perubahan tersebut. GajiGesa juga dapat memutuskan untuk berhenti menyediakan seluruh atau sebagian dari Layanan pada setiap waktu, dan tidak ada satu ketentuan pun di dalam Ketentuan Penggunaan ini yang dapat dijadikan sebagai jaminan bahwa Aplikasi atau bagian dari Layanan manapun akan selalu tersedia, baik pada bentuk saat ini atau bentuk lainnya, dan/atau bahwa GajiGesa akan terus mendukung, menjaga, atau meneruskan untuk menawarkan Layanan dan/atau Aplikasi atau versi mana pun daripadanya.',
    dataSubTitle1Data3Text:
      'IV. GajiGesa dapat menawarkan fitur khusus yang memiliki syarat-syarat dan ketentuan-ketentuan tersendiri di samping Ketentuan Penggunaan ini (“Ketentuan Penggunaan Terpisah”). Dalam hal tersebut, ada Ketentuan Penggunaan Terpisah yang akan berlaku apabila terdapat ketidaksesuaian dengan Ketentuan Penggunaan ini.',
    tncDataDescription:
      'Syarat dan ketentuan penggunaan ini (“Ketentuan Penggunaan”) mengatur hubungan antara PT Easy Management Systems (“GajiGesa”, “kami”, atau “milik kami”) dengan penggunanya (“Pengguna”, “Pelanggan”, “Anda”) sehubungan dengan Layanan (sebagaimana didefinisikan di bawah ini). \nMohon tinjau Ketentuan Penggunaan ini secara saksama. Dengan melaniutkan, Pengguna menyatakan bahwa Pengguna telah membaca, memahami, menerima, menyetujui, dan menyatakan bersedia terikat terhadap Ketentuan Penggunaan ini.',
    dataSubTitle2FooterDescription:
      'Jika Anda melanggar salah satu dari kondisi di atas atau lebih, ketentuan penggunaan ini dan hak Anda untuk menggunakan layanan dapat berakhir secara sepihak oleh Gajigesa dan Gajigesa dapat mengambil tindakan lain yang diizinkan oleh hukum yang berlaku.',
    dataSubTitle2Data0Text:
      'I. Dengan tunduk pada ketentuan dalam ketentuan penggunaan ini, Gajigesa dengan ini memberi Anda hak dan lisensi yang terbatas, dapat ditarik, tidak eksklusif, tidak dapat ditransfer (kecuali secara eksplisit disebutkan dalam ketentuan penggunaan ini) untuk menginstal dan menggunakan layanan.',
    dataSubTitle2Data1Text:
      'II. Anda setuju bahwa Layanan hanya dapat digunakan untuk tujuan yang diizinkan berdasarkan (a) ketentuan penggunaan ini; dan (b) setiap hukum, peraturan, praktik, atau pedoman yang berlaku yang umumnya diterima dalam yurisdiksi terkait.',
    dataSubTitle2Data2Text:
      'III. Anda tidak diizinkan (dan Anda tidak diizinkan mengizinkan pihak lain) untuk menyalin, mengubah, membuat turunan dari, insinyur terbalik (insinyur terbalik), parse, atau mencoba untuk mengambil layanan sumber (kode sumber), konten (sebagai kontenDefinedBelow), atau bagian mana pun dari itu.',
    dataSubTitle2Data3Text:
      'IV. Anda setuju untuk menggunakan layanan dengan cara yang wajar. Jika Gajigesa mengasumsikan bahwa Anda menggunakan layanan secara tidak wajar atau dapat menyebabkan penurunan kinerja layanan untuk pelanggan atau pengguna lain, maka Gajigesa dapat memberlakukan pembatasan pada penggunaan layanan Anda. Jika mungkin, Gajigesa akan memberikan aPemberitahuan 24 (dua puluh empat) jam sebelumnya dan meminta Anda untuk mengurangi penggunaan sebelum dikenakan batasan apa pun.',
    dataSubTitle2Data4Text:
      'V. Anda bertanggung jawab penuh atas penggunaan layanan dan setuju untuk tidak meminta pertanggungjawaban dari Gajigesa atas semua tuntutan, kerugian, dan/atau kerugian sehubungan dengan penggunaan layanan.',
    dataSubTitle2Data5Text:
      'VI. Aplikasi dan layanan hanya dapat digunakan pada perangkat yang kompatibel dan sesuai. Anda bertanggung jawab untuk memeriksa dan memastikan bahwa Anda telah mengunduh aplikasi dan layanan pada perangkat yang kompatibel dan sesuai. Gajigesa tidak bertanggung jawab jika Anda tidak memiliki perangkat yang kompatibel dan sesuai.',
    dataSubTitle2Data6Text: 'VII.Anda dilarang untuk:',

    dataSubTitle2Data6List0:
      'a. Gunakan layanan dengan cara yang melanggar hukum, atau untuk tujuan yang melanggar hukum atau penipuan, atau dengan cara apa pun yang tidak konsisten dengan ketentuan penggunaan;',
    dataSubTitle2Data6List1:
      'b. Melanggar hak kekayaan intelektual Gajigesa atau pihak ketiga sehubungan dengan penggunaan layanan;',
    dataSubTitle2Data6List2:
      'c. Kirim materi apa pun yang memfitnah, menghina, atau kontroversial lainnya sehubungan dengan penggunaan layanan Anda;',
    dataSubTitle2Data6List3:
      'd. Gunakan layanan dengan cara yang dapat merusak, melumpuhkan, membebani, membuat cacat, atau melemahkan sistem atau keamanan Gajigesa atau campur tangan pada pengguna lain;',
    dataSubTitle2Data6List4:
      'e. Mengumpulkan atau mengambil informasi apa pun dari fasilitas atau sistem Gajigesa, atau mencoba untuk menguraikan (menguraikan) transmisi untuk atau dari server yang menggunakan layanan;',
    dataSubTitle2Data6List5:
      'f. Akses atau mendaftar melalui login pengguna menggunakan bot atau metode otomatis lainnya;',
    dataSubTitle2Data6List6:
      'g. Mereproduksi, menduplikasi, menyalin, mendekonstruksi, menjual, memperdagangkan, atau menjual kembali layanan yang dimaksud;',
    dataSubTitle2Data6List7:
      'h. Menyalin, Mengubah, Membuat Derivatif Dari, Melakukan Insinyur Terbalik (Reversed Engineering), Mengurai, atau mencoba mengambil Layanan Sumber Kode (Kode Sumber), Konten (sebagaimana didefinisikan di bawah), atau bagian mana pun dari itu atau memungkinkan pihak lain menob dalamnya.',
    dataSubTitle3Data0Text:
      'I. Untuk dapat menggunakan Layanan, Anda harus terlebih dahulu terdaftar sebagai pengguna GajiGesa. Untuk menjadi pengguna terdaftar di GajiGesa, Perusahaan tempat Anda bekerja akan mengirimkan data yang dibutuhkan oleh GajiGesa untuk mendaftarkan Anda sebagai pengguna aktif GajiGesa. Anda tidak diperkenankan untuk menggunakan akun pihak lain tanpa izin. Anda wajib untuk menyediakan informasi akun yang benar, akurat, terbaru, dan lengkap, dan terus menjaga agar informasi akun tersebut tetap benar, akurat, terbaru, dan lengkap.',
    dataSubTitle3Data1Text:
      'II. Anda adalah satu-satunya orang yang berwenang mengakses dan menggunakan akun Anda. Anda diharuskan untuk menjaga keamanan dan kerahasiaan akun dan kode sandi Anda. Anda dilarang memperkenankan atau memperbolehkan pihak lain untuk mengakses atau menggunakan akun dan kode sandi Anda. Anda dapat berbagi akun dan kode sandi kepada Perwakilan yang Anda percaya, namun Anda adalah penanggung jawab tunggal untuk penggunaan Layanan yang menggunakan akun dan kode sandi Anda. Anda membebaskan dan akan mengganti rugi kami dari segala bentuk tanggung jawab, tindakan, gugatan, permintaan, biaya, tuntutan, dan pengeluaran di mana dapat dikeluarkan atau diderita oleh kami sebagai akibat dari penyalahgunaan Layanan menggunakan akun dan kode sandi Anda.',
    dataSubTitle3Data2Text:
      'III. Anda harus segera memberitahu GajiGesa apabila terdapat pelanggaran keamanan atau penggunaan akun secara tidak sah. Anda juga diharuskan untuk segera melakukan perubahan atas kode sandi Anda. Kami berhak untuk menghentikan akun Anda atau memblokir akses Anda terhadap Layanan apabila Anda melanggar ketentuan dalam menjaga keamanan kata sandi Anda.',
    dataSubTitle4Data0Text:
      'I. GajiGesa memberikan Anda masa Layanan untuk suatu jangka waktu tertentu sesuai kesepakatan PT Easy Management Systems dengan Perusahaan Anda bekerja. Seluruh data yang Anda simpan sehubungan dengan Layanan akan tersimpan dan menjadi hak dari PT Easy Management Systems.',
    dataSubTitle4Data1Text:
      'II. Periode berlangganan Layanan Anda akan berakhir pada akhir periode berlangganan sebagaimana kesepakatan dengan Perusahaan tempat Anda bekerja.. Apabila Anda memutuskan untuk tidak lagi berlangganan Layanan sampai akhir periode berlangganan, maka Anda tidak dapat melanjutkan penggunaan Layanan. Seluruh data yang Anda simpan sehubungan dengan Layanan akan tersimpan dan dapat diakses kembali setelah Anda kembali berlangganan Layanan. Kami berhak menghapus data Anda apabila telah melewati 30 (tiga puluh) hari dari akhir periode berlangganan Layanan.',
    dataSubTitle4Data2Text:
      'III. Layanan dapat mencakup fitur yang menghubungkan Anda ke salah satu mitra kami. Apabila Anda memilih menggunakan Layanan ini, Anda memahami dan setuju untuk ikut tunduk dan terikat pada syarat dan ketentuan yang berlaku dari mitra, yang melengkapi Ketentuan Penggunaan ini.',
    dataSubTitle5Data0Text:
      'I. Pengguna mengakui bahwa apabila ada, Pengguna setuju terhadap biaya, harga, dan siklus tagihan yang terkait sebagaimana (i) tertera pada Aplikasi dan/atau Situs pada saat penggunaan Layanan, dan (ii) diubah dari waktu ke waktu sesuai dengan Ketentuan Penggunaan sesuai kesepakatan dengan Perusahaan tempat Anda bekerja saat ini.',
    dataSubTitle5Data1Text:
      'II. GajiGesa dapat sewaktu-waktu, atas kebijaksanaannya sendiri, mengubah ketentuan pembebanan biaya, dan harga, yang berlaku terhadap jenis layanan apapun, termasuk mengubah biaya dan harga Layanan, dengan memberikan pemberitahuan kepada Anda. Apabila Anda tidak setuju dengan perubahan tersebut, Anda dapat berhenti berlangganan Layanan.',
    dataSubTitle5Data2Text:
      'III. Jika pembayaran dan informasi pendaftaran Anda tidak tepat, tidak terkini, atau tidak lengkap, kemudian Anda tidak memberitahukan kami secepatnya setelah terjadinya perubahan informasi, kami dapat menolak menyediakan Layanan pada Anda dalam bentuk apapun.',
    dataSubTitle5Data3Text:
      'IV. Semua pembayaran akan dibuat tanpa adanya pemotongan, khususnya pemotongan pajak atau jenis pajak lainnya, oleh karena itu Andalah yang akan sepenuhnya bertanggung jawab.',
    dataSubTitle6Data0Text:
      'I. Anda dengan ini memberikan izin yang bebas royalti, tidak dapat dicabut kembali, tanpa batas waktu, dapat disublisensikan dan berlaku di seluruh dunia untuk menggunakan (termasuk untuk tujuan komersial) informasi dan/atau data yang dikumpulkan oleh GajiGesa melalui penggunaan Anda atas Layanan, dengan ketentuan bahwa GajiGesa akan menggabungkan (dengan data dari pengguna lainnya) atau menjadikan informasi atau data tersebut menjadi anonim sebelum menggunakannya. Selain dari hak ini, GajiGesa tidak menuntut hak kekayaan intelektual lainnya sehubungan dengan informasi atau konten yang diberikan oleh Anda pada Layanan.',
    dataSubTitle6Data1Text:
      'II. Penggunaan dan/atau akses ke Layanan juga akan tunduk pada segala aturan yang dituangkan dalam kebijakan privasi GajiGesa yang dimuat pada Aplikasi dan/atau Situs (“Kebijakan Privasi”). Dengan menyetujui Ketentuan Penggunaan ini, kami mengasumsikan bahwa Anda telah membaca dan menyetujui isi dari Kebijakan Privasi. Kebijakan Privasi dan Ketentuan Penggunaan ini merupakan suatu kesatuan dokumen yang perlu disetujui oleh Pengguna sebelum dapat mendaftarkan diri dan menggunakan Layanan.',
    dataSubTitle7Data0Text:
      'I. Anda mengakui bahwa seluruh informasi dan data, seperti halaman situs, layanan situs, file data, teks, program komputer, video, audio, gambar, foto, animasi atau material lainnya (secara bersama-sama “Konten”) yang dapat Anda akses sebagai bagian dari, atau melalui penggunaan Anda atas Layanan itu sendiri adalah sepenuhnya milik GajiGesa. Anda mengakui dan menyetujui bahwa GajiGesa memiliki seluruh hak, kepemilikan, dan kepentingan yang sah secara hukum di dalam dan pada Layanan dan Konten, termasuk setiap hak kekayaan intelektual yang ada dalam Layanan dan Konten (baik hak tersebut terdaftar atau tidak, dan di negara mana pun di mana hak-hak tersebut timbul). Anda setuju untuk tidak menantang atau melakukan hal apa pun yang tidak konsisten dengan kepemilikan tersebut.',
    dataSubTitle7Data1Text:
      'II. Hak penggunaan yang diberikan kepada Anda oleh Ketentuan Penggunaan ini bukanlah suatu pengalihan atau penjualan hak kepemilikan GajiGesa atas Layanan, Konten, sistem, atau bagiannya. Selain dari hak penggunaan yang disebutkan pada Bagian 2.1 dari Ketentuan Penggunaan ini, GajiGesa tetap menguasai seluruh hak, hak milik, dan kepentingan (termasuk seluruh hak kekayaan intelektual) atas Layanan ataupun salinannya. Upaya apa pun untuk menggunakan Layanan di luar yang diperbolehkan oleh lisensi ini akan mengakhiri berlakunya lisensi yang dimaksud secara otomatis.',
    dataSubTitle7Data2Text:
      'III. Tidak ada satu ketentuan pun di dalam Ketentuan Penggunaan ini yang memberikan kepada Anda hak untuk menggunakan setiap merek dagang, nama dagang, merek jasa, logo, atau nama domain GajiGesa dengan cara apa pun yang dimaksudkan atau dapat menimbulkan penyesatan konsumen sehubungan dengan kepemilikan atau pengguna yang berwenang dari setiap merek dagang, nama dagang, merek jasa, logo, atau nama domain tersebut. Anda tidak diperbolehkan untuk memublikasikan atau menggunakan setiap merek dagang, nama dagang, merek jasa, atau logo GajiGesa, kecuali dengan persetujuan tertulis sebelumnya dari GajiGesa.',
    dataSubTitle8Data0Text:
      'I. GajiGesa dapat membatalkan atau menghentikan akun Pengguna pada setiap waktu berdasarkan kebijakan dari GajiGesa sendiri.',
    dataSubTitle8Data1Text:
      'II. Pengguna memiliki hak untuk membatalkan akunnya pada Layanan pada setiap waktu, dengan cara mengikuti petunjuk yang ada pada akun Pengguna melalui Aplikasi atau Situs, atau dengan menghubungi atau mengirimkan email kepada bagian pelayanan Pelanggan (Customer Support) dari GajiGesa. Dalam hal Pengguna membatalkan akun miliknya sebelum berakhirnya jangka waktu berlangganan Layanan (baik secara bulanan, tahunan, atau periode tertentu), maka pembatalan tersebut akan berlaku pada saat itu juga.',
    dataSubTitle8Data2Text:
      'III. Apabila akun Pengguna dibatalkan, maka: (a) akun tersebut dapat dinonaktifkan atau dihapus; (b) semua hak Pengguna dalam Ketentuan Penggunaan akan langsung berakhir; dan (c) tanpa mengesampingkan ketentuan perundang-undangan yang berlaku, semua data dan konten Pengguna dapat langsung dihapus dari sistem GajiGesa. GajiGesa tidak dapat dimintakan pertanggungjawaban atas segala kerugian atau kerusakan yang terjadi sebagai akibat dari pembatalan akun Pengguna, dan merupakan kewajiban Pengguna untuk memastikan bahwa konten dan data mana pun yang diperlukannya, dipindahkan atau diduplikasi sebelum pembatalan akun dilakukan.',
    dataSubTitle9Data0Text:
      'I. Layanan disediakan dengan basis “apa adanya” (as is) dan “sebagaimana tersedia” (as available), dan penggunaan Anda atasnya adalah risiko dari Anda sendiri. GajiGesa akan dengan segera (dalam jam kerja normal) menangani segala permasalahan teknis yang timbul sehubungan dengan Layanan.',
    dataSubTitle9Data1Text: 'II. GajiGesa tidak menyatakan maupun menjamin bahwa:',
    dataSubTitle9Data2Text:
      'III. Anda mengakui dan setuju bahwa GajiGesa dapat menggunakan pemasok dari pihak ketiga untuk menyediakan perangkat lunak, jaringan (networking), konektivitas (jaringan), penyimpanan (storage), dan teknologi lainnya untuk menyediakan Layanan. Segala tindakan dan perbuatan dari pemasok tersebut mungkin dapat di luar kendali GajiGesa dan GajiGesa tidak akan menerima tanggung jawab apa pun atas adanya kerugian atau kerusakan yang diderita oleh Anda akibat dari tindakan atau perbuatan pemasok tersebut. Untuk dan atas nama dirinya sendiri dan pemasok dari pihak ketiga tersebut, GajiGesa mengecualikan segala jaminan yang mungkin tersirat atau sebaliknya berlaku berdasarkan undang-undang dan peraturan yang berlaku lainnya, sejauh mana diperbolehkan oleh hukum yang berlaku',
    dataSubTitle9Data3Text:
      'IV. Untuk kemudahan Anda, GajiGesa dapat mencantumkan tautan ke situs atau konten lain dalam internet yang dimiliki atau dioperasikan oleh pihak ketiga. Situs atau konten yang ditautkan tidak berada di bawah kendali GajiGesa dan GajiGesa tidak bertanggung jawab atas segala kekeliruan, kelalaian, keterlambatan, penghinaan, fitnah, kebohongan, pornografi, konten asusila, ketidaktepatan, atau material lain yang dimuat dalam situs atau konten tersebut, atau konsekuensi dari mengakses situs atau aplikasi terkait manapun, serta akurasi informasi, konten, produk, atau layanan yang ditawarkan oleh, atau informasi yang terdapat dalam situs yang bertautan dengan atau dari Situs atau Aplikasi. Mengingat bahwa situs atau aplikasi pihak ketiga mungkin memiliki kebijakan privasi dan/atau standar keamanan berbeda yang berlaku di dalamnya, kami menganjurkan Anda untuk memeriksa kebijakan privasi dan syarat dan ketentuan situs dan aplikasi tersebut sebelum memberikan informasi pribadi apa pun.',
    dataSubTitle9Data4Text:
      'V. Segala tautan ke situs atau konten lain mana pun bukan merupakan bentuk dukungan atau verifikasi atas situs atau konten tersebut dan Anda setuju bahwa risiko dari diaksesnya situs atau konten yang ditautkan tersebut sepenuhnya ada pada Anda.',
    dataSubTitle9Data1List0: 'a. Layanan akan memenuhi kebutuhan spesifik Anda;',
    dataSubTitle9Data1List1:
      'b. Layanan akan selalu tepat waktu, aman, dan bebas dari kesalahan (error free), interupsi, pencurian atau kehancuran;',
    dataSubTitle9Data1List2: 'c. Layanan akan selalu akurat dan dapat diandalkan;',
    dataSubTitle9Data1List3:
      'd. Kualitas dari produk, jasa, informasi, atau material apa pun yang dibeli atau diperoleh oleh Anda melalui Layanan akan memenuhi ekspektasi Anda;',
    dataSubTitle9Data1List4: 'e. Setiap kesalahan (error) pada Layanan akan selalu diperbaiki;',
    dataSubTitle9Data1List5:
      'f. Layanan akan kompatibel dengan setiap perangkat seluler, komputer, sistem operasi, atau perangkat lainnya;',
    dataSubTitle9Data1List6:
      'g. Layanan yang disediakan sepenuhnya aman dan bebas dari bugs, virus, trojans, dan komponen perangkat lunak berbahaya lainnya. Adalah kewajiban dan tanggung jawab Anda untuk memastikan bahwa perangkat seluler dan sistem komputer Anda memiliki anti-virus yang dapat diandalkan dan secara rutin diperbaharui, dan bahwa Anda melakukan penyimpanan cadangan (back-up) secara rutin atas perangkat yang digunakan untuk menggunakan Layanan.',
    dataSubTitle10Data0Text:
      'I. Sejauh mana diperbolehkan oleh hukum yang berlaku, GajiGesa tidak akan bertanggung jawab atas segala kerusakan dan/atau kerugian langsung, tidak langsung, insidental, khusus, konsekuensial, atau tipikal (contoh kerusakan) yang mungkin ditimbulkan atau diderita oleh Anda, termasuk namun tidak terbatas pada kehilangan keuntungan, usaha, niat baik, reputasi, data atau kerugian tidak berwujud lainnya.',
    dataSubTitle10Data1Text:
      'II. GajiGesa juga tidak akan bertanggung jawab atas setiap kerusakan atau kerugian yang timbul sebagai akibat dari (a) penggunaan atau ketidakmampuan untuk menggunakan Layanan (secara keseluruhan atau sebagian) atau kerusakan teknis apa pun; (b) biaya atas pengadaan barang atau jasa pengganti; (c) akses yang terlarang untuk, atau perubahan atas, komunikasi atau data Pengguna; (d) pernyataan atau tindakan pihak ketiga manapun sehubungan dengan Layanan; (e) pengandalan Anda terhadap kelengkapan atau akurasi Layanan atau bagian manapun daripadanya, termasuk namun tidak terbatas pada, setiap kesalahan matematika atau nomor numerik yang terkait dengan transaksi keuangan apapun yang diberikan dalam Layanan; (f) setiap perubahan yang mungkin dilakukan GajiGesa atas Layanan, atau setiap penghentian permanen maupun sementara dari Layanan; (g) penghapusan, korupsi, atau kegagalan untuk menyimpan setiap konten dan data komunikasi lainnya yang dipelihara atau transmisikan oleh atau melalui penggunaan Layanan oleh Anda; (h) kegagalan Anda untuk memberikan informasi akun yang akurat kepada GajiGesa; atau (i) segala masalah lainnya sehubungan dengan Layanan.',
    dataSubTitle10Data2Text:
      'III. Dalam kasus mana pun, tanggung jawab maksimal dari GajiGesa sehubungan dengan Ketentuan Penggunaan atau penggunaan Layanan oleh Pengguna adalah terbatas pada jumlah biaya penggunaan Layanan yang telah dibayarkan oleh Pengguna kepada GajiGesa selama 12 (dua belas) bulan terakhir sebelum terjadinya klaim atau tuntutan yang mendasarinya.',
    dataSubTitle10Data3Text:
      'IV. Pembatasan tanggung jawab GajiGesa kepada Anda sebagaimana diatur dalam Bagian ini akan berlaku bahkan jika GajiGesa telah diberikan saran atas adanya kemungkinan kerusakan atau kerugian tersebut.',
    dataSubTitle10Data4Text:
      'V. Anda setuju untuk memberikan ganti rugi kepada dan membebaskan GajiGesa dan afiliasi, pejabat, agen, karyawan dan mitranya dari segala tuntutan atau klaim, termasuk biaya hukum yang wajar, dari pihak ketiga mana pun yang timbul dari dan/atau sehubungan dengan penggunaan Layanan oleh Anda yang bertentangan dengan Ketentuan Penggunaan ini atau pemberian data dan informasi yang tidak akurat atau tidak lengkap kepada GajiGesa sehubungan dengan penggunaan Layanan oleh Anda.',
    dataSubTitle11FooterText:
      'Anda setuju bahwa: (i) Layanan akan diberikan dari Indonesia; (Ii) hukum Indonesia berlaku untuk Ketentuan Penggunaan ini, termasuk setiap permasalahan kontraktual atau non-kontraktual atau perselisihan yang timbul dari atau sehubungan dengan Ketentuan Penggunaan ini, akses dan penggunaan Layanan oleh Anda, dan hubungan antara GajiGesa dengan Anda; dan (iii) setiap konflik yang timbul dari atau sehubungan dengan Layanan atau konflik antara GajiGesa dengan Anda sehubungan dengan Layanan akan diajukan dan diselesaikan pertama-tama melalui musyawarah untuk mufakat dalam waktu 30 (tiga puluh) hari terhitung sejak diterimanya pemberitahuan timbulnya konflik. Apabila setelah jangka waktu dimaksud tidak tercapai kesepakatan, maka penyelesaian konflik akan diselesaikan oleh Badan Arbitrase Nasional Indonesia (BANI). Dengan tidak mengesampingkan hal tersebut di atas, Anda setuju bahwa kami dapat meminta putusan provisi, putusan sela atau protective relief di hadapan pengadilan yang kompeten di wilayah yurisdiksi mana pun.',
    dataSubTitle11Data0Text:
      'I. Anda dengan ini menyatakan dan menjamin kepada GajiGesa bahwa Anda telah membaca, memahami, dan menyetujui semua ketentuan dalam Ketentuan Penggunaan ini, serta Anda setuju untuk terikat olehnya dan mematuhi segala ketentuan yang diatur di dalamnya.',
    dataSubTitle11Data1Text:
      'II. Ketentuan Penggunaan ini diatur dan ditafsirkan sesuai dengan hukum yang berlaku di Republik Indonesia. Setiap sengketa yang timbul sehubungan dengan Ketentuan Penggunaan ini akan diselesaikan melalui musyawarah untuk mencapai mufakat. Apabila musyawarah tidak menghasilkan kesepakatan, maka sengketa tersebut akan dirujuk ke Pengadilan Negeri setempat yang berwenang di wilayah hukum Republik Indonesia.',
    dataSubTitle11Data2Text:
      'III. Ketentuan Penggunaan ini berlaku dan mengikat sejak Anda mendaftarkan diri dan mulai menggunakan Layanan GajiGesa. Ketentuan Penggunaan ini akan tetap berlaku selama Anda menggunakan Layanan dan akan terus berlaku setelah berakhirnya Layanan, baik oleh Anda maupun oleh GajiGesa.',
    dataSubTitle12FooterText:
      'Anda setuju untuk mengizinkan kami menghubungi Anda melalui email, telepon, pos, unggahan (posting) atau dokumen yang dapat diunduh (atau kecuali dinyatakan lain oleh Anda) dan menyampaikan iklan, material pemasaran, informasi baru, surat kabar, pemberitahuan, dan informasi lain apapun kepada Anda melalui alamat kontak yang Anda sampaikan kepada kami pada saat pendaftaran dan/atau alamat kontak lain dari waktu ke waktu.',
    dataSubTitle13Data0Text:
      'I. Keseluruhan. Ketentuan Penggunaan ini adalah keseluruhan perjanjian antara Anda dan GajiGesa dan menggantikan seluruh pemahaman, komunikasi dan persetujuan sebelumnya, baik tertulis maupun lisan, terkait pokok isi dari Ketentuan Penggunaan ini. Ketentuan Penggunaan ini harus dibaca bersama dan berlaku secara bersama dengan Kebijakan Privasi, Ketentuan Penggunaan Terpisah dan/atau syarat dan ketentuan lain yang berlaku untuk Layanan yang Anda pilih, termasuk dari pihak ketiga atau mitra (apabila ada).',
    dataSubTitle13Data1Text:
      'II. Keterpisahan. Apabila putusan pengadilan yang berkekuatan hukum tetap memutuskan bahwa terdapat bagian dari Ketentuan Penggunaan ini yang tidak berlaku, bagian tersebut akan dihilangkan tanpa mempengaruhi bagian lain dari Ketentuan Penggunaan ini dan ketentuan yang lain akan tetap sah dan dapat diberlakukan.',
    dataSubTitle13Data2Text:
      'III. Pengalihan. Anda tidak dapat mengalihkan hak atau kewajiban Anda berdasarkan Ketentuan Penggunaan ini kepada siapa pun (kecuali disebutkan secara eksplisit dalam Ketentuan Penggunaan ini) tanpa persetujuan tertulis sebelumnya dari GajiGesa. Namun, GajiGesa dapat mengalihkan hak dan kewajibannya berdasarkan Ketentuan Penggunaan ini tanpa persetujuan Anda kepada (a) afiliasi nya, (b) perusahaan lain melalui penjualan aset oleh GajiGesa, atau (c) penerus melalui proses peleburan, penggabungan, akuisisi, reorganisasi atau tindakan hukum lain. Pengalihan selain menurut ketentuan Bagian ini dianggap tidak berlaku.',
    dataSubTitle14Data0Text:
      'I. Anda dapat menyampaikan pertanyaan dan keluhan terkait Layanan ke bagian pelayanan pelanggan (Customer Support) kami di info@gajigesa.com.',
    dataSubTitle14Data1Text:
      'II. GajiGesa akan melakukan verifikasi data Pengguna dengan berpedoman pada data Pengguna yang tersimpan pada sistem GajiGesa. Kami berhak melakukan penolakan untuk memproses pertanyaan dan keluhan yang Anda ajukan terkait Layanan dalam hal data Anda yang diverifikasi tidak sesuai dengan data Pengguna yang tersimpan pada sistem kami.',
  },
  common: {
    noInternetConnection: 'Internet bermasalah, cek kembali koneksi internetmu dan refresh halaman',
    Language: 'id',
    continue: 'Lanjutkan',
    later: 'Nanti Dulu',
    allow: 'Izinkan',
    Layinnya: 'Lainnya',
    oopsError: 'Ups, ada kesalahan!',
    oopsErrorDescription: 'Tenang, sedang kami cek untuk kamu. Silakan tutup dulu dan buka kembali GajiGesanya',
    oopsRetryApp: 'Mulai Ulang Aplikasi',
    oopsRetryWeb: 'Refresh Halaman',
    cancelled: 'Batalkan',
    confirmed: 'Konfirmasi',
    ok: 'Oke',
    loader: 'Mohon menunggu',
    help: 'Bantuan',
    lockPeriod: lockPeriodText,
    suspendedError: 'Tidak bisa bertransaksi, silahkan hubungi HR untuk mengecek status akun!',
    emptyString: '',
    homeTab: 'Beranda',
    transactionTab: 'Transaksi',
    financialTab: 'Finansial',
    bpTab: 'Pembayaran',
    profileTab: 'Profil',
    lockPeriodModalText:
      'Kamu sedang tidak dalam masa transaksi. Silakan coba gunakan akun Poin, Uang Kas atau Uang Kantor apabila tersedia.',
    suspendedModalText: 'Mohon maaf, tapi kamu belum bisa bertransaksi. Silakan hubungi HR untuk cek status akun!',
    storageTitle: 'Ruang Penyimpanan Hampir Penuh',
    storageContent:
      'Ruang penyimpanan ponsel kamu hampir penuh. Silahkan menghapus file atau aplikasi yang jarang atau tidak dipakai lagi dan coba kembali',
  },
  codepush: {
    isUpdating: 'Sedang Mengupdate…',
    dontMissText: 'Jangan lewatkan versi aplikasi terbaru!',
    updateAppText: 'Update aplikasi GajiGesa untuk mendapatkan fitur terkini dan',
    wait: 'Harap Tunggu...',
    whileUpdating:
      'Jangan tutup aplikasi Anda saat memperbarui, aplikasi akan dimulai ulang secara otomatis setelah pembaruan selesai',
    updateBtnText: 'Update Sekarang',
    laterBtnTxt: 'Nanti Saja',
    update: 'Perbarui',
  },
  referral: {
    invite: 'Ajak Teman',
    referralHeader: 'Kode Referral',
    inputPlaceHolder: 'Masukkan kode di sini',
    verifyBtn: 'PAKAI',
    validReferral: 'VALID',
    toolTip: 'Masukkan kode referral atau kode promo dan dapatkan GajiGesa Poin setelah selesai bertransaksi',
    referralPageTitle: 'Hadiah Poin',
    txtInviteFriends: 'Ajak teman transaksi, BONUS Poin!',
    txtCollectPrize: 'Kumpulkan hadiah sampai dengan',
    txtAchievePrize: 'Cara raih hadiah referral',
    txtCollectedText: 'Yang sudah terkumpul :',
    txtGGReferralCode: 'Kode Referral GajiGesa',
    txtYou: 'Kamu',
    txtShareCode: 'Bagikan Kode',
    txtYourFriend: 'Temanmu',
    txtUseCode: 'Gunakan Kode',
    txtTransaction: 'Transaksi',
    txtDoYou: 'Buat Kamu',
    txtSuccess: 'yang berhasil mengajak teman kerja pakai GajiGesa',
    txtForFriends: 'Buat Temanmu',
    txtFirstTx: 'setelah berhasil melakukan transaksi pertamanya',
    txtReferralTnCHeader: 'Syarat & Ketentuan Program Referral',
    codeCopiedSuccessfully: 'Kode berhasil tersalin.',
    txtGift: 'Dptkan hadiah ', // todo: remove
    txtPoinShare: 'Poin dr GajiGesa. Masukkan kode ini di app saat trx pertama:', // todo: remove
    txtDownloadApp: 'Download skrg', // todo: remove
    txtTnCPoins: '\n\n*S&K Berlaku',
    referralError: 'Ada terjadi keselahan', // todo: remove
    txtPoin: ' Poin',
    copyButtonText: 'Salin Kode',
    shareButtonText: 'Bagikan',
    appliedReferral1: 'Kode referral berhasil dipakai.',
    appliedReferral2: 'GajiGesa Poin',
    appliedReferral3: 'akan ditambahkan setelah transaksi ini sukses',
    textCopiedToClipboardPart1:
      'Eh, kamu tau nggak kalau GajiGesa, partner perusahaan kita, lagi bagi-bagi BONUS spesial? Yap, abis tarik gaji pertama kali pakai kode referral ini: ',
    textCopiedToClipboardPart2:
      ' bakal langsung dapet 25.000 GG Poin!\n' +
      '\n' +
      'Menarik, kan? Yuk, langsung download appnya di sini biar kita bisa untung bareng: ',
    validCodeText: 'Hore! Kode promo sudah bisa digunakan',
    textPoinNote:
      'Sekarang Poin bisa Anda tarik ke bank terdaftar dan digunakan untuk top up Ovo, GoPay, dan e-money lainnya!',
    floatingText1: 'Mau BONUS Poin s/d',
    floatingText2: '2.5 Juta?',
    floatingText3: 'Bagikan Kode Sekarang!',
  },
  login: {
    welcome: 'Selamat Datang!',
    phoneNumber: 'Nomor HP',
    whatsapp: 'Whatsapp',
    sms: 'SMS',
    noAccount: 'Belum Punya Akun? ',
    signUpNow: 'Daftar Sekarang',
    help: 'Butuh Bantuan Kami?',
    agreeToContinue: 'Dengan melanjutkan, Anda menyetujui',
    tnc: 'Syarat & Ketentuan Lainnya',
    invalidNumber: 'Nomor handphone tidak valid',
    loginHelpText:
      'Tarik gajimu lebih awal, bayar tagihan, top-up pulsa atau e-wallet dan tukar hadiah poin agar lebih hemat',
    tncText: 'Dengan melanjutkan, Anda menyetujui ',
    tncHyperLink: 'Syarat & Ketentuan Lainnya',
    GG_429_TITLE: 'Harap Tunggu Sebentar',
    GG_429_DESCRIPTION: 'Kami menerima terlalu banyak permintaan OTP ke nomor ponsel ini. Coba lagi setelah 1 jam ya!',
    GG_429_BUTTON_TEXT: 'Okie',
    GG_429_OTP_SCREEN_ERROR: 'Terlalu banyak permintaan kode verifikasi. \nMohon tunggu 1 jam untuk mencoba kembali',
    GG_500_TITLE: 'Kembali Lagi Nanti Ya!',
    GG_500_DESCRIPTION: 'Kami sedang membereskan beberapa masalah nih, jangan khawatir kami akan segera kembali',
    GG_500_BUTTON_TEXT: 'Oke',
    GG_4031_TITLE: 'Wah, Akses Akun Anda Terkunci',
    GG_4031_DESCRIPTION:
      'Saat ini, akses akun Anda sudah diblokir berdasarkan permintaan HR kantor Anda. Jika ini sebuah kesalahan, harap hubungi HR Anda untuk informasi lebih lanjut',
    GG_4031_BUTTON_TEXT: 'Oke',
    GG_4032_TITLE: 'Mohon Maaf, Kak!',
    GG_4032_DESCRIPTION:
      'Saat ini, akses akun Anda sedang terkunci sementara. Hubungi HR Anda untuk info lebih lanjut. Kami akan memberi kabar jika akun Anda sudah aktif kembali',
    GG_4032_BUTTON_TEXT: 'Oke',
    GG_4033_TITLE: 'Waduh…',
    GG_4033_DESCRIPTION:
      'Ada yang salah, nih. Tapi, bukan salah siapa-siapa kok. Lebih baik hubungi HR Anda untuk informasi lebih lanjut',
    GG_4033_BUTTON_TEXT: 'Oke',
    GG_404_TITLE: 'Nomor HP Tidak Terdaftar',
    GG_404_DESCRIPTION: 'Sepertinya, nomor HP ',
    GG_404_DESCRIPTION2:
      ' ini tidak ada di database kami. Cek ulang nomor yang Anda masukkan atau klik Cek Data Anda supaya kami bisa lakukan pengecekan.',
    GG_404_BUTTON_TEXT: 'Coba Lagi',
    GG_404_BUTTON_TEXT2: 'Cek Data',
    IN_PROCESS_TITLE: 'Data Sedang Diproses',
    IN_PROCESS_DESCRIPTION:
      'Proses ini bisa membutuhkan waktu 5 hari kerja. Hubungi tim GajiGesa jika belum ada info lebih lanjut',
    IN_PROCESS_BUTTONTEXT: 'Oke, Mengerti!',
    REGISTERED_USER_TITLE: 'Nomor HP Sudah Terdaftar',
    REGISTERED_USER_DESCRIPTION: 'Silahkan coba masuk dengan nomor HP',
    REGISTERED_USER_BUTTONTEXT: 'Masuk Kembali',
    RESEND_OTP_TITLE: 'Kode Verifikasi Terkirim',
    RESEND_OTP_DESCRIPTION: 'Cek kode verifikasi Anda yang kami kirim ke nomor HP ',
    RESEND_OTP_DESCRIPTION2: '. Anda dapat meminta kode verifikasi kembali setelah 30 detik',
    RESEND_OTP_BUTTONTEXT: 'Oke',
    LOGOUT_TITLE: 'Anda Yakin Ingin Keluar Dari Aplikasi?',
    LOGOUT_DESCRIPTION:
      'Jika Anda keluar dari aplikasi Anda tidak bisa tarik gaji lebih awal dan akan kelewatan promo promo menarik loh! ',
    LOGOUT_BUTTONTEXT: 'Tetap Keluar',
    LOGOUT_BUTTONTEXT2: 'Batalkan',
    PHONE_DELETE_TITLE: 'Yakin Mau Hapus?',
    PHONE_DELETE_DESCRIPTION: 'Untuk menambah nomor HP, kamu perlu mengulang proses verifikasi dari awal.',
    PHONE_DELETE_BUTTON_TEXT1: 'Hapus',
    PHONE_DELETE_BUTTON_TEXT2: 'Jangan Hapus',
    PHONE_MAX_LIMIT_TITLE: 'Wah, sudah mencapai batas, nih...',
    PHONE_MAX_LIMIT_DESCRIPTION:
      'Maaf, sudah ada 10 nomor HP yang kamu daftarkan di GajiGesa. Tambah lagi jika kamu sudah hapus nomor HP yang tidak terpakai.',
    PHONE_MAX_LIMIT_BUTTON_TEXT: 'Saya Paham',
    PHONE_DUPLICATE_ENTRY: 'Ups, ada yang salah, nih...',
    PHONE_DUPLICATE_ENTRY_DESCRIPTION:
      'Nomor ini ada dalam daftarmu. Silakan tambahkan nomor yang belum ada di daftar, ya!',
    SECURITY_PIN_FALLBACK_TITLE: 'PIN Berhasil Dibuat',
    SECURITY_PIN_FALLBACK_DESCRIPTION: 'PIN sudah aktif, kamu sudah bisa transaksi lebih aman dan nyaman',
    SECURITY_PIN_BUTTON_OK: 'Oke',
    SECURITY_PIN_GG_01_TITLE: 'PIN Berhasil Dibuat',
    SECURITY_PIN_GG_01_DESCRIPTION:
      'PIN sudah aktif, sekarang kamu bisa tambah nomor HP dan akun bank lain dengan aman!',
    SECURITY_PIN_GG_01_BUTTON1: 'Tambah No. HP',
    SECURITY_PIN_GG_01_BUTTON2: 'Tambah Bank',
    SECURITY_PIN_ACTIVE_01_TITLE: 'Manfaat Punya PIN',
    SECURITY_PIN_ACTIVE_01_BUTTON1: 'Tambah No. HP',
    SECURITY_PIN_ACTIVE_01_BUTTON2: 'Tambah Bank',
    SECURITY_PIN_ACTIVE_PHONE_ENROLL_BUTTON1: 'Tambah Nomor HP',
    SECURITY_PIN_ACTIVE_BANK_ENROLL_BUTTON: 'Tambah Akun Bank',
    BANK_DELETE_TITLE: 'Yakin Mau Hapus?',
    BANK_DELETE_DESCRIPTION: 'Untuk menambah rekening ulang, kamu perlu mengulang proses verifikasi dari awal.',
    BANK_DELETE_BUTTONTEXT1: 'Hapus Nomor',
    BANK_DELETE_BUTTONTEXT2: 'Batal',
    BANK_MAX_LIMIT_TITLE: 'Wah, sudah mencapai batas, nih...',
    BANK_MAX_LIMIT_DESCRIPTION:
      'Maaf, kamu sudah menambahkan 10 nomor rekening di GajiGesa. Tambah lagi jika kamu sudah menghapus nomor rekening yang tidak terpakai.',
    BANK_MAX_BUTTONTEXT1: 'Saya Paham',
    BANK_DUPLICATE_ENTRY_TITLE: 'Ups, ada yang salah, nih...',
    BANK_DUPLICATE_ENTRY_DESCRIPTION:
      'Nomor rekening ini sudah ada di daftarmu. Silakan ubah nomor ini atau hapus lalu tambahkan kembali',
    BANK_DUPLICATE_BUTTONTEXT1: 'Kembali',
    LOAN_APPLICATION_TITLE_1: 'Pengajuan Sedang Dalam Proses',
    LOAN_APPLICATION_DESCRIPTION_1:
      'Harap tunggu proses verifikasi dan transfer maks. 1 x 24 jam kerja oleh tim GajiGesa dan tempat kerjamu.',
    LOAN_BUTTON_TEXT_1: 'Oke',
    LOAN_APPLICATION_TITLE_2: 'Pengajuan e-Kasbon Disetujui!',
    LOAN_APPLICATION_DESCRIPTION_2: 'Cek uang masuk di rekening rekening terdaftar (utama) di GajiGesa',
    LOAN_BUTTON_TEXT_2: 'Lihat Detail',
    LOAN_APPLICATION_TITLE_3: 'Pengajuan e-Kasbon Disetujui!',
    LOAN_APPLICATION_DESCRIPTION_3: 'Cek uang masuk di rekening rekening terdaftar (utama) di GajiGesa',
    LOAN_BUTTON_TEXT_3: 'Lihat Detail',

    LOAN_APPLICATION_TITLE_4: 'Pengajuan e-Kasbon Ditolak!',
    LOAN_APPLICATION_DESCRIPTION_4:
      'Maaf, pengajuanmu belum disetujui perusahaan karena belum memenuhi syarat & ketentuan, namun anda masih dapat menggunakan EWA dan Silakan coba ajukan di lain waktu',
    LOAN_BUTTON_TEXT_4: 'Oke',
    LOAN_APPLICATION_TITLE_5: 'Cicilan e-Kasbon Selesai!',
    LOAN_APPLICATION_DESCRIPTION_5:
      'Kamu memenuhi syarat untuk mengajukan e-Kasbon lagi. Tertarik untuk ajukan kembali?',
    LOAN_BUTTON_TEXT_5_1: 'Nanti saja',
    LOAN_BUTTON_TEXT_5_2: 'Ajukan Lagi',
    KTP_MODAL_TITLE_1: 'Yuk, Lengkapi Data Dirimu…',
    KTP_MODAL_DESCRIPTION_1: 'Sebelum mulai menabung, lengkapi dulu yuk data pribadimu untuk keamanan saat transaksi',
    KTP_MODAL_TEXT_1: 'Lengkapi Data Diri Sekarang',
    KTP_MODAL_TITLE_2: 'Fitur Segera Hadir!',
    KTP_MODAL_DESCRIPTION_2: 'Sabar ya sebentar lagi fitur ini akan hadir dan kami akan memberitahu-mu segera!',
    KTP_MODAL_TEXT_2: 'Kembali',
    THREE_RETRY_TITLE: 'PIN Sudah Salah 3 Kali',
    THREE_RETRY_DESCRIPTION:
      'Karena kamu sudah memasukkan PIN yang tidak sama sebanyak 3 kali, yuk ulangi buat PIN baru lagi',
    THREE_RETRY_BUTTONTEXT1: 'Ulangi Buat PIN',
    THREE_RETRY_VERIFICATION_TITLE: 'PIN Sudah Salah 3 Kali',
    THREE_RETRY_VERIFICATION_DESCRIPTION:
      'Kamu sudah salah 3 kali memasukkan PIN yang salah. Permintaan kamu akan tetap diproses, silahkan hubungi layanan pelanggan untuk proses reset PIN',
    THREE_RETRY_VERIFICATION_BUTTONTEXT1: 'Nanti Saja',
    THREE_RETRY_VERIFICATION_BUTTONTEXT2: 'Reset PIN',
    BEFORE_CREATE_PIN_TITLE: 'Eits, Aktivasi PIN Dulu, Yuk!',
    BEFORE_CREATE_PIN_DESCRIPTION: 'Saatnya bikin transaksimu lebih aman dan nyaman di GajiGesa dengan aktivasi PIN',
    BEFORE_CREATE_PIN_BUTTONTEXT2: 'Buat Sekarang',
    USER_BANK_PREVENT_TITLE: 'Yakin Mau Batalkan?',
    USER_BANK_PREVENT_DESCRIPTION:
      'Dengan membatalkan proses ini, kamu perlu mengulangi semua proses verifikasi, mulai dari unggah foto.',
    USER_BANK_PREVENT_BUTTONTEXT1: 'Batalkan',
    USER_BANK_PREVENT_BUTTONTEXT2: 'Jangan Batalkan',
    SECURITY_PIN_GG_01_PHONE_ENROLL_DESCRIPTION:
      'PIN sudah aktif, sekarang kamu bisa tambah nomor HP lain dengan aman!',
    SECURITY_PIN_GG_01_BANK_ENROLL_DESCRIPTION:
      'PIN sudah aktif, sekarang kamu bisa tambah akun bank lain dengan aman!',
    NEED_CREATE_PIN_TITLE: 'Eits, Aktivasi PIN Dulu, Yuk!',
    NEED_CREATE_PIN_DESCRIPTION: 'Saatnya bikin transaksimu lebih aman dan nyaman di GajiGesa dengan aktivasi PIN',
    NEED_CREATE_PIN_BUTTONTEXT: 'Buat Sekarang',
    VERIFY_PIN_LOCKED_TITLE: 'Pin Keamanan Terkunci',
    VERIFY_PIN_LOCKED_DESCRIPTION: 'Coba lagi setelah 10 menit',
    VERIFY_PIN_LOCKED_BUTTONTEXT: 'Oke',
    PHONE_CHANGE_SUCCESS_TITLE: 'Kesuksesan!',
    PHONE_CHANGE_SUCCESS_DESCRIPTION: 'Nomor Telepon Diperbarui. Anda dapat masuk dengan Nomor Telepon baru',
    PHONE_CHANGE_SUCCESS_BUTTONTEXT: 'Melanjutkan',
  },
  otp: {
    enterOTP: 'Masukkan Kode Verifikasi yang kami kirimkan ke',
    headerText: 'Cek Data',
    continue: 'Lanjutkan',
    resendText: 'Tidak terima kode? Kirim ulang ke',
  },
  permissionsModal: {
    contactAccess: 'Akses Kontak',
    contactText1: 'Membantumu mengecek teman kerja yang belum mengaktifkan GajiGesa',
    contactText2: 'Memudahkanmu berbagi kode referral untuk teman kerja. Ada banyak bonus GajiGesa Poin menantimu',
    locationAccess: 'Akses Lokasi',
    locationText1: 'Memberikanmu performa aplikasi lebih baik',
    locationText2: 'Memberikanmu experience pengguna yang lebih mudah',
    contactAndLocation: 'Akses Kontak dan Lokasi',
    allowContactAndLocation: 'Izinkan Akses Untuk Kontak dan Lokasi',
  },
  signup: {
    headerText: 'Cek Data',
    selectData: 'Pilih Data',
    signupInformation: 'Isi data berikut ini untuk bantu kami mengecek akun Anda',
    nameTxtInput: 'Nama Lengkap',
    nameTxtInputPlaceholder: 'Masukkan nama lengkap',
    placeHolderEmpId: 'Masukkan Nama Lengkap',
    comapnyName: 'Nama Perusahaan',
    placeHolderCompanyName: 'Masukkan Nama Tempat Anda Bekerja',
    employeeIdHeader: 'ID Karyawan',
    employeeIdPlaceholder: 'Masukkan ID Karyawan',
    ktpHeader: 'KTP(Kartu Tanda Penduduk)',
    ktpPlaceholder: 'Masukkan Nomor KTP Anda',
    dataTypeForChecking: 'Tipe Data untuk Pengecekan',
  },
  withdrawlSlider: {
    heading: 'Nominal Penarikan',
    selectData: 'Pilih Akun',
    openPeriodText: 'Transaksi bisa dilakukan pada',
    payDateWithdrawal: 'dan pada tanggal gajian',
    withdrawButton: 'Tarik Gaji Sekarang',
    withdrawalReason: 'Alasan Penarikan Uang',
  },
  withdrawal: {
    status: {
      inactive: 'Masa Transaksimu Sedang Tidak Aktif',
      withdrawalSuspended: 'Akun Kamu Sedang Tidak Aktif',
    },
  },
  profile: {
    hi: 'Hai',
    lihat: 'Lihat',
    bonusHeader: 'Bonus dan Hadiah',
    information: 'Informasi',
    securityHeader: 'Keamanan Akun',
    referralHeader: 'Halaman Finansial',
    defaultBankHeader: 'Rekening Terdaftar (Utama)',
    addBankString: 'Tambah rekening lainnya',
    registeredBanks: 'Lihat Rekening Bank Terdaftar',
    myPoints: 'Poin Saya',
    zonaRekanHR: 'Zona Rekan HR',
    phoneEnroll: 'Daftar Nomor HP',
    salaryInfo: 'Informasi Gaji',
    picZone: 'PIC Zone',
    pinsText: 'PIN Keamanan',
    phoneChange: 'Perubahan Nomor Telepon',
    manageSecurityPin: 'Kelola PIN',
    securityPinActive: 'Aktif',
    securityPinRejected: 'Buat Sekarang!',
    bantuanHeader: 'Bantuan',
    customerService: 'Layanan Pelanggan',
    bankList: 'Daftar bank',
    logout: 'Keluar Aplikasi',
    primaryBankHeader: 'Rekening Terdaftar (Utama)',
    bankListInfo: 'Rekening utama merupakan nomor rekening bank yang didaftarkan tempat kerjamu di GajiGesa.',
    bankOtherList: 'Rekening Terdaftar Lainnya',
    bankMaxLimit: '(Maks. 10 rekening lainnya)',
    appVersion: 'App version',
    otaVersion: 'OTA Version',
  },
  bp: {
    phoneTransfer: 'Ganti',
    customerId: customerIdIndo,
    customerIdInput: 'Masukkan ID Pelanggan',
    selectIdHeader: 'Pilih ID Pelanggan',
    selectedId: customerIdIndo,
    selectRegionHeader: 'WIlayah',
    selectRegion: 'Pilih Wilayah',
    subProductNominals: 'Pilih Produk:',
    price: 'Harga',
    voucherTnC: 'Tukarkan sebelum 7 hari dari tanggal pembelian voucher',
    voucherHeader1: 'Yang Kamu Dapatkan',
    voucherHeader2: 'Syarat & Ketentuan',
    voucherHeader3: 'Cara Redeem Voucher',
    provider: 'Provider',
    bpDetails: 'Detail Pembayaran',
    regionSelect: 'Cari Wilayah',
    selectOrInputAccountNumber: 'Silahkan isi ID Pelanggan Kamu',
    unavailableProduct: 'Saldo GajiGesa tidak cukup untuk melakukan transaksi ini. Coba cek akun kamu yang lain',
    unavailableNominalHeader: 'Produk Tidak Ditemukan',
    unavailableNominalDescription: 'Silahkan cek kembali nomor atau ID pelanggan',
    pascabayarDetails: 'Detail Nomor',
    pdamAccountModalHeaderTitle: 'Pilih ID Pelanggan',
    new: 'Baru',
    registeredID: 'ID Terdaftar',
  },
  reqTxSheet: {
    chosenProduct: 'Informasi Produk',
    phoneNumber: 'Nomor ponsel',
    provider: 'Provider',
    requestedAmount: 'Nominal Pulsa',
    customerId: customerIdIndo,
    customerName: 'Nama pelanggan',
    tokenPLNNominal: 'Nominal Token',
    voucherType: 'Jenis voucher',
    voucherNominal: 'Nominal voucher',
    emoneyProvider: 'Tipe E-Money',
    emoneyNominal: 'Nominal top up',
    paketDataName: 'Jenis Paket Data',
    monthYear: 'Bulan/Tahun',
    regionName: 'Nama daerah',
    subTotal: 'Total pembayaran',
    total: 'Total',
    totalPostpaid: adminFee,
    subTotalPostpaid: 'Tagihan PLN',
    pdamBill: 'Tagihan PDAM',
    tncHeader: 'Ketentuan Layanan',
    bpTnc:
      'Apabila ada, saya bersedia untuk membayar biaya yang muncul atas penggunaan layanan GajiGesa.\n\nSaya menyetujui biaya layanan GajiGesa akan dikurangi secara langsung dari jumlah dana yang saya ajukan dan saya akan menerima dana di rekening saya sejumlah yang telah dikurangi biaya layanan yang muncul.\n\nSaya menyetujui gaji yang akan saya terima dari perusahaan pada tanggal gajian akan dipotong sejumlah total transaksi yang saya lakukan melalui layanan GajiGesa.\n\n',
    bpDetails: 'Dipotong Dari :',
    cancelTnC: 'Batal',
    acceptTnC: 'Setuju',
    checkBill: 'Cek Tagihan',
    confirmTnC: 'Saya mengkonfirmasi rincian transfer.',
    goldCreatedAt: 'Waktu Transaksi',
    goldPrice: 'Harga Beli Emas',
    goldUnit: 'Jumlah Berat Emas',
    tagihanPascabayar: 'Tagihan Pascabayar',
  },
  successPage: {
    txInProcess: 'Transaksi Dalam Proses',
    purchased: 'Pembayaran',
    txTimeTaken: 'Proses bisa memakan waktu hingga 5 menit',
  },
  transactionHistory: {
    kodeLink: 'Kode/Link Tersedia',
    belum: 'Belum Ada Transaksi',
    belumDes: 'Yuk mulai bertransaksi dan pantau riwayat transaksi \nKamu disini',
  },
  transactionSummary: {
    totalIncome: 'Total Pendapatan',
    totalExpenses: 'Total Pengeluaran',
    total: {
      0: 'Uang Kamu adalah Total Pendapatan - Total Pengeluaran',
      1: 'Total saldo GajiGesa Poin yang diterima dari skema reward',
      3: 'Akun yang diterima melalui penalangan GajiGesa',
      4: 'Akun yang digunakan sebagai dana operasional kantor',
      5: 'Total GajiGesa Poin yang telah ditukar menjadi dana kas',
    },
    income: {
      0: 'Total Pendapatan adalah Gaji Pro Rata + Tunjangan, Bonus, dan Komisi.',
      1: 'Total Pendapatan mengacu pada reward yang Kamu terima',
      3: txnIncomeTextInd,
      4: txnIncomeTextInd,
      5: 'Total Pendapatan mengacu pada saldo Poin yang Kamu tukar',
    },
  },
  transStatus: {
    wait: 'Menunggu',
    success: 'Sukses',
    failed: 'Gagal',
    rejected: 'Ditolak',
  },
  accountSelectorText: {
    basedOn: 'Berdasarkan',
  },
  reasonsModal: {
    previous: 'Sebelumnya',
    next: 'Selanjutnya',
    reasonForWithdrawal: 'Alasan Penarikan',
  },
  withdrawalPage: {
    header: 'Penarikan Uang',
    detailsHeader: detailTransaksi,
    amountOfWithdrawal: 'Jumlah Penarikan',
    adminFee,
    withdrawalTotal: 'Jumlah Yang diterima',
    destinationAccount: 'Rekening Tujuan',
  },
  editTransactions: {
    status: 'Status',
    transDate: 'Tanggal',
    transaksi: 'Transaksi',
    nominalPulsa: 'Nominal Pulsa',
    phoneNumber: 'Nomor HP',
    totalPembayaran: 'Total Pembayaran',
    kantongDana: 'Kantong Dana',
    provider: 'Provider',
    customerId: customerIdIndo,
    customerName: 'Nama Pelanggan',
    nominalToken: 'Nominal Token',
    nominalVoucher: 'Nominal Voucher',
    emoneyPhoneNumber: 'Nomor HP',
    emoneyNominal: 'Nominal Top-up',
    emoneyProductName: 'Tipe e-Money',
    packetProductName: 'Jenis Paket Data',
    bulanTahun: 'Bulan/ Tahun',
    tagihanPascabayar: 'Tagihan Pascabayar',
    adminFee,
    nominalPLN: 'Nominal Tagihan PLN',
    pdamNama: 'Nama PDAM',
    pdamCustomerName: 'Tagihan PDAM',
    hargaBelis: 'Harga Beli Emas',
    jumlahBerat: 'Jumlah Berat Emas',
    noOfReferensi: 'No. Referensi',
    jumlahPenarikan: 'Jumlah Penarikan',
    jumlahPenyesusian: 'Jumlah penyesuaian',
    bankName: 'Bank Penerima',
    transReceivedAmount: 'Jumlah yang diterima',
    beneficiaryName: 'Pemilik Rekening',
    bankAccountNumber: 'No. Rekening Penerima',
    failedToUpload: '(Gagal Upload)',
    loanBankName: 'Bank Tujuan',
    loanBankAccountNo: 'No. Rekening',
  },
  transactionDetailsScreen: {
    detailTransaksi,
    keterangan: 'Keterangan',
    uangdescTitle: 'Deskripsi Transaksi',
    masukkanKeterangan: 'Masukkan Keterangan',
    instructions: `Jangan tutup halaman ini hingga bukti transaksimu ter-upload dan terkirim`,
    instructionTwo: 'Harap hanya upload 1 (satu) file foto yang berisi gabungan beberapa bukti',
    kodeVoucherTersalin: 'Kode voucher tersalin!',
    uploadBuktiPembayaran: 'Upload Bukti Pembayaran(JPG, PNG)',
    uploadBuktiPembayaranShort: 'Upload Bukti Pembayaran',
    limitDescription: 'Hanya bisa mengupload 1 bukti transaksi, jadi gabungkan bukti transaksi menjadi 1 file',
    dariGaleri: 'Dari Galeri',
    simpan: 'Simpan',
  },
  autoDisburse: {
    navTitle: termsAndConditionInd,
    lockPeriod: lockPeriodText,
    suspended: 'Tidak bisa bertransaksi, silahkan hubungi HR untuk mengecek status akun!',
    optIn: {
      first: 'Masa tutup transaksi-mu akan dimulai tanggal',
      linkButton: 'Pelajari lebih lanjut',
    },
    optOut: {
      first: 'Penarikan sisa gaji akan ditransfer ke bank utama kamu pada tanggal',
      linkButton: 'Batalkan?',
    },
    successOptIn: 'Permintaan tarik sisa gaji berhasil!',
    terms: [
      'Sisa saldo dalam akun akan dikirim secara otomatis ke akun bank utama Anda setiap hari terakhir masa transaksi pada pukul 5 sore',
      'Proses penarikan sisa saldo otomatis akan dikenakan biaya admin',
      'Saldo akan dipotong secara langsung dari akun gaji Anda (Proses pemotongan yang sama berlaku pada transaksi lainnya)',
      'Untuk membatalkan penarikan sisa saldo otomatis, segera hubungi tim operasional GajiGesa',
      'Pembatalan hanya bisa dilakukan maks. 2 hari sebelum siklus tutup gaji dimulai',
    ],
    mainTitle: 'Tarik Gaji Sebelum Masa Tutup Transaksi Berakhir!',
    descOne:
      'Jika Anda menyetujui S&K di bawah, sisa saldo dalam Akun Gaji akan dikirim ke rekening bank utama Anda yang terdaftar di GajiGesa.',
    descTwo: 'Keseluruhan saldo akan dikirim pada hari terakhir di masa transaksi, APABILA perusahaan Anda menyetujui.',
    bulletOne: termsAndConditionInd,
    termsTitle: 'Saya setuju dengan ketentuan dan syarat yang berlaku',
    continue: 'Lanjutkan',
  },
  withdrawalSuccess: {
    withdrawText: 'Penarikan Gaji Sebesar',
    pendingText: 'Transaksi Dalam Proses',
    successText: 'Transaksi Sukses',
  },
  phoneTransfer: {
    phoneTitle: 'Nomor HP Tersimpan',
    noRegisteredText: 'Belum Ada Nomor HP Terdaftar',
    phoneAddPurpose:
      'Nomor HP Lainnya Masih Kosong Manfaatkan fitur ini untuk top-up pulsa, paket data, atau saldo e-wallet untuk orang terdekatmu.',
    phoneAddBtnText: 'Tambah Nomor HP',
    phoneTransferSupport: 'Verifikasi Sekarang',
    phoneVerify: 'Dalam Verifikasi',
    phoneRejected: 'Ditolak',
    userPhone: 'Nomor HP Kamu (Utama)',
    phoneMaxLimit: '(Maks. 10 nomor lainnya)',
    phoneTransferWarning: 'Nomor HP yang kamu daftarkan perlu divalidasi terlebih dahulu sebelum bisa digunakan.',
  },
  addPhone: {
    phonePlaceHolderText: 'Contoh: Ayah, Ibu, Joko, Siti',
    addPhoneText: 'Masukkan Nomor HP',
    addPhoneNameText: 'Masukkan Nama',
    addPhoneInfo1:
      'Permudah transaksi beli pulsa, paket data, dan top-up e-wallet untuk orang lain dengan mendaftarkan nomornya.',
    addPhoneInfo2: 'Nomor ini akan diverifikasi manual oleh tim GajiGesa',
    addPhoneButtonText: 'Simpan Nomor',
    numberHeader: 'Nomor HP Lainnya',
    phonePending: 'Pendaftaran nomor baru ini sedang diverifikasi',
    phoneApproved: 'Nomor HP tersimpan',
  },
  userProfile: {
    noteText: 'Promosi eksklusif siap menanti Anda sebagai Rekan HR!',
    unTukText: '*Untuk perubahan data silahkan hubungi HR Kamu',
    selectableText: 'Simpan dan Lanjutkan',
    departmentEmpty: 'Pilih Departemen Kamu',
    desginationEmpty: 'Pilih Jabatan Kamu',
    lihatGateway: 'Lihat Benefit Rekan HR',
    tag: 'Rekan HR',
    textOne: phoneNumberInd,
    texttwo: 'Nama',
    textThree: 'Nomor HP',
    textFour: 'Data Perusahaan',
    textFive: 'Data Perusahaan',
    textSix: 'Departemen',
    textSeven: 'Jabatan',
    headerBack: 'Data Diri',
    textEight: 'Hai',
    textNine: phoneNumberInd,
    toastSuccess: 'Departemen dan Jabatan Tersimpan!',
    SUB_TERMS: [
      'Anda terpilih menjadi Rekan HR Gajigesa pada tanggal 1 Agustus 2023.',
      'Anda mengajak Adi, rekan kerja Anda untuk bertransaksi di aplikasi GajiGesa dengan mengirimkan pesan WhatsApp ajakan melalui ikon WhatsApp pada kontak Adi di halaman Zona Rekan HR pada tanggal 2 Agustus 2023.',
      'Adi kemudian login aplikasi GajiGesa pada tanggal 3 Agustus 2023.',
      'Maka, Anda akan mendapatkan insentif berupa GajiGesa poin sebesar 20.000.',
    ],
    PIC_TERMS_TEXT: [
      'Anda adalah Rekan HR yang terpilih untuk mendapatkan insentif berupa GajiGesa poin sebesar 20.000 untuk setiap karyawan / kolega di perusahaan Anda yang login di Aplikasi GajiGesa, setelah Anda mengajak karyawan / kolega di perusahaan Anda melalui WhatsApp. Contohnya:',
      'Anda juga bisa mendapatkan insentif berupa GajiGesa poin sebesar 25.000 jika rekan kerja Anda menggunakan kode referral Anda untuk melakukan transaksi pertama kali di GajiGesa. Selengkapnya, baca syarat dan ketentuan di sini',
      'Program ini hanya berlaku untuk sesama karyawan yang bekerja dalam satu kantor/ perusahaan.',
      'Program ini hanya berlaku untuk transaksi menggunakan aplikasi Gajigesa.',
      'Baca syarat dan ketentuan di sini',
      'https://gajigesa.com/gajigesa-referral',
      'https://gajigesa.com/rekan-hr',
    ],
    termsText: 'Cek Syarat & Ketentuan',
    linkTerms: 'di Sini!',
    textInputPlaceHolder: 'Cari Nama Teman Anda',
    titleOne: 'Daftar Teman',
    titleTwo: 'Status Ajakan',
    listingItemOne: 'Login Pertama',
    listingItemTwo: 'Sudah Transaksi',
    whatsappContentOne:
      'Cobain benefit kantor yuk! Sebagai karyawan kantor kita, kamu bisa tarik gajimu sebelum tanggal gajian dengan GajiGesa lho. Buka aplikasi GajiGesa sekarang dan dapatkan GRATIS 45.000 Poin untuk transaksi pertamamu',
    whatsappContentTwo: '➡️ Download aplikasi GajiGesa di bit.ly/gajigesa-android  atau kunjungi app.gajigesa.com',
    departmentSearch: 'Cari Nama jabatan',
    jabatan: 'Jabatan',
    poinEarned: 'Poin Terkumpul',
    tooltipBanner: 'Jumlah Poin yang berhasil Anda kumpul selama promo influencer berlangsung',
    picLandingTitle: 'Zona Rekan HR',
    deptDesginTitle: 'Pilih Departemen',
    designTitle: 'Pilih Jabatan',
    termsAndCondition: termsAndConditionInd,
  },
  addBank: {
    bankTitle: 'Tambah Rekening Bank',
    bankTitlePlaceholder: 'Pilih Bank',
    bankNameTitle: 'Nama Bank',
    bankNameTitlePlaceholder: 'Contoh: 3216549870',
    bankAccontNumTitle: 'Nomor Rekening',
    bankAccountName: 'Nama Pemilik Rekening',
    bankAccountNamePlaceholder: 'Contoh: Mardiasih',
    bankAccountNickName: 'Label Pemilik Rekening (optional)',
    bankAccountNickNamePlaceholder: 'Contoh: Rek Ayah, Rek Tagihan',
    bankAccountAdd: 'Simpan Data Rekening',
    bankAccountAddInfo:
      'Data rekeningmu akan diverikasi manual oleh tim GajiGesa. Percepat proses verifikasi dengan menghubungi tim support GajiGesa, atau tunggu maks. 3 x 24 jam.',
    findBank: 'Cari nama bank',
    bankList: 'Rekening Bank Terdaftar',
    bankHeaderFromTransfer: 'Pilih Rekening',
    addPending: 'Proses verifikasi mulai diproses setelah kamu klik Verifikasi Sekarang',
    addApproved: 'Rekening baru tersimpan',
    accountNameError: 'Nama Pemilik Rekening tidak boleh mengandung karakter spesial dan angka.',
    selectBank: 'Silakan pilih bank',
    numValidation: 'Jumlah melebihi validasi angka',
    requiredTransfer: 'Jumlah diperlukan untuk transfer',
    numericString: 'Nomor rekening harus berupa angka',
    reqName: 'Nama pemilik rekening wajib diisi',
  },
  bankTransfer: {
    sourceOfFunds: 'Sumber Dana',
    transferOfDay: 'Transfer Dana',
    accountName: 'Nama Rekening',
    transferInfo: 'Sumber dana untuk transfer ini berasal dari Akun Gaji GajiGesa.',
    amountForTransfer: 'Nominal Pengiriman',
    successText: 'Pengiriman Dana Sebesar',
    notEnoughBalance: 'Saldo tidak cukup',
  },
  bankEmptyPage: {
    emptyBanksTitle: 'Nomor rekening lainnya masih kosong',
    useBankAdd: 'Gunakan fitur ini untuk transfer dana ke rekening lain yang terdaftar.',
    addBank: 'Tambah Rekening',
  },
  bankList: {
    accountList: 'Daftar Rekening',
    accountListForTransfer: 'Rekening Tersimpan',
    addBankAccount: 'Tambah Rekening',
    hanya: 'Hanya nomor rekening terverifikasi yang bisa digunakan.',
  },
  securityPin: {
    pinScreenTitle: 'PIN Keamanan',
    bannerTitle: 'Aktivasi PIN untuk kemanan akunmu',
    setPinText: 'Buat Sekarang!',
    reEnterPin: 'Masukkan Ulang PIN',
    makeNewPin: 'Buat PIN Baru',
    enterPin: 'Masukkan PIN Kamu',
    securityInfo:
      'Hindari gunakan PIN dengan rangkaian angka yang mudah ditebak. Contoh: "000000", “111111”, "123456", "654321"',
    bankInfo: 'Kamu bisa tarik dan kirim gaji ke akun bank-mu yang lain',
    phoneInfo: 'Kamu bisa beli pulsa, paket data dan transaksi lainnya ke nomor HP-mu yang lain',
    errorPinNotSame: 'PIN tidak sama',
    errorPinFormat: 'Format PIN salah, silahkan ulangi kembali dan cek informasi dibawah',
    resetLinkText: 'Ada kendala? Hubungi Layanan Pelanggan',
    retry1: 'PIN yang dimasukkan salah, tinggal ',
    retry2: 'kali kesempatan lagi sebelum PIN diblokir',
  },
  treasury: {
    bannerTitle: 'Lengkapi KTP dan Transaksi \nEmas, Raih Bonus 35.000 Poin!',
    bannerButton: 'Ambil Hadiah',
    goldTerms: [
      'Anda tidak dapat menggabungkan akun Treasury pribadi dengan akun Treasury yang terintegrasi di platform GajiGesa.',
      'Anda belum dapat menukarkan emas digitalmu ke dalam bentuk emas fisik. Untuk sekarang, Anda hanya bisa membeli dan menjual emas dalam bentuk digital.',
      'Apabila akun GajiGesa Anda sudah tidak dapat diakses kembali atau sudah tidak aktif, hubungi tim Customer Support GajiGesa untuk keperluan klaim akses dan atau saldo emas digital akun Treasury Anda.',
    ],
    ktpFieldError: 'Nomor KTP tidak valid',
    ktpTitle: 'Silahkan isi informasi kamu',
    ktpTitleOne: 'Nama Sesuai KTP',
    ktpPlaceholder: 'Masukkan nama lengkap kamu',
    ktpTitleTwo: 'Nomor KTP / Nomor Identitas Penduduk',
    ktpPlaceHOlderTwo: 'Masukkan Nomor KTP kamu',
    ktpTItleThree: 'Kode Promo (Tidak Wajib di Isi)',
    ktpPlaceHolder: 'Masukkan Kode referral atau promo di sini',
    infoTextBucket: [
      'Untuk menggunakan fitur tabungan emas, silakan lengkapi data pribadi Anda dengan nomor KTP yang valid',
      'Privasi data Anda sangat penting bagi kami. Menjaga kerahasiaannya adalah prioritas utama GajiGesa. Terima kasih atas kerjasamanya!',
    ],
    ktpLanjuktan: 'Simpan dan Lanjutkan',
    benifitsDisplayOne: 'Modal Ringan',
    benifitsDisplayTwo: 'Mudah Dicairkan',
    benifitsDisplayThree: 'Proteksi Nilai\nKekayaan',
    termsBandOne: 'Cek Syarat Ketentuan Tabungan Emas',
    termsBandTwo: 'di Sini!',
    hadia: 'Hadiah buatmu!',
    hadiatwo: '*Bonus akan diberikan maksimal 3 hari setelah transaksi pertama',
    sponsorOne: 'Didukung Oleh',
    sponsortwo: 'Berlisensi & diawasi oleh',
    ktpHeaderTitle: 'Pendaftaran Data Diri',
    webViewTitle: 'Tabungan Emas - GajiGesa',
    termsAndConditions: termsAndConditionInd,
    mainButtontitle: 'Emas Saya',
    kodePromo: 'Kode Promo',
  },

  loan: {
    LOAN_TERMS: [
      'Saya menyetujui bahwa persetujuan atas layanan Kasbon adalah kewenangan penuh perusahaan tempat saya bekerja.',
      'Saya bersedia untuk membayar biaya layanan dan cicilan Kasbon yang muncul atas penggunaan layanan ini.',
      'Saya menyetujui biaya layanan akan dikurangi secara langsung dari jumlah dana yang saya ajukan dan saya akan menerima dana di rekening saya sejumlah yang telah dikurangi biaya layanan yang muncul.',
      'Saya menyetujui gaji yang akan saya terima dari perusahaan pada setiap tanggal gajian akan dipotong sejumlah total transaksi yang saya lakukan melalui layanan ini.',
      'Saya akan bertanggung jawab untuk penyelesaian kewajiban layanan Kasbon ini (apabila masih ada), terlepas dari status hubungan kerja saya dengan perusahaan.',
    ],
    saya: ' Saya Setuju',
    INFO_STATUS_DESC_1:
      'e-Kasbon disetujui! Dana berhasil ditransfer ke rekening rekening terdaftar (utama) di GajiGesa',
    INFO_STATUS_DESC_2: 'Pengajuanmu sedang dalam proses verifikasi. Kami akan memberitahumu setelah disetujui',
    INFO_STATUS_DESC_3: 'Jika disetujui dana akan ditransfer ke rekening bank terdaftar (utama) di GajiGesa',
    loanHeaderText: 'Ajukan dana cepat sesuai kebutuhanmu dengan e-Kasbon. Cicil hingga 3 kali, langsung tuntas!',
    toastErrorLoan: 'Maaf, kamu tidak bisa bertransaksi saat ini. Hubungi HR-mu jika ingin bertransaksi kembali.',
    loanTermsText: 'Saya menyetujui Syarat & Ketentuan yang berlaku',
    harapa: 'Harapa Perhatikan!',
    baca: 'Baca ketentuan berikut ini sebelum melanjutkan proses pengajuan dana',
    riwayat: 'Riwayat e-Kasbon',
    pilih: 'Pilih jumlah dana yang kamu butuhkan',
    menunggu: 'Menunggu Persetujuan',
    jumlah: 'Jumlah dana diterima',
    cicilan: 'Cicilan',
    ciciBulan: 'Cicilan per bulan',
    pelajari: 'Pelajari Cara Pengajuan di Sini',
    loanText1: 'Detail Cicilan',
    loanText2: 'Pemotongan gaji akan dilakukan untuk cicilan e-Kasbon setiap siklus gajian, sesuai dengan tenor',
    loanText3: 'Cicilan Ke',
    loanText4: 'Tanggal dan Status',
    loanItemText1: 'Jumlah dana diterima',
    loanItemText2: 'Tenor',
    loanItemText3: 'Cicilan per bulan',
    loanMetaText1: 'Biaya layanan akan langsung memotong jumlah danamu yang telah disetujui',
    loanMetaText2: 'Biaya layanan',
    loanMetaText3: 'Rincian',
    loanMetaInfo1: 'Pilih jumlah dana yang kamu butuhkan',
    loanMetaInfo2: 'Tunggu proses pengecekan oleh tempat kerjamu',
    loanMetaInfo3: 'Setelah pengajuan disetujui, cek uang masuk di rekening terdaftar (utama) di GajiGesa',
    loanMetaInfo4: 'Pada hari gajian, ada pemotongan gaji untuk cicilan e-Kasbon sesuai dengan tenor',
    loanMetaInfo5: 'Ajukan lagi e-Kasbon jika cicilanmu sudah selesai',
    loanMetaInfo6: 'Baca syarat dan ketentuan selengkapnya di sini:',
    transaksiDetail: detailTransaksi,
    transaksiDetail2: 'Biaya Layanan',
    transaksiDetail3: 'Bank Tujuan',
    transaksiDetail4: 'No. Rekening',
    transaksiDetail5: 'Pemilik Rekening',
    termsAndCondition: termsAndConditionInd,
    loanButton: 'Lanjutkan Proses',
  },
  salaryInfo: {
    textOne: 'Sisa Saldo Akun Gaji Tersedia',
    textTwo: 'Cek Limit Akun Gaji Periode Ini',
    textThree: `Sisa Saldo Akun Gaji Tersedia adalah\nTotal Gaji Akumulatif - Total Pengeluaran`,
    textFour: 'Total Gaji Akumulatif',
    textFive: 'Total Pengeluaran',
    textSiz: 'Status Masa Transaksi',
    textSeven: 'Aktif Sampai',
    textEight: 'Sedang Tidak Aktif',
    textNine: lockPeriodText,
    textTen: 'Periode transaksi kamu adalah',
    textEleven: 'Edukasi GajiGesa',
    FAQ_TITLE_ONE: 'Apa itu Akun Gaji?',
    FAQ_DESC_1:
      'Akun Gaji adalah dana yang bisa kamu akses per siklus gaji. Jumlah saldo ini bertambah tiap harinya, menyesuaikan dengan jumlah hari kerjamu.',
    FAQ_DESC_2:
      'Saldo ini bisa digunakan untuk tarik gaji langsung, bayar tagihan, isi pulsa, dan/atau top-up saldo e-money. Jumlah ini menyesuaikan dengan total pengeluaranmu.',
    FAQ_DESC_3:
      'Jumlah Akun Gaji ini teratur-ulang saat masa transaksi sudah aktif kembali. Saldo tersisa akan ditransfer ke rekeningmu bersamaan dengan gajimu.',
    FAQ_TITLE_TWO: 'Apa Itu Masa Transaksi?',
    FAQ_DESC_4:
      'Saat masa transaksi aktif, kamu bisa mengakses gaji. Dengan demikian, kamu bisa melakukan penarikan gaji atau pembayaran tagihan dengan Saldo GajiGesa.',
    FAQ_DESC_5: 'Saat masa transaksimu nonaktif, kamu hanya bisa melakukan transaksi dengan GajiGesa Poin. ',
    FAQ_DESC_6:
      'Masa transaksi ini biasanya berdekatan dengan tanggal gajianmu. Hubungi HR-mu untuk informasi lebih lanjut terkait masa transaksi.',
    tooltipTitle: 'Limit Akun Gaji',
    tooltipDesc:
      'Jumlah ini merupakan total saldo maksimal yang bisa diakses per periode transaksi/siklus gaji, sesuai dengan ketentuan tempat kerjamu',
    harja: 'Total Hari Kerja',
    xGaji: 'x Gaji Per Hari',
  },
  sdModal: {
    waitAMinute: 'Tunggu Dulu',
    chooseSD:
      'Pada periode ini, kamu hanya bisa melakukan pencairan sisa gajimu bulan ini. Silakan pilih Pencairan Gaji.',
    sdBtn: 'Oke, Saya Mengerti',
  },
  appUpdate: {
    textOne: 'Yuk, Update Aplikasi GajiGesa-mu!',
    textTwo: 'Nikmati performa app lebih baik lagi dengan perbarui versi terbaru GajiGesa di Playstore',
    textThree: 'Perbarui Sekarang',
    textFour: 'Update GajiGesa versi terbaru!',
    textFive: 'Segera update aplikasi untuk menikmati layanan yang lebih baik.',
    textSix: 'UPDATE',
  },
  new: 'Baru',
  phoneNumberChange: {
    enterPhoneText: 'Silakan Masukkan Nomor Telepon Baru Anda',
    nextButtonText: 'Berikutnya',
    enterOtp: 'Masukkan OTP yang Diterima di Nomor Baru',
    enterOtpDescription:
      'Setelah Verifikasi Berhasil, Anda Akan Keluar Dari Semua Perangkat. Dan Harus Masuk Menggunakan Nomor Baru Lagi',
  },
};

export default id;
