import { AntDesign } from '@expo/vector-icons';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { View, Text } from 'native-base';
import React from 'react';

type TenantSettings = {
  lang: string;
};

type SelectedAccount = {
  error?: Record<string, string>;
};

type Store = {
  tenantSettings: TenantSettings;
  selectedAccount?: SelectedAccount;
};

function WithdrawError() {
  const { tenantSettings, selectedAccount }: Store = getStore();
  const { error } = selectedAccount || {};

  if (error) {
    return (
      <View
        backgroundColor={colors.error.softRose}
        flexDirection="row"
        alignItems="center"
        style={[
          appStyles.paddingHorizontal(12),
          appStyles.paddingVertical(4),
          appStyles.borderRadius(8),
          appStyles.marginBottom(16),
        ]}>
        <AntDesign name="warning" size={scale(24)} color={colors.error.rose} />
        <Text variant="xsm-bold" color={colors.neutral.charcoal} style={[appStyles.marginLeft(8), appStyles.flex1]}>
          {error[tenantSettings.lang]}
        </Text>
      </View>
    );
  }
  return null;
}

export default WithdrawError;
