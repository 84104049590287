import { AntDesign } from '@expo/vector-icons';
import colors from '@theme/colors';
import { View, Icon } from 'native-base';
import React from 'react';
import styles from './styles';

const ArrowUp: React.FC = () => (
  <View style={styles.arrowStyle}>
    <Icon as={AntDesign} name="up" color={colors.neutral.charcoal} />
  </View>
);

export default ArrowUp;
