import config from '@config';
import { shareApiGeneric } from '@screens/GGReferralPage/referralPageUtils';
import tenantConfig from '@tenantConfig';

const initialState = {
  loading: false,
  loginData: null,
  otpData: null,
  tenantSettings: tenantConfig[config.COUNTRY_CODE],
  meData: null,
  accounts: [],
  withdrawlAccounts: [],
  bpAccounts: [],
  selectedBPAccount: {},
  canShowReferral: false,
  employeeId: null,
  isInLockPeriod: false,
  referralApplicable: false,
  status: '',
  userId: null,
  selectedAccount: {},
  featureFlags: [],
  reasons: [],
  billPaymentData: null,
  loadingMessage: 'Mohon menunggu',
  employerId: '',
  showPermissionsDialog: false,
  employeeProfile: null,
  employeeBank: {},
  pinRegister: {},
  referralValidated: false,
  selectedPCId: null,
  selectedTxnSummaryAcc: 0,
  selectedTxnAccount: {},
  validatedReferralCode: null,
  bpCategoryPrefix: '',
  bpNominalSelected: '',
  withdrawalReason: null,
  transactionReason: null,
  isValidPromocode: false,
  promocode: '',
  treasuryPayload: null,
  bpPhone: null,
  searchText: '',
  employeesToRefer: [],
  employeesReferred: [],
  defaultBank: null,
  bankForTransfer: null,
  isSuspended: false,
  isLoanTermsAccepted: false,
  customReasonText: '',
  editRefresh: false,
  headerText: '',
  referralButtonText: '',
  successPageBanners: [],
  deepLinkData: '',
  referralShortLink: '',
  meAmplitudeContext: [],
  shouldForceLogout: false,
  autoDisburseData: {},
  securityPinOpen: {
    token: null,
    attempts: 0,
    remainingAttempts: 0,
  },
};

const createSlice = (set, get) => ({
  ...initialState,
  setLoading: loading => set({ loading }),
  setLoginData: loginData => set({ loginData }),
  setOtpData: otpData => set({ otpData }),
  setFeatureFlags: featureFlags => set({ featureFlags }),
  setSelectedAccount: selectedAccount => set({ selectedAccount }),
  /** Items set during /me api=======START====== */
  setAccounts: async accounts => {
    await set({ accounts });
    await set({
      withdrawlAccounts: accounts?.filter(item => item.isWithdrawalEligible) || [],
    });
  },
  setWithdrawlAccounts: withdrawlAccounts => set({ withdrawlAccounts }),
  setBPAccounts: bpAccounts => set({ bpAccounts }),
  setSelectedBPAccounts: selectedBPAccount => set({ selectedBPAccount }),
  setCanShowReferral: canShowReferral => set({ canShowReferral }),
  setEmployeeId: employeeId => set({ employeeId }),
  setIsInLockPeriod: isInLockPeriod => set({ isInLockPeriod }),
  setReferralApplicable: referralApplicable => set({ referralApplicable }),
  setStatus: status => set({ status }),
  setUserId: userId => set({ userId }),
  /** Items set during /me api=======END====== */
  setReasons: (reasons, initial = true) => {
    if (initial) {
      set({
        reasons: reasons.map(item => {
          const unSelectedReasons = item;
          unSelectedReasons.selected = false;
          return { ...unSelectedReasons };
        }),
      });
    }
    set({ reasons });
  },

  setTenantSettings: tenantSettings => {
    if (tenantSettings) {
      set({ tenantSettings });
    } else {
      set({ tenantSettings: tenantConfig[config.COUNTRY_CODE] });
    }
  },
  setBillPaymentData: data => set({ data }),
  setLoadingMessage: loadingMessage => set({ loadingMessage }),
  setEmployerId: employerId => set({ employerId }),
  setShowPermissionsDialog: showPermissionsDialog => set({ showPermissionsDialog }),
  setEmployeeProfile: employeeProfile => set({ employeeProfile }),
  setEmployeeBank: employeeBank => set({ employeeBank }),
  setShowPinRegister: pinRegister => set({ pinRegister }),
  setInitialData: () => set({ ...initialState }),
  setReferralValidated: referralValidated => set({ referralValidated }),
  setValidatedReferralCode: validatedReferralCode => set({ validatedReferralCode }),
  setSelectedPCId: selectedPCId => set({ selectedPCId }),
  setTxnSummaryAcc: selectedTxnSummaryAcc => set({ selectedTxnSummaryAcc }),
  setSelectedTxnAccount: selectedTxnAccount => set({ selectedTxnAccount }),
  setBpCategoryPrefix: bpCategoryPrefix => set({ bpCategoryPrefix }),
  setBpNominalSelected: bpNominalSelected => set({ bpNominalSelected }),
  setWithdrawalReason: withdrawalReason => set({ withdrawalReason }),
  setTransactionReason: transactionReason => set({ transactionReason }),
  setIsValidPromocode: isValidPromocode => set({ isValidPromocode }),
  setPromocode: promocode => set({ promocode }),
  setTreasury: treasuryPayload => set({ treasuryPayload }),
  setBPPhone: bpPhone => set({ bpPhone }),
  setSearchText: searchText => set({ searchText }),
  setPicReferralEmployees: (employeesToRefer, employeesReferred) => set({ employeesToRefer, employeesReferred }),
  resetPicReferral: () =>
    set({
      searchText: '',
      employeesToRefer: [],
      employeesReferred: [],
    }),
  setDefaultBank: defaultBank => set({ defaultBank }),
  setBankForTransfer: bankForTransfer => set({ bankForTransfer }),
  setIsSuspended: isSuspended => set({ isSuspended }),
  setLoanTerms: isLoanTermsAccepted => set({ isLoanTermsAccepted }),
  setCustomReasonText: customReasonText => set({ customReasonText }),
  setEditRefresh: editRefresh => set({ editRefresh }),
  setHeaderText: headerText => set({ headerText }),
  setReferralHeaderText: referralButtonText => set({ referralButtonText }),
  setTxnSuccessReferral: successPageBanners => set({ successPageBanners }),
  setAppliedReferral: appliedReferralCode => {
    if (appliedReferralCode) set({ referralValidated: true, validatedReferralCode: appliedReferralCode });
    else set({ referralValidated: false, validatedReferralCode: null });
  },
  setDeepLinkData: deepLinkData => set({ deepLinkData }),
  setReferralShortLink: referralShortLink => set({ referralShortLink }),
  share: () => {
    shareApiGeneric(get().employeeId, get().referralShortLink);
  },

  setMeAmplitudeContext: meAmplitudeContext => set({ meAmplitudeContext }),
  setForceLogout: () => set({ shouldForceLogout: true }),
  setAutoDisburseData: autoDisburseData => set({ autoDisburseData }),
  setSecurityPinOpen: securityPinOpen => set({ securityPinOpen }),
});

export default createSlice;
