import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { regularFont } from '@theme/theme';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { scale } from '@utils/normalize';
import { changeAmountWithFormatting, numberWithDots } from '@utils/utils';
import { View, Text } from 'native-base';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'react-native';
import { getAmountInputStyle } from './styles';

interface Errors {
  [key: string]: any;
}

interface AmountForWithdrawalProps {
  handleChange: (field: string) => (value: string) => void;
  errors?: Errors;
  amount?: string;
  source?: string;
}

export const onFocusHandler =
  (source: string): (() => void) =>
  () => {
    const e = AMPLITUDE_CONSTANTS.transfer.transfer_manual_input;
    const { name, attributes } = e;
    AmplitudeHelper.logEvent(name, {
      [attributes.source]: source,
      [attributes.transaction_category_id]: 'wd-02',
    });
  };

const AmountForWithdrawal: React.FC<AmountForWithdrawalProps> = ({
  handleChange,
  errors = {},
  amount = '',
  source = '',
}) => {
  const { t } = useTranslation('common');
  const { tenantSettings } = getStore();
  const { currencySymbol, currencySeperatorRegEx } = tenantSettings;
  const [inputValue, setInputValue] = useState(amount);

  const changeAmountCallBack = (val: string) => {
    changeAmountWithFormatting(currencySeperatorRegEx, () => {}, setInputValue)(val);
    handleChange('amount')(val.toString().replace(/\./g, ''));
  };

  return (
    <View style={appStyles.marginTop(16)}>
      <Text variant="sm-normal" color={colors.neutral.darkMist}>
        {t('bankTransfer.amountForTransfer')}
      </Text>
      <View style={getAmountInputStyle(errors)} _web={{ paddingY: scale(13) }}>
        <Text variant="md-normal" style={appStyles.marginLeft(16)}>
          {currencySymbol}
        </Text>
        <TextInput
          keyboardType="numeric"
          testID="txtInputAmount"
          value={numberWithDots(inputValue)}
          onChangeText={changeAmountCallBack}
          onFocus={onFocusHandler(source)}
          style={[appStyles.flex1, { fontFamily: regularFont, fontSize: scale(14), lineHeight: scale(21) }]}
        />
      </View>
    </View>
  );
};

export default AmountForWithdrawal;
