import { getStore } from '@store/storeUtils';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { Input, Text } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  getBackgroundColor,
  getBorderColorForInput,
  getDisabledColorForText,
  shouldGetCloseIcon,
  shouldShowError,
} from './loginPhoneInputUtils';

const phoneEvent = AMPLITUDE_CONSTANTS.authentication;

function LoginPhoneInput(props) {
  const {
    phoneNumber,
    setPhoneNumber,
    placeholderText,
    sError,
    isDisabled,
    isEditable,
    logParams,
    onPropsFocus,
    onPropsBlur,
  } = props;
  const { tenantSettings } = getStore();
  const { countryCode } = tenantSettings;
  const [isFocused, setIsFocused] = useState(false);

  const onFocusHandler = () => {
    setIsFocused(true);
    if (onPropsFocus) return onPropsFocus();
    AmplitudeHelper.logEvent(phoneEvent.phone_number_clicked.name, logParams, true);
  };

  const onEndEditingHandler = () => {
    if (onPropsBlur) return onPropsBlur();
    AmplitudeHelper.logEvent(phoneEvent.phone_number_filled.name, logParams, true);
  };

  return (
    <>
      <Input
        testID="loginPhoneInput"
        sentry-label="loginPhoneInput"
        backgroundColor={getBackgroundColor(isDisabled)}
        isDisabled={isDisabled}
        editable={isEditable}
        marginTop="4px"
        borderWidth="1px"
        variant="unstyled"
        _web={{
          _disabled: {
            opacity: 1,
          },
        }}
        _focus={{
          borderColor: getBorderColorForInput(sError, isFocused),
        }}
        InputLeftElement={
          <Text testID="txtCountryCode" color={getDisabledColorForText(isDisabled)} marginLeft="16px" variant="md-bold">
            +{countryCode}
          </Text>
        }
        color={getDisabledColorForText(isDisabled)}
        InputRightElement={shouldGetCloseIcon(isDisabled, phoneNumber, setPhoneNumber)}
        placeholder={placeholderText || '8123456789'}
        size="md"
        fontWeight="100"
        value={phoneNumber}
        keyboardType="number-pad"
        onFocus={onFocusHandler}
        onBlur={onEndEditingHandler}
        onChangeText={val => setPhoneNumber(val)}
        {...props}
      />
      {shouldShowError(sError)}
    </>
  );
}

LoginPhoneInput.propTypes = {
  phoneNumber: PropTypes.string,
  sError: PropTypes.string,
  isDisabled: PropTypes.bool,
  isEditable: PropTypes.bool,
  setPhoneNumber: PropTypes.func,
  logParams: PropTypes.shape({}),
  onPropsFocus: PropTypes.func,
  onPropsBlur: PropTypes.func,
};

LoginPhoneInput.defaultProps = {
  phoneNumber: '',
  sError: '',
  isDisabled: false,
  isEditable: true,
  setPhoneNumber: () => {},
  logParams: {},
  onPropsFocus: null,
  onPropsBlur: null,
};

export default LoginPhoneInput;
