import { analyticsTimeStampFormat } from '@root/constants';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { getNowInFormat } from '@utils/dateUtils';

interface LogCheckUncheckParams {
  transaction_category_id: string;
  amplitudeAccountName: string;
  tncChecked: boolean;
  validatedReferralCode: string;
  source: string;
}

export default function logCheckUncheck({
  transaction_category_id,
  amplitudeAccountName,
  tncChecked,
  validatedReferralCode,
  source,
}: LogCheckUncheckParams): void {
  const eventParams = {
    [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckbox.attributes.transaction_category_id]:
      transaction_category_id,
    [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckbox.attributes.timestamp]:
      getNowInFormat(analyticsTimeStampFormat),
    [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckbox.attributes.source]: source,
    [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckbox.attributes.dynamic_account_name]: amplitudeAccountName,
    [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckbox.attributes.referral_code]: validatedReferralCode,
  };

  if (!tncChecked) {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckbox.name, eventParams);
  } else {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckboxUnchecked.name, eventParams);
  }
}
