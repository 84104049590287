const ScreenNames = {
  LOGIN: 'Login',
  OTP: 'OTP',
  HOME: 'Home',
  TERMS_AND_CONDITION: 'TermsAndCondition',
  ENROLLED_PHONES: 'EnrolledPhones',
  SALARY_INFORMATION: 'SalaryInformation',
  BANK_LIST: 'BankList',
  SUB_PRODUCTS: 'SubProducts',
  NOMINALS: 'Nominals',
  GGPDAM: 'GGPdam',
  GG_PASCABAYAR: 'GGPascabayar',
  GG_TAGIHAN_PLN: 'GGTagihanPLN',
  GG_REGIONS: 'GGRegions',
  GG_VOUCHER_DETAILS: 'GGVoucherDetails',
  GG_BP_SUCCESS: 'GGBPSuccess',
  GG_CHAT_BOT: 'GGChatBot',
  GG_WITHDRAW_DETAILS: 'GGWithdrawDetails',
  GG_WITHDRAW_SUCCESS: 'GGWithdrawSuccess',
  GG_CREATE_PIN: 'GGCreatePin',
  GG_VERIFY_PIN: 'VerifyPin',
  GG_VERIFY_PIN_WITH_EXPIRY: 'GGVerifyPinWithExpiry',
  GG_ADD_PHONE_SCREEN: 'GGAddPhoneScreen',
  GG_EDIT_TRANSACTION: 'GGEditTransaction',
  GG_AUTO_DISBURSE_TERMS: 'GGAutoDisburseTerms',
  GG_PHONE_NUMBER_CHANGE: 'GGPhoneNumberChange',
  GG_PHONE_NUMBER_CHANGE_CONFIM: 'GGPhoneNumberChangeConfirm',
  REFERRAL_PAGE: 'ReferralPage',
  USER_PROFILE: 'UserProfile',
  DEPT_DESIGN_LIST: 'DeptDesignList',
  PIC_LISTING: 'PicListing',
  PIC_TERMS: 'PicTerms',
  GG_ADD_BANK_SCREEN: 'GGAddBankScreen',
  GG_BANK_TRANSFER: 'GGBankTransfer',
  GG_ALL_BANKS: 'GGAllBanks',
  HomeTab: 'HomeTab',
  TransactionsTab: 'TransactionsTab',
  Finansial: 'Finansial',
  PembayaranTab: 'PembayaranTab',
  ProfileTab: 'ProfileTab',
  GG_LOAN: 'GGLoan',
  GG_LOAN_INFO: 'GGLoanInfo',
  GG_GOLDWEBVIEW: 'GoldWebView',
  GG_LOANLISTING: 'LoanListing',
  GG_LOANDETAILS: 'LoanDetails',
  GG_LOANTERMS: 'LoanTerms',
} as const;

export type ScreenName = keyof typeof ScreenNames;
export default ScreenNames;
