import { Modal } from 'native-base';
import React from 'react';

interface ModalCloseProps extends React.ComponentProps<typeof Modal.CloseButton> { }

function ModalClose(props: ModalCloseProps): JSX.Element {
  return <Modal.CloseButton {...props} testID="modalClose" />;
}

export default ModalClose;
