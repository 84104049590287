import useFeatureFlag from '@customHooks/useFeatureFlag';
import BottomSheet from '@molecules/LoginInfoSheet';
import { NavigationProps } from '@navigation/types';
import { useNavigation } from '@react-navigation/native';
import { FEATURE_FLAGS, SECURITY_PIN_INFO_MODAL } from '@root/constants';
import { FeatureFlagRecord } from '@root/types';
import { PinRegister } from '@root/types/securityPin';
import { getStore } from '@store/storeUtils';
import { returnBottomSheetContent } from '@utils/utils';
import { View } from 'native-base';
import React, { useState } from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import profilePhoneChangeActiveBottomSheetHandler from './profilePhoneChangeRowUtils';

interface Props {
  childRef: React.MutableRefObject<any>;
}

const ProfilePhoneChangeRow: React.FC<Props> = (props: Props) => {
  const { childRef } = props;
  const navigation = useNavigation<NavigationProps>();
  const { pinRegister } = getStore();

  const [infoModal, setInfoModal] = useState({
    visible: false,
    data: returnBottomSheetContent(SECURITY_PIN_INFO_MODAL.NEED_CREATE_PIN, SECURITY_PIN_INFO_MODAL.NEED_CREATE_PIN),
    errorCode: 'NEED_CREATE_PIN',
  });
  const [isFeatureAvailable] = useFeatureFlag({
    featureName: [FEATURE_FLAGS.SECURITY_PIN, FEATURE_FLAGS.PHONE_NUMBER_CHANGE],
  });

  const { checkPin, onPrimaryPress, onCloseModal } = profilePhoneChangeActiveBottomSheetHandler({
    setInfoModal,
    infoModal,
    navigation,
    pinRegister: pinRegister as PinRegister,
    isFeatureAvailable: isFeatureAvailable as FeatureFlagRecord[],
  });

  return (
    <TouchableWithoutFeedback ref={childRef} testID="btnPressPhoneChange" onPress={() => checkPin()}>
      <View>
        <BottomSheet onPressPrimary={onPrimaryPress} loginInfo={infoModal} onClose={onCloseModal} />
      </View>
    </TouchableWithoutFeedback>
  );
};

export default ProfilePhoneChangeRow;
