import navigationConstants from '@navigation/navigationConstants';
import { NavigationProps } from '@navigation/types';
import { FEATURE_FLAGS, SECURITY_PIN_INFO_MODAL } from '@root/constants';
import { FeatureFlagRecord, ModalState } from '@root/types';
import { PinRegister } from '@root/types/securityPin';
import { returnBottomSheetContent } from '@utils/utils';
import type React from 'react';

interface ProfilePhonChangeUtilsParams {
  setInfoModal: React.Dispatch<React.SetStateAction<ModalState>>;
  infoModal: ModalState;
  navigation: NavigationProps;
  isFeatureAvailable: FeatureFlagRecord[];
  pinRegister: PinRegister;
}

export default function profilePhoneChangeActiveBottomSheetHandler(params: ProfilePhonChangeUtilsParams) {
  const { setInfoModal, infoModal, navigation, isFeatureAvailable, pinRegister } = params;

  const checkPin = async () => {
    if (
      !isFeatureAvailable[FEATURE_FLAGS.SECURITY_PIN]?.show ||
      !isFeatureAvailable[FEATURE_FLAGS.PHONE_NUMBER_CHANGE]?.show
    ) {
      return;
    }

    // If not Enrolled to Security PIN
    // Show Bottom Sheet
    if (!pinRegister.isActive) {
      return setInfoModal({
        data: returnBottomSheetContent(
          SECURITY_PIN_INFO_MODAL.NEED_CREATE_PIN,
          SECURITY_PIN_INFO_MODAL.NEED_CREATE_PIN,
        ),
        errorCode: 'NEED_CREATE_PIN`',
        visible: true,
      });
    }

    // If Enrolled, Fetch Security Open
    // It will give a token, this will be tracked how many attempts left
    if (pinRegister.isActive) {
      return navigation.navigate(navigationConstants.GG_VERIFY_PIN_WITH_EXPIRY, {
        type: 'verifyPinWithExpiry',
        source: 'phone_change',
        onVerifyCallBack: () =>
          navigation.navigate(navigationConstants.GG_PHONE_NUMBER_CHANGE, {
            source: 'phone_change',
          }),
      });
    }
  };

  const onPrimaryPress = () => {
    setInfoModal({
      ...infoModal,
      visible: false,
    });

    if (infoModal.errorCode !== 'GG_01_FALLBACK') {
      navigation.navigate(navigationConstants.GG_CREATE_PIN, {
        type: 'create',
        source: 'phone_change',
      });
    }
  };

  const onCloseModal = () => {
    setInfoModal({
      ...infoModal,
      visible: false,
    });
  };
  return { checkPin, onPrimaryPress, onCloseModal };
}
