import navigationConstants from '@navigation/navigationConstants';
import { NavigationProps } from '@navigation/types';
import { SECURITY_PIN_INFO_MODAL } from '@root/constants';
import { ModalState } from '@root/types';
import { formatPhoneNumber, goBackToHome, removeNonNumeric, returnBottomSheetContent } from '@utils/utils';
import type { TFunction } from 'i18next';
import type React from 'react';
import { PhoneChangePayload } from '.';

export function onUserInteractionHandlers(params: {
  navigation: NavigationProps;
  isError: string;
  infoModal: ModalState;
  setError: React.Dispatch<React.SetStateAction<string>>;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  t: TFunction;
  countryCode: string;
  securityPinOpen: { token: string };
  submitPhoneNumber: (payload: PhoneChangePayload) => void;
  otpType?: string;
  phoneNumber: string;
}) {
  const {
    // global
    navigation,
    t,
    countryCode,
    // states
    securityPinOpen,
    isError,
    infoModal,
    otpType,
    phoneNumber,
    // setters
    setError,
    setPhoneNumber,
    setIsDisabled,
    setIsLoading,
    // func
    submitPhoneNumber,
  } = params;

  const onChangePhoneNumber = text => {
    setIsLoading(true);
    if (isError) setError('');
    setPhoneNumber(removeNonNumeric(text));

    if (removeNonNumeric(text).substring(0, 2) === '00') {
      setIsDisabled(true);
      setError(t('login.invalidNumber'));
    }

    if (formatPhoneNumber(removeNonNumeric(text), countryCode).length >= 8) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
    setIsLoading(false);
  };

  const onSubmitPhoneNumber = () => {
    setIsDisabled(true);
    setIsLoading(true);
    return submitPhoneNumber({
      token: securityPinOpen.token,
      otpType: otpType || 'SMS',
      mobileNumber: formatPhoneNumber(phoneNumber, countryCode),
    });
  };

  const onPrimaryPressModal = () => {
    if (infoModal.errorCode === 'VERIFY_PIN_LOCKED') {
      goBackToHome(navigation);
    }
  };

  return { onChangePhoneNumber, onSubmitPhoneNumber, onPrimaryPressModal };
}

export function onAppEventHandlers(params: {
  navigation: NavigationProps;
  t: TFunction;
  setInfoModal: React.Dispatch<React.SetStateAction<ModalState>>;
  setError: React.Dispatch<React.SetStateAction<string>>;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isPhoneChangeError: boolean;
  isPhoneChangeSuccess: boolean;
  phoneChangeSuccessData: any;
  phoneChangeError: string[];
}) {
  const {
    navigation,
    t,
    setInfoModal,
    setError,
    setIsDisabled,
    setIsLoading,
    isPhoneChangeError,
    isPhoneChangeSuccess,
    phoneChangeSuccessData,
    phoneChangeError,
  } = params;

  const onErrorPhoneNumber = () => {
    if (isPhoneChangeError && phoneChangeError) {
      setIsLoading(false);
      setIsDisabled(false);
      if (phoneChangeError[0] === 'GG-PHONE-09' || phoneChangeError[0] === 'GG-PHONE-10') {
        return setInfoModal({
          visible: true,
          data: returnBottomSheetContent(
            SECURITY_PIN_INFO_MODAL.VERIFY_PIN_LOCKED,
            SECURITY_PIN_INFO_MODAL.VERIFY_PIN_LOCKED,
          ),
          errorCode: 'VERIFY_PIN_LOCKED',
        });
      }
      setError(t(`errorCodes.${phoneChangeError}`));
    }
  };

  const onSuccessPhoneNumber = () => {
    if (isPhoneChangeSuccess && phoneChangeSuccessData) {
      setIsLoading(false);
      setIsDisabled(false);
      return navigation.navigate(navigationConstants.GG_PHONE_NUMBER_CHANGE_CONFIM, {
        source: 'phone_change',
      });
    }
  };

  return { onErrorPhoneNumber, onSuccessPhoneNumber };
}
