import colors from '@theme/colors';
import { Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface TextWithValueProps {
  textToShow?: string;
  valueText?: string;
  onValuePress?: () => void;
  isValueHyperLink?: boolean;
  textSize?: string;
  valueSize?: string;
  textWeight?: string;
  valueWeight?: string;
  [key: string]: any; // For any additional props that might be spread
}

const TextWithValue: React.FC<TextWithValueProps> = ({
  textToShow = '',
  valueText = '',
  onValuePress = () => {},
  isValueHyperLink = false,
  textSize = 'md',
  valueSize = 'md',
  textWeight = '100',
  valueWeight = '100',
  ...props
}) => {
  const { t } = useTranslation('common');

  return (
    <Text variant={textSize} testID="txtPlain" sentry-label="txtPlain" fontWeight={textWeight} {...props}>
      {t(textToShow)}{' '}
      <Text
        testID="txtValue"
        sentry-label="txtValue"
        onPress={onValuePress}
        variant={valueSize}
        underline={isValueHyperLink}
        color={isValueHyperLink ? colors.secondary.orchid : colors.neutral.charcoal}
        fontWeight={valueWeight}>
        {t(valueText)}
      </Text>
    </Text>
  );
};

export default TextWithValue;
