// src/utils/firebase.js
import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyCIgtSIXCASf8h8q_HpMhqCYZad0iJmWL8',
  authDomain: 'pwa-stage-fd8a1.firebaseapp.com',
  projectId: 'pwa-stage-fd8a1',
  storageBucket: 'pwa-stage-fd8a1.firebasestorage.app',
  messagingSenderId: '123359503835',
  appId: '1:123359503835:web:e7bf78f28868b8c59eb3fe',
  measurementId: 'G-264M8D9CTP',
};

const app = initializeApp(firebaseConfig);
const analytics = typeof window !== 'undefined' ? getAnalytics(app) : null;

export { analytics, logEvent, setUserId, setUserProperties };

// const firebaseConfig = {
//   apiKey: "AIzaSyBDf0uzTMNVpAPVHgFJzqu9_sUI3ijj9Zc",
//   authDomain: "pwa-prod-aa32a.firebaseapp.com",
//   projectId: "pwa-prod-aa32a",
//   storageBucket: "pwa-prod-aa32a.firebasestorage.app",
//   messagingSenderId: "805104467100",
//   appId: "1:805104467100:web:e5baaf38daa7136cd08159",
//   measurementId: "G-E11ZZLZREF"
// };
