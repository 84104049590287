import colors from '@theme/colors';
import { SVG_ICONS } from '@utils/SvgIcons';

export const DRAWER_NAVIGATION_TYPES = {
  navigation: 'navigation',
  link: 'link',
  logout: 'logout',
};

const sVoucherText1 =
  'Jika kode voucher belum diterima via SMS setelah 3 jam transaksi, pelanggan harap menghubungi Layanan Bantuan GajiGesa';
const sVoucherText2 =
  'Pelanggan akan mendapatkan kode voucher melalui SMS setelah pembelian voucher digital berhasil melalui aplikasi GajiGesa.';
const sVoucherText3 = 'Tim GajiGesa berhak melakukan pembatalan jika menemukan adanya kecurangan atau penipuan.';
const sVoucherText4 =
  'GajiGesa berhak untuk mengubah syarat dan ketentuan yang berlaku tanpa pemberitahuan terlebih dulu.';
const sVoucherText5 = 'Masukkan lokasi penjemputan, lalu klik Promo.';
const sVoucherText6 = 'Masukkan kode voucher dan klik Use Now.';
const sVoucherText7 = 'Jumlah tarif akan dipotong sesuai dengan voucher yang dipilih.';
const sVoucherText8 = 'Masa berlaku voucher: 14 (empat belas) hari setelah kode diaktifkan';
const sVoucherText9 = 'Pembelian kode voucher yang telah berhasil tidak bisa dibatalkan.';
const sVoucherText10 =
  'Jika harga transaksi lebih besar dari nominal voucher, maka sisa pembayaran dapat dilakukan dengan metode pembayaran tunai atau GoPay.';
const sVoucherText11 = 'Penggunaan voucher tidak dapat digabungkan dengan voucher lainnya.';
const sVoucherText12 =
  'Tim GajiGesa berhak melakukan pembatalan transaksi voucher jika menemukan adanya kecurangan atau penipuan.';
const sVoucherText13 =
  'Dengan membeli voucher ini, pengguna dianggap mengerti dan menyetujui semua syarat & ketentuan berlaku.';
const sVoucherText14 = 'Syarat dan ketentuan dapat berubah sewaktu-waktu tanpa pemberitahuan terlebih dahulu.';
const sVoucherText15 = 'Voucher tidak dapat digabungkan dengan promo lainnya.';
const sVoucherText16 =
  'Jika ada kendala Voucher Gojek, pengguna bisa langsung mengakses laman Bantuan, lalu profile menu dan klik Bantuan > pilih Section menu Promo, dan customer bisa mengajukan kode promo yang tidak bisa digunakan. Atau, email langsung ke customerservice@go-jek.com.';
const sVoucherText17 =
  'Jika pengguna mengalami kendala pada kode voucher, harap tunggu respon balasan dari Tim CS Gojek, karena Gojek akan langsung melakukan pengecekan kendala pada kode voucher tersebut.';
const sVoucherText18 =
  'Estimasi penyelesaian kendala adalah maks. 2 x 24 jam terhitung sejak pengguna complain melalui customerservice@go-jek.com.';
const sVoucherText19 = 'Masukkan kode voucher dan klik Apply Code.';
const sVoucherText20 = 'Promo hanya berlaku dengan pembelian minimal Rp100.000.';
const sVoucherText21 =
  'Kode hanya dapat digunakan untuk 1 (satu) kali pemakaian untuk 1 (satu) akun yang terdaftar di aplikasi Hangry.';
const sVoucherText22 =
  'Pembelian hanya berlaku di aplikasi Hangry (delivery order) dan tidak bisa digunakan pada aplikasi pesan makan online lainnya, seperti GrabFood atau GoFood.';
const sVoucherText23 = 'Voucher hanya dapat digunakan di DKI Jakarta, Bekasi, dan Tangerang.';
const sVoucherText24 =
  'Pihak Hangry berhak untuk membatalkan transaksi bila pengguna voucher tidak sesuai dengan syarat dan ketentuan yang berlaku.';
const sVoucherText25 = 'Download Hangry Apps di perangkat iOS atau Android kamu.';
const sVoucherText26 =
  'Lakukan sign up untuk pengguna baru atau log in dengan nomor hp yang terhubung dengan akun Hangry-mu.';
const sVoucherText27 =
  'Klik "Pakai Kupon Diskon" yang terdapat di atas tombol "Bayar" untuk masuk ke halaman Voucher. Kamu akan masuk ke dalam daftar voucher yang kamu miliki.';
const sVoucherText28 = 'Masukkan kode voucher yang akan kamu dapatkan setelah membeli voucher ini.';
const sVoucherText29 =
  'Voucher akan otomatis tertampil setelah kamu memasukkan kode voucher. Klik "Pakai". Jika berhasil, total biaya belanja kamu akan otomatis terpotong senilai nominal voucher yang dipilih.';
const sVoucherText30 = 'Klik "Bayar" dan pilih metode pembayaran yang diinginkan (OVO, Gopay, Dana).';
const sVoucherText31 = 'Selesai! Selamat menikmati produk Hangry!';
const sVoucherText32 = 'Masa berlaku voucher: 29 hari setelah pembelian';
const sVoucherText33 = 'Voucher berlaku setiap hari, termasuk hari libur nasional.';
const sVoucherText34 = 'Voucher sudah termasuk pajak dan layanan.';
const sVoucherText35 =
  'Voucher tidak bisa digunakan untuk menu promo dan tidak dapat digabungkan dengan promo lainnya.';
const sVoucherText36 = 'Lakukan transaksi di store yang sudah Anda pilih.';
const sVoucherText37 = 'Pastikan Anda menukarkan e-voucher saat masih berlaku.';
const sVoucherText38 =
  'Klik link e-voucher yang dikirimkan melalui app GajiGesa/SMS, lalu tunjukkan ke kasir. Apabila e-voucher yang dikirimkan berupa kode unik dan bukan link, tunjukan kode unik tersebut ke kasir.';
const sVoucherText39 =
  'Selesai! Anda tidak harus membayar ke kasir, kecuali jika pembelian produk melebihi nilai e-voucher Anda.';
const sVoucherText40 =
  'Pelanggan akan mendapatkan redeem link atau kode voucher melalui SMS setelah pembelian voucher digital melalui aplikasi GajiGesa';
const sVoucherText41 =
  'Redeem link yang diberikan hanya dapat diakses satu kali, sehingga pelanggan perlu memastikan untuk hanya menggunakan redeem code tersebut saat akan melakukan pembayaran menggunakan voucher di merchant tersebut';
const sVoucherText42 = 'Disarankan untuk menukarkan voucher maksimal 7 hari setelah pembelian voucher';
const sVoucherText43 =
  'Masa validasi kode voucher setelah dibuka linknya hanya 15 menit. Pastikan untuk menggunakannya saat akan melakukan pembayaran di merchant';
const sVoucherText44 = 'Kode voucher yang sudah ditukarkan tidak dapat digunakan kembali.';
const sVoucherText45 =
  'Voucher tidak dapat diuangkan. Jika total transaksi lebih kecil daripada nominal voucher, maka sisa nilai voucher akan hangus.';
const sVoucherText46 = 'Voucher tidak dapat diuangkan';
const sVoucherText47 =
  'Apabila dalam kurun waktu 3 jam setelah pelanggan berhasil melakukan pembelian voucher namun pelanggan belum menerima kode voucher melalui SMS pada nomor terdaftar di GajiGesa, pelanggan dapat menghubungin Contact Center GajiGesa';

export const COMMON_CONSTANTS = {
  support: 'https://api.whatsapp.com/send?&phone=628111778961',
  ytLink: 'https://youtube.com/playlist?list=PLK-ft83oaMqaYDwzwJJh0DNx7WpI-qURK',
  playstoreLink: 'https://play.google.com/store/apps/details?id=com.gajitimnative',
  networkError: 'Network Error',
  bankTransferSupport: 'https://bit.ly/verifikasibank_gajigesa',
};

export const USER_ROLES = {
  employeeWithGTEmployer: 'gajitim employer',
};

export const PAYSLIP = {
  EMPLOYEE_NAME: 'EMPLOYEE_NAME',
  PHONE_NUMBER: 'PHONE_NUMBER',
  BANK: 'BANK',
  ACCOUNT_NUMBER: 'ACCOUNT_NUMBER',
  SALARY_AND_PAYMENT: 'SALARY_AND_PAYMENT',
  SALARY_PAYMENTS: 'SALARY_PAYMENTS',
  ATTENDANCE_HISTORY: 'ATTENDANCE_HISTORY',
  DATE: 'DATE',
  DESCRIPTION: 'DESCRIPTION',
  TOTAL: 'TOTAL',
  TITLE2: 'ATTENDANCE',
  NOTE: 'NOTE',
  WORKING_HOURS: 'WORKING_HOURS',
  SALARY: 'SALARY',
  ADDITION: 'ADDITION',
  DEDUCTION: 'DEDUCTION',
  BASE_SALARY: 'BASE_SALARY',
  TOTAL_ADDITION: 'TOTAL_ADDITION',
  BONUS: 'BONUS',
  OVERTIME: 'OVERTIME',
  TOTAL_DEDUCTION: 'TOTAL_DEDUCTION',
  NETT_SALARY: 'NETT_SALARY',
  PAID_SALARY: 'PAID_SALARY',
  OUTSTANDING_SALARY: 'OUTSTANDING_SALARY',
  ATTENDANCE: 'ATTENDANCE',
  ABSENT: 'ABSENT',
  PAID_LEAVE: 'PAID_LEAVE',
  LATE: 'LATE',
  DAY_OFF: 'DAY_OFF',
  PRESENT: 'PRESENT',
  PENDING: 'PENDING',
  OUTSTANDING: 'OUTSTANDING',
  PAID: 'PAID',
  LATE_FINE: 'LATE_FINE',
  HOLIDAY: 'HOLIDAY',
  PAYMENT_FROM_GAJIGESA: 'PAYMENT_FROM_GAJIGESA',
  PAYMENT_FROM_GAJITIM: 'PAYMENT_FROM_GAJITIM',
  OVERPAID: 'OVERPAID',
};

export const BOTTONSHEET_PHONE_ENROLLMENT = {
  PHONE_DELETE: 'PHONE_DELETE',
  MAX_LIMIT: 'MAX_LIMIT',
  DUPLICATE_ENTRY: 'DUPLICATE_ENTRY',
};

export const BOTTONSHEET_PHONE_CONTENT = {
  [BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE]: {
    title: 'PHONE_DELETE_TITLE',
    description: 'PHONE_DELETE_DESCRIPTION',
    buttonText: 'PHONE_DELETE_BUTTON_TEXT1',
    buttonText2: 'PHONE_DELETE_BUTTON_TEXT2',
    hasTwoButtons: true,
    icon: SVG_ICONS.deleteModalIcon,
  },
  [BOTTONSHEET_PHONE_ENROLLMENT.MAX_LIMIT]: {
    title: 'PHONE_MAX_LIMIT_TITLE',
    description: 'PHONE_MAX_LIMIT_DESCRIPTION',
    buttonText: 'PHONE_MAX_LIMIT_BUTTON_TEXT',
    hasTwoButtons: false,
    icon: SVG_ICONS.bottomSheet.logout,
  },
  [BOTTONSHEET_PHONE_ENROLLMENT.DUPLICATE_ENTRY]: {
    title: 'PHONE_DUPLICATE_ENTRY',
    description: 'PHONE_DUPLICATE_ENTRY_DESCRIPTION',
    buttonText: 'PHONE_MAX_LIMIT_BUTTON_TEXT',
    hasTwoButtons: false,
    icon: SVG_ICONS.duplicateModalIcon,
  },
};

export const TOOLTIP_CONTENT = {
  balance: 'Uang Kamu adalah Total Pendapatan - Total Pengeluaran',
  totalAmount: 'Total Pendapatan adalah Gaji Pro Rata + Tunjangan, Bonus, dan Komisi.',
  referral:
    'Masukkan kode referral dari temanmu sekantor dan dapatkan untung GajiGesa Poin setelah transaksi pertamamu',
  reward: 'Kode referral berhasil dipakai',
  rewardFailed: 'Kode referral gagal dipakai. Transaksi ini tidak mendapatkan hadiah poin.',
};

export const TERMS = {
  first: 'Apabila ada, saya bersedia untuk membayar biaya yang muncul atas penggunaan layanan GajiGesa.',
  second:
    'Saya menyetujui biaya layanan GajiGesa akan dikurangi secara langsung dari jumlah dana yang saya ajukan dan saya akan menerima dana di rekening saya sejumlah yang telah dikurangi biaya layanan yang muncul.',
  third:
    'Saya menyetujui gaji yang akan saya terima dari perusahaan pada tanggal gajian akan dipotong sejumlah total transaksi yang saya lakukan melalui layanan GajiGesa.',
};

export const LIST_TYPES = {
  SIMPLE_LIST: 'SIMPLE_LIST',
  COLUMN_LIST: 'COLUMN_LIST',
  MULTICOLUMN_LIST: 'MULTICOLUMN_LIST',
  NO_LIST: 'NO_LIST', // Does not have any of the list mentioned above
  NO_COMPONENT: 'NO_COMPONENT', // Does not render any react component
};

export const POSTPAID_VALIDATION_VALUE = {
  'BP-06': {
    value: 185000,
  },
  'BP-17': {
    value: 300000,
  },
  'BP-08': {
    value: 235000,
  },
};

export const BP_CATEGORY_NAMES = {
  ISI_ULANG: 'Isi Ulang',
  TAGIHAN: 'Tagihan',
  PROMO: 'Promo',
  KEBUTUHAN_SEHARI_HARI: 'Kebutuhan Sehari-hari',
};

export const FEATURE_FLAGS = {
  NEW_BILLPAYMENT: 'NEW_BP        ',
  REFERRAL: 'REFERRAL      ',
  APPTOUR: 'APPTOUR       ',
  DYNAMIC_ACCOUNTS_POINS: 'D_ACC_POINS   ',
  GAMIFICATION: 'GAMIFICATION  ',
  SAVINGS_HOME: 'SAVINGS_HOME  ',
  STORIES: 'STORIES       ',
  BP_HOMEPAGE: 'BP_HOMEPAGE   ',
  BP_POINTS: 'BP_POINTS     ',
  SAV_OFFER: 'SAV_OFFER     ',
  WD_TOUR: 'WD_TOUR       ',
  REFERRAL_TOUR: 'REFERRAL_TOUR ',
  PULSA_TOUR: 'PULSA_TOUR    ',
  POP_PERMISSION: 'POP_PERMISSION',
  LOAN: 'LOAN          ',
  MUL_BANK_ACC: 'MUL_BANK_ACC  ',
  PHONE_ENROLL: 'PHONE_ENROLL  ',
  BANK_SEL_TRNSF: 'BANK_SEL_TRNSF',
  ZAKAT: 'ZAKAT         ',
  EWA_MODAL: 'EWA_MODAL     ',
  SALARY_INFO: 'SALARY_INFO   ',
  SECURITY_PIN: 'SECURITY_PIN  ',
  CHAT_GPT: 'CHAT_GPT      ',
  GOLD_SAVINGS: 'GOLD_SAVINGS  ',
  AUTODISBURSE: 'AUTODISBURSE  ',
  PIC_REFERRAL: 'PIC_REFER     ',
  PHONE_NUMBER_CHANGE: 'PHONE_N_CHANGE',
};

export const PWA_REFFERAL_LINK = 'https://gajigesa.com/gajigesa-referral';
export const LOAN_READ_LINK = 'https://gajigesa.com/e-Kasbon';

export const UPDATE_TYPE = {
  OPTIONAL: 'OPTIONAL',
  MANDATORY: 'MANDATORY',
  NO_UPDATE: 'NO_UPDATE',
};

export const BP_TOP_TAB_LABEL = {
  SALARY_ACCOUNT: 'Gaji Prorata',
  POINT: 'Poin',
};

export const STORIES_ROUTE = 'Stories/StoriesData.json';

export const TERMS_AND_CONDITIONS_ROUTE = 'TermsAndCondition/termsandCondition.json';

export const REWARD_INFO_ROUTE = 'RewardInfo/RewardInfo.json';

export const DYNAMIC_ACCOUNTS_TYPE = {
  EWA: 'EWA',
  POINTS: 'POINTS',
  GOLD: 'GOLD',
};

export const ERROR_CODES = {
  'GG-OTP-08': 'GG-OTP-08',
  'GG-500': 'GG-500',
  'GG-4291': 'GG-4291',
  'GG-EMP-02': 'GG-EMP-02',
  'GG-4032': 'GG-4032',
  'GG-4033': 'GG-4033',
  'GG-OTP-07': 'GG-OTP-07',
  'GG-OTP-01': 'GG-OTP-01',
  'GG-409': 'GG-409',
  'GG-4091': 'GG-4091',
};

export const LOGIN_INFO_SHEET_KEYS = {
  'GG-OTP-08': 'LIMIT_EXCEED',
  'GG-500': 'OTHER_ERROR',
  'GG-EMP-02': 'SUSPEND_REASON1',
  'GG-4032': 'SUSPEND_REASON2',
  'GG-4033': 'SUSPEND_REASON3',
  'GG-OTP-01': 'NOT_REGISTERED',
  'GG-OTP-07': 'NOT_REGISTERED',
  'GG-401': 'EXPIRED_OTP',
  'GG-410': 'INVALID_OTP',
  'GG-4291': 'TOO_MANY_REQUEST_OTP',
  IN_PROCESS: 'IN_PROCESS',
  REGISTERED_USER: 'REGISTERED_USER',
  RESEND_OTP: 'RESEND_OTP',
  LOGOUT: 'LOGOUT',
};

export const BOTTONSHEET_KEYS = {
  BANK_DELETE: 'BANK_DELETE',
  MAX_LIMIT: 'MAX_LIMIT',
  DUPLICATE_ENTRY: 'DUPLICATE_ENTRY',
};

export const MESSAGGE_TYPE = {
  SMS: 'SMS',
  WHATSAPP: 'WHATSAPP',
};

export const CHECK_DATA_DROPDOWN_LIST = [
  {
    text: 'ID Karyawan',
    placeHolder: 'Masukkan ID Karyawan',
    isSelected: false,
    identityType: 'employee-id',
  },
  {
    text: 'KTP (Kartu Tanda Penduduk)',
    placeHolder: 'Masukkan Nomor KTP Anda',
    isSelected: false,
    identityType: 'national-id',
  },
];

export const MISSION_STATUS = {
  COMPLETED: 'COMPLETED',
  ON_GOING: 'IN_PROGRESS',
};

export const MISSION_GOAL_TYPE = {
  TOTAL_COUNT: 'TOTAL_COUNT',
  TOTAL_AMOUNT: 'TOTAL_AMOUNT',
};

export const TIER_STATUS_TYPE = {
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS',
  DISABLED: 'DISABLED',
};

export const WELCOME_DATA = [
  {
    srNo: 0,
    cardColor: colors.secondary.orchid,
    textContext: 'Tarik gajimu lebih awal kapan dan di mana pun. \nTanpa bunga dan, pastinya, bukan pinjaman',
    circleGradient: ['rgba(44, 190, 150, 0.9)', 'rgba(44, 190, 150, 0.2)', 'rgba(44, 190, 150 , 0)'],
  },
  {
    srNo: 1,
    cardColor: colors.primary.carnation,
    textContext: 'Bayar tagihanmu lebih mudah dan \ntop-up saldo pulsa atau e-wallet lebih praktis',
    circleGradient: ['rgba(248, 178, 79, 0.9)', 'rgba(248, 178, 79, 0.2)', 'rgba(248, 178, 79, 0)'],
  },
  {
    srNo: 2,
    cardColor: colors.variants.clover,
    textContext: 'Bikin transaksi kebutuhan harianmu \nmakin hemat dengan tukar hadiah poin!',
    circleGradient: ['rgba(49, 91, 231, 0.5)', 'rgba(49, 91, 231, 0.2)', 'rgba(49, 91, 231, 0)'],
  },
];

export const MILVIK_CONST = [
  {
    title: 'Apa itu Milvik?',
    data: [
      {
        info: [
          'Milvik adalah telekonsultasi kesehatan terjangkau, tanpa batas selama 24 jam untuk keluarga. Dengan membeli voucher seharga Rp15.000-, para pengguna bisa langsung berkonsultasi dengan dokter secara online melalui WhatsApp.',
        ],
        subTitle: '',
        bulleted: false,
      },
    ],
  },

  {
    title: 'Cara Reedem Voucher',
    data: [
      {
        subTitle: 'Cara pertama:',
        info: [
          'Jika pembelian voucher sudah berhasil. Pilih Lihat Voucher Saya',
          'Salin Kode Voucher Telekonsultasi. Lalu, pilih WhatsApp Chat',
          'Pengguna akan dialihkan ke laman WhatsApp Milvik, lalu pilih Konsultasi Dokter (Pengguna Voucher Milvik Dokter)',
        ],
        bulleted: true,
      },
      {
        subTitle: 'Cara kedua:',
        info: [
          'Buka aplikasi GajiGesa > Fitur Pembayaran',
          'Pilih Telekonsultasi di bagian Kesejahteraan',
          'Pada halaman Voucher Saya, salin Kode Voucher',
          'Pilih Konsultasi',
          'Pengguna akan dialihkan ke laman WhatsApp Milvik, lalu pilih Konsultasi Dokter (Pengguna Voucher Milvik Dokter)',
        ],
        bulleted: true,
      },
    ],
  },
  {
    title: 'Yang Kamu Dapatkan',
    data: [
      {
        subTitle: '',
        info: ['1x Telekonsultasi chat 24/7 dengan dokter umum.'],
        bulleted: false,
      },
    ],
  },
  {
    title: 'Syarat dan Ketentuan',
    data: [
      {
        subTitle: '',
        info: [
          'Transaksi pembelian voucher konsultasi dokter online dengan Milvik hanya bisa dilakukan melalui aplikasi GajiGesa.',
          'Harga voucher adalah senilai Rp15.000. Pembelian bisa dilakukan dengan gaji prorata pada EWA atau GajiGesa Poin.',
          'Sebelum melakukan konsultasi, voucher harus ditukar terlebih dahulu via layanan WhatsApp Milvik.',
          'Konsultasi dokter online akan dilakukan via WhatsApp.',
          'Voucher hanya bisa ditukar apabila kode masih valid dan belum pernah digunakan.',
          'Voucher konsultasi dokter online dengan Milvik hanya berlaku selama 3 bulan setelah pembelian.',
          'Penggunaan voucher Milvik harus mengikuti syarat dan ketentuan dalam aplikasi GajiGesa.',
          'Voucher yang sudah kamu beli tak bisa dikembalikan.',
          'Komentar, pertanyaan, serta keluhan mengenai konsultasi dokter online dengan Milvik dan/atau Syarat dan Ketentuan ini, ditujukan ke Layanan Customer Support GajiGesa.',
          'GajiGesa berhak mengubah syarat dan ketentuan yang berlaku tanpa pemberitahuan terlebih dulu.',
          'Dengan membeli voucher Milvik, kamu dianggap telah setuju dan membaca semua syarat dan ketentuan berlaku.',
        ],
        bulleted: true,
      },
    ],
  },
];

export const VOUCHER_INV_STATUS = {
  AVAILABLE: 'AVAILABLE',
  PURCHASED: 'PURCHASED',
  REDEEMED: 'REDEEMED',
  DELETED: 'DELETED',
  EXPIRED: 'EXPIRED',
};

export const VOUCHER_INV_ASSETS = {
  [VOUCHER_INV_STATUS.AVAILABLE]: {
    voucherColor: colors.secondary.orchid,
    ctaText: 'Salin',
    leftCircleStyle: { borderColor: colors.secondary.orchid },
  },
  [VOUCHER_INV_STATUS.PURCHASED]: {
    voucherColor: colors.secondary.orchid,
    ctaText: 'Salin',
    leftCircleStyle: { borderColor: colors.secondary.orchid },
  },
  [VOUCHER_INV_STATUS.REDEEMED]: {
    voucherColor: colors.neutral.darkMist,
    ctaText: 'Terpakai',
    leftCircleStyle: { borderColor: colors.neutral.darkMist },
  },
  [VOUCHER_INV_STATUS.DELETED]: {
    voucherColor: colors.secondary.orchid,
    ctaText: 'Salin',
    leftCircleStyle: { borderColor: colors.secondary.orchid },
  },
  [VOUCHER_INV_STATUS.EXPIRED]: {
    voucherColor: colors.secondary.orchid,
    ctaText: 'Salin',
    leftCircleStyle: { borderColor: colors.secondary.orchid },
  },
};

export const OTHER_ACCOUNT_NAMES = {
  SAVINGS: 'SAVINGS',
};

export const DYNAMIC_ACCOUNT_NAMES = {
  SALARY_ACCOUNT: 'SALARY_ACCOUNT', // This is not a dynamic account in our system but is a core account
  SDACCOUNT: 'SALARY_DISBURSEMENT',
  POINTS: 'POINTS',
  WORK_ALLOWANCE: 'UANG_KANTOR',
  UANG_KAS: 'UANG_KAS',
};

export const TRANSACTION_LIST_DYNAMIC_ACCOUNTS = [
  DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT,
  DYNAMIC_ACCOUNT_NAMES.POINTS,
  DYNAMIC_ACCOUNT_NAMES.SDACCOUNT,
  DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE,
  DYNAMIC_ACCOUNT_NAMES.UANG_KAS,
];
export const HOMEPAGE_DYNAMIC_ACCOUNTS = [
  DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT,
  DYNAMIC_ACCOUNT_NAMES.SDACCOUNT,
  DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE,
  DYNAMIC_ACCOUNT_NAMES.UANG_KAS,
];

export const BP_DYNAMIC_ACCOUNTS = [
  DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT,
  DYNAMIC_ACCOUNT_NAMES.POINTS,
  DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE,
  DYNAMIC_ACCOUNT_NAMES.UANG_KAS,
];

export const TRANSACTION_SCREEN_ACCOUNT_NAME = {
  'Salary Disbursement': 'Pencairan Gaji',
  POINTS: 'Poin',
  GajiGesa: 'GajiGesa',
  'Uang Kantor': 'UANG_KANTOR',
};

export const TRANSACTION_TYPE = {
  EXPENSE: 'EXPENSE',
  INCOME: 'INCOME',
};

export const TRANSACTION_DETAILS_KEYS = {
  transRequestedAmount: 'Jumlah Penarikan',
  transFee: 'Biaya Admin',
  transReceivedAmount: 'Jumlah Yang diterima',
  transAmount: 'Nominal',
  transDate: 'Tanggal',
  moneyAccount: 'Kantong Dana',
  displayName: 'Transaksi',
  transStatus: 'transStatus',
  referenceNumber: 'No. Referensi',
  otherMobileNumber: 'Nomor HP',
  otherOwnerName: 'Nama',
  bankName: 'Nama Bank Penerima',
  bankAccountNumber: 'Nomor Rekening Penerima',
  beneficiaryName: 'Nama Pemilik Rekening',
  donatorCount: 'Jumlah Donatur',
  donatorName: 'Donator',
  email: 'Email',
  totalPembayaran: 'Total Pembayaran',
};

/*
[Account Name]:{
  [reasonId/default(if not avail)]:{
    [detailsKey (key which is recieved from BE on transactionList api)]:{
      name:'Name of the key you want to show on the UI',
      priority:'To decide which details comes first',
      isAmount:'Decides if the key is an amount',
      isDate:'Decides if the key is a Date'
    }
  }
}
*/

const ggTransactionConfig = {
  default: {
    displayName: {
      name: TRANSACTION_DETAILS_KEYS.displayName,
      priority: 1,
    },
    bankName: {
      name: TRANSACTION_DETAILS_KEYS.bankName,
      priority: 2,
    },
    bankAccountNumber: {
      name: TRANSACTION_DETAILS_KEYS.bankAccountNumber,
      priority: 3,
    },
    beneficiaryName: {
      name: TRANSACTION_DETAILS_KEYS.beneficiaryName,
      priority: 4,
    },
    transRequestedAmount: {
      name: TRANSACTION_DETAILS_KEYS.transRequestedAmount,
      priority: 5,
      isAmount: true,
    },
    transFee: {
      name: TRANSACTION_DETAILS_KEYS.transFee,
      priority: 6,
      isAmount: true,
    },
    transReceivedAmount: {
      name: TRANSACTION_DETAILS_KEYS.transReceivedAmount,
      priority: 7,
      isAmount: true,
    },
    transDate: {
      name: TRANSACTION_DETAILS_KEYS.transDate,
      priority: 10,
      isDate: true,
    },
    otherMobileNumber: {
      name: TRANSACTION_DETAILS_KEYS.otherMobileNumber,
      priority: 8,
      isAmount: false,
    },
    otherOwnerName: {
      name: TRANSACTION_DETAILS_KEYS.otherOwnerName,
      priority: 9,
      isAmount: false,
    },
    moneyAccount: {
      name: TRANSACTION_DETAILS_KEYS.moneyAccount,
      priority: 11,
    },
  },
  34: {
    // Comission
    transAmount: {
      name: TRANSACTION_DETAILS_KEYS.transAmount,
      priority: 1,
      isAmount: true,
    },
    transDate: {
      name: TRANSACTION_DETAILS_KEYS.transDate,
      priority: 2,
      isDate: true,
    },
    moneyAccount: {
      name: TRANSACTION_DETAILS_KEYS.moneyAccount,
      priority: 3,
    },
  },
  19: {
    displayName: {
      name: TRANSACTION_DETAILS_KEYS.displayName,
      priority: 1,
    },
    donatorCount: {
      name: TRANSACTION_DETAILS_KEYS.donatorCount,
      priority: 2,
      isDonatorCount: true,
    },

    donatorName: {
      name: TRANSACTION_DETAILS_KEYS.donatorName,
      priority: 3,
    },
    email: {
      name: TRANSACTION_DETAILS_KEYS.email,
      priority: 4,
    },

    transAmount: {
      name: TRANSACTION_DETAILS_KEYS.totalPembayaran,
      priority: 5,
      isAmount: true,
    },

    transDate: {
      name: TRANSACTION_DETAILS_KEYS.transDate,
      priority: 6,
      isDate: true,
    },

    moneyAccount: {
      name: TRANSACTION_DETAILS_KEYS.moneyAccount,
      priority: 7,
    },
  },
};

export const TRANSACTION_DETAIL_CONFIG = {
  SALARY_DISBURSEMENT: {
    default: {
      transRequestedAmount: {
        name: TRANSACTION_DETAILS_KEYS.transRequestedAmount,
        priority: 1,
        isAmount: true,
      },
      transFee: {
        name: TRANSACTION_DETAILS_KEYS.transFee,
        priority: 2,
        isAmount: true,
      },
      transReceivedAmount: {
        name: TRANSACTION_DETAILS_KEYS.transReceivedAmount,
        priority: 3,
        isAmount: true,
      },
      transDate: {
        name: TRANSACTION_DETAILS_KEYS.transDate,
        priority: 4,
        isDate: true,
      },
      moneyAccount: {
        name: TRANSACTION_DETAILS_KEYS.moneyAccount,
        priority: 4,
      },
    },
    39: {
      transReceivedAmount: {
        name: TRANSACTION_DETAILS_KEYS.transReceivedAmount,
        priority: 1,
        isAmount: true,
      },
      transDate: {
        name: TRANSACTION_DETAILS_KEYS.transDate,
        priority: 2,
        isDate: true,
      },
      moneyAccount: {
        name: TRANSACTION_DETAILS_KEYS.moneyAccount,
        priority: 3,
      },
    },
    38: {
      transRequestedAmount: {
        name: 'Jumlah penyesuaian',
        priority: 1,
        isAmount: true,
      },
      transDate: {
        name: TRANSACTION_DETAILS_KEYS.transDate,
        priority: 2,
        isDate: true,
      },
      moneyAccount: {
        name: TRANSACTION_DETAILS_KEYS.moneyAccount,
        priority: 3,
      },
    },
  },
  POINTS: {
    default: {
      displayName: {
        name: TRANSACTION_DETAILS_KEYS.displayName,
        priority: 1,
      },
      transRequestedAmount: {
        name: TRANSACTION_DETAILS_KEYS.transRequestedAmount,
        priority: 2,
        isAmount: true,
      },
      transFee: {
        name: TRANSACTION_DETAILS_KEYS.transFee,
        priority: 3,
        isAmount: true,
      },
      transReceivedAmount: {
        name: TRANSACTION_DETAILS_KEYS.transReceivedAmount,
        priority: 4,
        isAmount: true,
      },
      transDate: {
        name: TRANSACTION_DETAILS_KEYS.transDate,
        priority: 5,
        isDate: true,
      },
      moneyAccount: {
        name: TRANSACTION_DETAILS_KEYS.moneyAccount,
        priority: 6,
      },
    },
  },
  GajiGesa: ggTransactionConfig,
  UANG_KAS: ggTransactionConfig,
  UANG_KANTOR: {
    default: {
      transRequestedAmount: {
        name: TRANSACTION_DETAILS_KEYS.transRequestedAmount,
        priority: 1,
        isAmount: true,
      },
      transFee: {
        name: TRANSACTION_DETAILS_KEYS.transFee,
        priority: 2,
        isAmount: true,
      },
      transReceivedAmount: {
        name: TRANSACTION_DETAILS_KEYS.transReceivedAmount,
        priority: 3,
        isAmount: true,
      },
      transDate: {
        name: TRANSACTION_DETAILS_KEYS.transDate,
        priority: 4,
        isDate: true,
      },
      moneyAccount: {
        name: TRANSACTION_DETAILS_KEYS.moneyAccount,
        priority: 4,
      },
    },
    40: {
      referenceNumber: {
        name: TRANSACTION_DETAILS_KEYS.referenceNumber,
        priority: 1,
      },
      transReceivedAmount: {
        name: TRANSACTION_DETAILS_KEYS.transReceivedAmount,
        priority: 2,
        isAmount: true,
      },
      transDate: {
        name: TRANSACTION_DETAILS_KEYS.transDate,
        priority: 3,
        isDate: true,
      },
      moneyAccount: {
        name: TRANSACTION_DETAILS_KEYS.moneyAccount,
        priority: 4,
      },
    },
    41: {
      transReceivedAmount: {
        name: 'Jumlah penyesuaian',
        priority: 1,
        isAmount: true,
      },
      transDate: {
        name: TRANSACTION_DETAILS_KEYS.transDate,
        priority: 2,
        isDate: true,
      },
      moneyAccount: {
        name: TRANSACTION_DETAILS_KEYS.moneyAccount,
        priority: 3,
      },
    },
  },
};

export const APP_TOUR_INTERACTION_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'STARTED',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
};

export const PRODUCT_CODE_MILVIK = 'BP-18';

export const APP_TOUR_FLOWS = {
  TRANSACTION: 'TRANSACTION',
};

export const LOAN_META_INFO = [
  'Pilih jumlah dana yang kamu butuhkan',
  'Tunggu proses pengecekan oleh tempat kerjamu',
  'Setelah pengajuan disetujui, cek uang masuk di rekening terdaftar (utama) di GajiGesa',
  'Pada hari gajian, ada pemotongan gaji untuk cicilan e-Kasbon sesuai dengan tenor',
  'Ajukan lagi e-Kasbon jika cicilanmu sudah selesai',
  'Baca syarat dan ketentuan selengkapnya di sini',
];

export const LOAN_ATTENTION_TEXT = [
  'Tim HR tempat kerjamu akan mengecek pengajuanmu terlebih dahulu',
  'Dana ini akan ditransfer ke rekening bank terdaftar (utama) di GajiGesa',
  'Proses verifikasi dan transfer membutuhkan waktu maks. 1 x 24 jam kerja',
  'Pembayaran cicilan akan langsung memotong gajimu',
];

export const LOAN_APPLICATION_STATUS = {
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  DISBURSED: 'DISBURSED',
  FULLY_PAID: 'FULLY_PAID',
};

export const LOAN_TERMS = [
  'Saya menyetujui bahwa persetujuan atas layanan Kasbon adalah kewenangan penuh perusahaan tempat saya bekerja.',
  'Saya bersedia untuk membayar biaya layanan dan cicilan Kasbon yang muncul atas penggunaan layanan ini.',
  'Saya menyetujui biaya layanan akan dikurangi secara langsung dari jumlah dana yang saya ajukan dan saya akan menerima dana di rekening saya sejumlah yang telah dikurangi biaya layanan yang muncul.',
  'Saya menyetujui gaji yang akan saya terima dari perusahaan pada setiap tanggal gajian akan dipotong sejumlah total transaksi yang saya lakukan melalui layanan ini.',
  'Saya akan bertanggung jawab untuk penyelesaian kewajiban layanan Kasbon ini (apabila masih ada), terlepas dari status hubungan kerja saya dengan perusahaan.',
];

export const PHONE_ENROLLMENT_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const PHONE_ENROLL_LINK = 'https://bit.ly/verifikasihp_gajigesa';

export const PHONE_ENROLL_INFO_TEXT =
  '1. Permudah transaksi beli pulsa, paket data, dan top-up e-wallet untuk orang lain dengan mendaftarkan nomornya\n2. Nomor ini akan diverifikasi manual oleh tim GajiGesa';

export const BP_NAMES = {
  'bp-10': 'pulsa',
  'bp-15': 'emoney',
  'bp-08': 'pascabayar',
};

export const SELECTED_NOMINATION_ZAKAT = {
  categoryId: 'BP-29',
  categoryPrefix: 'BP-29',
  code: 'ZAKAT50',
};

export const LOGIN_INFO_SHEET_CONTENT = {
  [LOGIN_INFO_SHEET_KEYS['GG-OTP-08']]: {
    title: 'GG_429_TITLE',
    description: 'GG_429_DESCRIPTION',
    buttonText: 'GG_429_BUTTON_TEXT',
    otpScreenError: 'GG_429_OTP_SCREEN_ERROR',
    icon: SVG_ICONS.bottomSheet.waiting,
  },
  [LOGIN_INFO_SHEET_KEYS['GG-500']]: {
    title: 'GG_500_TITLE',
    description: 'GG_500_DESCRIPTION',
    buttonText: 'GG_500_BUTTON_TEXT',
    icon: SVG_ICONS.bottomSheet.comeback,
  },
  [LOGIN_INFO_SHEET_KEYS['GG-EMP-02']]: {
    title: 'GG_4031_TITLE',
    description: 'GG_4031_DESCRIPTION',
    buttonText: 'GG_4031_BUTTON_TEXT',
    icon: SVG_ICONS.bottomSheet.suspend,
  },
  [LOGIN_INFO_SHEET_KEYS['GG-4032']]: {
    title: 'GG_4032_TITLE',
    description: 'GG_4032_DESCRIPTION',
    buttonText: 'GG_4032_BUTTON_TEXT',
    icon: SVG_ICONS.bottomSheet.suspend,
  },
  [LOGIN_INFO_SHEET_KEYS['GG-4033']]: {
    title: 'GG_4033_TITLE',
    description: 'GG_4033_DESCRIPTION',
    buttonText: 'GG_4033_BUTTON_TEXT',
    icon: SVG_ICONS.bottomSheet.suspend,
  },
  [LOGIN_INFO_SHEET_KEYS['GG-OTP-07']]: {
    title: 'GG_404_TITLE',
    description: 'GG_404_DESCRIPTION',
    description2: 'GG_404_DESCRIPTION2',
    buttonText: 'GG_404_BUTTON_TEXT',
    buttonText2: 'GG_404_BUTTON_TEXT2',
    icon: SVG_ICONS.bottomSheet.unregistered,
    hasTwoButtons: true,
  },
  [LOGIN_INFO_SHEET_KEYS.IN_PROCESS]: {
    title: 'IN_PROCESS_TITLE',
    description: 'IN_PROCESS_DESCRIPTION',
    buttonText: 'IN_PROCESS_BUTTONTEXT',
    icon: SVG_ICONS.bottomSheet.error,
  },
  [LOGIN_INFO_SHEET_KEYS.REGISTERED_USER]: {
    title: 'REGISTERED_USER_TITLE',
    description: 'REGISTERED_USER_DESCRIPTION',
    buttonText: 'REGISTERED_USER_BUTTONTEXT',
    icon: SVG_ICONS.bottomSheet.registered,
  },
  [LOGIN_INFO_SHEET_KEYS.RESEND_OTP]: {
    title: 'RESEND_OTP_TITLE',
    description: 'RESEND_OTP_DESCRIPTION',
    description2: 'RESEND_OTP_DESCRIPTION2',
    buttonText: 'RESEND_OTP_BUTTONTEXT',
    icon: SVG_ICONS.bottomSheet.otp,
  },
  [LOGIN_INFO_SHEET_KEYS.LOGOUT]: {
    title: 'LOGOUT_TITLE',
    description: 'LOGOUT_DESCRIPTION',
    buttonText: 'LOGOUT_BUTTONTEXT',
    buttonText2: 'LOGOUT_BUTTONTEXT2',
    hasTwoButtons: true,
    icon: SVG_ICONS.bottomSheet.logout,
  },
};

export const CODEPUSH_TYPE = {
  MANDATORY: 'MANDATORY',
  OPTIONAL: 'OPTIONAL',
};

// export const accountsTheme = [
//   { icon: SVG_ICONS.dynamicAccounts.salaryAccount, color: colors.secondary.softOrchid },
//   { icon: SVG_ICONS.coin3, color: colors.warning.softSunflower },
//   { icon: SVG_ICONS.dynamicAccounts.sdAccount, color: colors.variants.softClover },
//   { icon: SVG_ICONS.dynamicAccounts.workAllowanceIcon, color: colors.primary.softCarnation },
//   { icon: SVG_ICONS.dynamicAccounts.uangKas, color: colors.warning.softSunflower },
// ];

export const VOUCHER_TEXT_CONSTANTS = {
  grab: {
    text1: ['1 (satu) buah voucher digital Grab untuk digunakan bertransaksi via aplikasi Grab', sVoucherText1],
    text2: [
      sVoucherText2,
      'Kode voucher GrabGifts yang sudah dibeli di GajiGesa tidak dapat dikembalikan atau ditukar. Jika kode voucher GrabGifts sudah expired, kode tidak dapat ditukarkan dengan kode baru.',
      'Kode voucher GrabGifts hanya berlaku untuk 1 (satu) kali perjalanan. Sisa balance dari kode voucher yang tidak terpakai akan hangus dan tidak dapat dikembalikan ke akun OVO Anda.',
      'Layanan Grab yang dapat digunakan untuk kode voucher GrabGifts adalah GrabCar, GrabCar Plus, GrabCar 6-Seater, GrabBike.',
      'Jika kode tidak dapat dipakai, Anda dapat menghubungi Customer Service Grab di nomor telepon 021-80648777 atau e-mail support.id@grab.com.',
      sVoucherText3,
      sVoucherText4,
      'Dengan membeli voucher ini, pengguna dianggap mengerti dan menyetujui semua syarat & ketentuan berlaku',
    ],
    text3: ['Masuk ke aplikasi Grab dan pilih Car atau Bike.', sVoucherText5, sVoucherText6, sVoucherText7],
  },
  'Go Car': {
    text1: [
      '1 (satu) buah voucher digital transaksi GoCar untuk digunakan bertransaksi via aplikasi Gojek',
      sVoucherText8,
      sVoucherText1,
    ],
    text2: [
      sVoucherText2,
      sVoucherText9,
      'Kode voucher hanya berlaku untuk 1 (satu) kali transaksi dengan GoCar.',
      'Jika harga transaksi lebih kecil dari nominal voucher, tidak ada pengembalian kelebihan biaya transaksi GoCar.',
      sVoucherText10,
      sVoucherText11,
      sVoucherText16,
      sVoucherText17,
      sVoucherText18,
      sVoucherText3,
      sVoucherText4,
      sVoucherText13,
    ],
    text3: [
      'Masuk ke aplikasi Gojek dan pilih GoCar.',
      'Masukkan lokasi penjemputan, lalu klik Promo. ',
      sVoucherText19,
      sVoucherText7,
    ],
  },
  'Go Food': {
    text1: [
      '1 (satu) buah voucher digital transaksi GoFood untuk digunakan bertransaksi via aplikasi Gojek',
      sVoucherText8,
      sVoucherText1,
    ],
    text2: [
      sVoucherText2,
      sVoucherText9,
      'Kode voucher hanya berlaku untuk 1 (satu) kali transaksi dengan GoFood.',
      'Jika harga transaksi lebih kecil dari nominal voucher, tidak ada pengembalian kelebihan biaya transaksi GoFood.',
      sVoucherText10,
      sVoucherText11,
      sVoucherText16,
      sVoucherText17,
      sVoucherText18,
      sVoucherText3,
      sVoucherText4,
      sVoucherText13,
    ],
    text3: ['Masuk ke aplikasi Gojek dan pilih GoFood.', sVoucherText5, sVoucherText19, sVoucherText7],
  },
  'Go Ride': {
    text1: [
      '1 (satu) buah voucher digital transaksi GoRide untuk digunakan bertransaksi via aplikasi Gojek',
      sVoucherText8,
      sVoucherText1,
    ],
    text2: [
      sVoucherText2,
      sVoucherText9,
      'Kode voucher hanya berlaku untuk 1 (satu) kali transaksi dengan GoRide.',
      'Jika harga transaksi lebih kecil dari nominal voucher, tidak ada pengembalian kelebihan biaya transaksi GoRide.',
      sVoucherText10,
      sVoucherText11,
      sVoucherText16,
      sVoucherText17,
      sVoucherText18,
      sVoucherText3,
      sVoucherText4,
      sVoucherText13,
    ],
    text3: ['Masuk ke aplikasi Gojek dan pilih GoRide.', sVoucherText5, sVoucherText19, sVoucherText7],
  },
  'Kopi Dari Pada': {
    text1: [
      '1 (satu) buah voucher digital transaksi Kopi Dari Pada senilai Rp30.000,- hanya untuk digunakan bertransaksi via aplikasi Hangry',
      sVoucherText1,
    ],
    text2: [
      sVoucherText2,
      sVoucherText9,
      sVoucherText20,
      sVoucherText21,
      sVoucherText22,
      sVoucherText23,
      sVoucherText15,
      'Voucher hanya berlaku untuk semua produk Kopi Dari Pada.',
      sVoucherText24,
      sVoucherText14,
      sVoucherText12,
      sVoucherText4,
      sVoucherText13,
    ],
    text3: [
      sVoucherText25,
      sVoucherText26,
      'Pilih produk Kopi Dari Pada yang kamu inginkan. Jika sudah, klik "Lihat Keranjang" untuk melihat isi keranjangmu.',
      sVoucherText27,
      sVoucherText28,
      sVoucherText29,
      sVoucherText30,
      sVoucherText31,
    ],
  },
  Kopitiam: {
    text1: ['1 (satu) buah voucher Kopitiam untuk makan di tempat.', sVoucherText32, sVoucherText1],
    text2: [
      sVoucherText2,
      sVoucherText9,
      sVoucherText33,
      sVoucherText34,
      sVoucherText35,
      'E-Voucher ini tidak dapat dipakai di Kopitiam Go!',
      'Maksimum pemakaian 5 (lima) E-Voucher dalam sekali pembelanjaan.',
      sVoucherText45,
      sVoucherText14,
      sVoucherText12,
      sVoucherText4,
      sVoucherText13,
    ],
    text3: [sVoucherText36, sVoucherText37, sVoucherText38, sVoucherText39],
  },
  KFC: {
    text1: ['1 (satu) buah voucher transaksi KFC untuk pembelian langsung di store.', sVoucherText32, sVoucherText1],
    text2: [
      sVoucherText2,
      sVoucherText9,
      sVoucherText33,
      sVoucherText34,
      sVoucherText35,
      sVoucherText45,
      sVoucherText14,
      sVoucherText12,
      sVoucherText4,
      sVoucherText13,
    ],
    text3: [sVoucherText36, sVoucherText37, sVoucherText38, sVoucherText39],
  },
  Yoshinoya: {
    text1: [
      '1 (satu) buah voucher transaksi Yoshinoya untuk pembelian Layanan Food Delivery via Whatsapp.',
      sVoucherText32,
      sVoucherText1,
    ],
    text2: [
      sVoucherText2,
      sVoucherText9,
      sVoucherText33,
      sVoucherText34,
      sVoucherText15,
      'Voucher tidak dapat diuangkan.',
      'Jika jumlah total pembelian kurang dari nilai Voucher, maka Customer tidak bisa untuk meminta selisih pembayarannya kepada Yoshinoya.',
      'Jika jumlah total pembelian melebihi nilai voucher, maka Customer membayar selisih pembayaran dengan transfer kepada Yoshinoya.',
      sVoucherText14,
      sVoucherText12,
      sVoucherText4,
      sVoucherText13,
    ],
    text3: [
      'Hubungi Delivery Service Yoshinoya menggunakan Whatsapp di nomor +62 858-1150-0566',
      'Pilih menu makanan yang ingin dipesan.',
      'Sebut kode unik e-voucher kepada Petugas Yoshinoya atau kirim screenshoot redeem page voucher.',
      'Petugas Yoshinoya akan menginput Kode Voucher ke dalam sistem terlebih dahulu.',
      'Jika berhasil, maka pesanan tersebut akan dilanjutkan, dan dipastikan untuk diproses.',
      'Jika tidak berhasil, Petugas yoshinoya akan langsung memberitahu Customer mengapa Voucher tersebut tidak dapat di-redeem/ditukarkan.',
    ],
  },
  'Voucher Ta Wan': {
    text1: [
      '1 (satu) buah voucher diskon Ta Wan senilai untuk digunakan bertransaksi langsung di restoran',
      sVoucherText1,
    ],
    text2: [
      sVoucherText2,
      sVoucherText9,
      'Masa berlaku voucher ini akan diberikan setelah voucher berhasil dibeli.',
      'Voucher berlaku setiap hari, termasuk hari libur nasional dan sudah termasuk pajak dan layanan.',
      'Voucher tidak dapat digabungkan dengan promo lainnya',
      'Jika total transaksi lebih kecil daripada nominal voucher, maka sisa nilai voucher akan hangus.',
      'Pihak Ta Wan berhak untuk membatalkan transaksi bila pengguna voucher tidak sesuai dengan syarat dan ketentuan yang berlaku.',
      sVoucherText14,
      sVoucherText12,
      sVoucherText4,
      sVoucherText13,
    ],
    text3: [sVoucherText36, sVoucherText37, sVoucherText38, sVoucherText39],
  },
  'Moon Chicken': {
    text1: [
      '1 (satu) buah voucher digital transaksi Moon Chicken senilai hanya untuk digunakan bertransaksi via aplikasi Hangry',
      sVoucherText1,
    ],
    text2: [
      sVoucherText2,
      sVoucherText9,
      'Promo hanya berlaku dengan pembelian minimal Rp150.000.',
      sVoucherText21,
      sVoucherText22,
      sVoucherText23,
      sVoucherText15,
      'Voucher hanya berlaku untuk semua produk Moon Chicken.',
      sVoucherText24,
      sVoucherText14,
      sVoucherText12,
      sVoucherText4,
      sVoucherText13,
    ],
    text3: [
      sVoucherText25,
      sVoucherText26,
      'Pilih produk Moon Chicken yang kamu inginkan. Jika sudah, klik "Lihat Keranjang" untuk melihat isi keranjangmu.',
      sVoucherText27,
      sVoucherText28,
      sVoucherText29,
      sVoucherText30,
      sVoucherText31,
    ],
  },
  'Accha Resto': {
    text1: [
      '1 (satu) buah voucher digital transaksi Accha Resto hanya untuk digunakan bertransaksi via aplikasi Hangry',
      sVoucherText1,
    ],
    text2: [
      sVoucherText2,
      sVoucherText9,
      'Voucher hanya dapat digunakan untuk daerah Jabodetabek.',
      'Masa berlaku voucher dapat dilihat di halaman rincian pesanan.',
      'Voucher yang tidak digunakan dalam periode penukaran akan dianggap hangus',
      'Voucher tidak dapat ditukar, dikembalikan, serta digabungkan dengan promosi lainnya',
      'Pengguna bertanggung jawab atas perlindungan dan kerahasiaan kode voucher',
      sVoucherText14,
      sVoucherText12,
      sVoucherText4,
      sVoucherText13,
    ],
    text3: [
      'Lakukan transaksi voucher yang kamu mau',
      'Cek kodemu di daftar transaksi',
      'Klaim kode voucher ke nomor CS Accha (+62 812-1997-1882)',
      'Pesan makananmu sekarang!',
    ],
  },
  'Nasi Ayam Kulit Bude Sari': {
    text1: [
      '1 (satu) buah voucher digital transaksi Nasi Ayam & Kulit Bude Sari hanya untuk digunakan bertransaksi via aplikasi Hangry',
      sVoucherText1,
    ],
    text2: [
      sVoucherText2,
      sVoucherText9,
      sVoucherText20,
      sVoucherText21,
      sVoucherText22,
      sVoucherText23,
      sVoucherText15,
      'Voucher hanya berlaku untuk semua produk Nasi Ayam & Kulit Bude Sari.',
      sVoucherText24,
      sVoucherText14,
      sVoucherText12,
      sVoucherText4,
      sVoucherText13,
    ],
    text3: [
      sVoucherText25,
      'Lakukan sign up untuk pengguna baru atau log in dengan nomor hp yang terhubung dengan akun Hangry-mu. ',
      'Pilih produk Nasi Ayam & Kulit Bude Sari yang kamu inginkan. Jika sudah, klik "Lihat Keranjang" untuk melihat isi keranjangmu.',
      sVoucherText27,
      sVoucherText28,
      sVoucherText29,
      sVoucherText30,
      sVoucherText31,
    ],
  },
  'Fish Streat': {
    text1: [
      '1 (satu) buah voucher transaksi Fish Streat untuk pembelian langsung di tempat.',
      sVoucherText32,
      sVoucherText1,
    ],
    text2: [
      sVoucherText2,
      sVoucherText9,
      sVoucherText33,
      sVoucherText34,
      sVoucherText15,
      sVoucherText46,
      sVoucherText14,
      sVoucherText12,
      sVoucherText4,
      sVoucherText13,
    ],
    text3: [sVoucherText36, sVoucherText37, sVoucherText38, sVoucherText39],
  },
  Kulina: {
    text1: [
      '1 (satu) buah voucher digital transaksi Kulina untuk digunakan bertransaksi via aplikasi atau website Kulina.id',
      sVoucherText1,
    ],
    text2: [
      sVoucherText2,
      sVoucherText9,
      'Tidak ada minimum pembelian untuk promo ini.',
      'Kode hanya dapat digunakan untuk 1 (satu) kali transaksi. Apabila voucher hanya digunakan sebagian, maka sisa voucher akan hangus.',
      'Pembelian hanya berlaku di aplikasi atau website Kulina.',
      sVoucherText15,
      'Voucher tidak dapat ditukarkan dengan uang tunai dalam kondisi apa pun.',
      sVoucherText24,
      sVoucherText14,
      sVoucherText12,
      sVoucherText4,
      sVoucherText13,
      'Info lebih lanjut hubungi Customer Service Kulina melalui e-mail ke sales@kulina.id.',
    ],
    text3: [
      'Buka website kulina.id atau download aplikasi Kulina di playstore/appstore',
      'Tentukan atau pilih alamat pengantaran',
      'Pilih menu (Basic Lunch)',
      'Pilih berapa box dan berapa jumlah hari ingin berlangganan',
      'Pilih tambahkan keranjang',
      'Pilih "Lihat Keranjang"',
      'Pilih Checkout untuk ke pembayaran dan konfirmasi alamat yang tertera',
      'Pilih "Punya Kode Promo"',
      'Masukkan Kode Promo di "punya kode promo?" dan pilih gunakan',
      'Klik Metode pembayaran untuk pembayarannya',
      'Selesai! Selamat menikmati Kulina!',
    ],
  },
  'San Gyu': {
    text1: [
      '1 (satu) buah voucher digital transaksi Sangyu hanya untuk digunakan bertransaksi via aplikasi Hangry',
      sVoucherText1,
    ],
    text2: [
      sVoucherText2,
      sVoucherText9,
      sVoucherText20,
      sVoucherText21,
      sVoucherText22,
      sVoucherText23,
      sVoucherText15,
      'Voucher hanya berlaku untuk semua produk Sangyu.',
      sVoucherText24,
      sVoucherText14,
      sVoucherText12,
      sVoucherText4,
      sVoucherText13,
    ],
    text3: [
      sVoucherText25,
      sVoucherText26,
      'Pilih produk Sangyu yang kamu inginkan. Jika sudah, klik "Lihat Keranjang" untuk melihat isi keranjangmu.',
      sVoucherText27,
      sVoucherText28,
      sVoucherText29,
      sVoucherText30,
      sVoucherText31,
    ],
  },
  'Carrefour Transmart': {
    text1: [
      '1 (satu) buah voucher belanja Carrefour Transmart untuk pembelian langsung di store.',
      sVoucherText32,
      sVoucherText1,
    ],
    text2: [
      sVoucherText2,
      sVoucherText9,
      sVoucherText33,
      sVoucherText34,
      sVoucherText35,
      sVoucherText45,
      sVoucherText14,
      sVoucherText12,
      sVoucherText4,
      sVoucherText13,
    ],
    text3: [sVoucherText36, sVoucherText37, sVoucherText38, sVoucherText39],
  },
  'Voucher Alfamart': {
    text1: [
      'Voucher Digital Alfamart adalah Voucher Belanja non fisik yang dapat digunakan untuk transaksi apa saja kecuali payment e-commerce',
      'Apabila dalam kur  un waktu 3 jam setelah pelanggan berhasil melakukan pembelian voucher namun pelanggan belum menerima link voucher melalui SMS pada nomor terdaftar di GajiGesa, pelanggan dapat menghubungin Contact Center GajiGesa',
    ],
    text2: [sVoucherText40, sVoucherText41, sVoucherText42, sVoucherText43, sVoucherText44],
    text3: [
      'Lakukan transaksi di store yang kamu pilih.',
      'Pastikan koneksi internet stabil ketika menukarkan voucher digital.',
      'Pastikan penukaran dilakukan saat voucher digital masih berlaku (disarankan melakukan penukaran 7 hari setelah pembelian voucher).',
      'Masa validasi kode voucher setelah dibuka linknya hanya 15 menit. Pastikan untuk menggunakannya saat akan melakukan pembayaran di merchant.',
      'Setelah link terbuka, pindai QR code yang diterima pada mesin pembayaran.',
    ],
  },
  'Voucher Grab Transport': {
    text1: [
      'Voucher Digital Grab Transport adalah Voucher non fisik yang dapat digunakan untuk melakukan transaksi Grab Car maupun Grab Bike',
      sVoucherText47,
    ],
    text2: [
      sVoucherText40,
      'Kode voucher hanya dapat digunakan sekali',
      'Disarankan untuk menukarkan kode voucher maksimal 7 hari dari tanggal pembelian voucher',
      sVoucherText46,
      'Voucher tidak dapat digabungkan dengan promo lain',
    ],
    text3: [
      'Masuk ke aplikasi Grab dan pilih Car atau Bike.',
      'Masukkan lokasi penjemputan lalu klik promo.',
      sVoucherText6,
      'Jumlah tarif akan dipotong sesuai dengan voucher yang dipilih',
    ],
  },
  'Voucher Indomaret': {
    text1: [
      'Voucher Digital Indomaret adalah Voucher Belanja non fisik yang dapat digunakan untuk transaksi apa saja kecuali payment e-commerce',
      'Apabila dalam kurun waktu 3 jam setelah pelanggan berhasil melakukan pembelian voucher namun pelanggan belum menerima link voucher melalui SMS pada nomor terdaftar di GajiGesa, pelanggan dapat menghubungin Contact Center GajiGesa',
    ],
    text2: [sVoucherText40, sVoucherText41, sVoucherText42, sVoucherText43, sVoucherText44],
    text3: [
      'Lakukan transaksi di store yang kamu pilih.',
      'Pastikan koneksi internet stabil ketika menukarkan voucher digital.',
      'Pastikan penukaran dilakukan saat voucher digital masih berlaku (disarankan melakukan penukaran 7 hari setelah pembelian voucher).',
      'Masa validasi kode voucher setelah dibuka linknya hanya 15 menit. Pastikan untuk menggunakannya saat akan melakukan pembayaran di merchant.',
      'Setelah link terbuka, pindai QR code yang diterima pada mesin pembayaran.',
    ],
  },
  'Voucher Grab Food': {
    text1: [
      'Voucher Digital Grab Food adalah Voucher non fisik yang dapat digunakan untuk melakukan transaksi Grab Food',
      sVoucherText47,
    ],
    text2: [
      sVoucherText40,
      'Kode voucher hanya dapat digunakan sekali',
      'Disarankan untuk menukarkan kode voucher maksimal 7 hari dari tanggal pembelian voucher',
      sVoucherText46,
      'Voucher tidak dapat dikombinasikan dengan promo lain',
    ],
    text3: [
      'Masuk ke aplikasi Grab dan pilih Food.',
      'Masukkan lokasi delivery, restoran dan menu.',
      sVoucherText6,
      'Jumlah tarif akan dipotong sesuai dengan voucher yang dipilih',
    ],
  },
  'Voucher Halodoc': {
    text1: [
      'Voucher Digital Halodoc adalah Voucher non fisik yang dapat digunakan untuk melakukan Rapid Test COVID-19 yang valid digunakan di semua lokasi Rapid Test.',
      sVoucherText47,
    ],
    text2: [
      sVoucherText40,
      'Kode voucher yang diberikan hanya berlaku untuk satu kali Rapid Test.',
      'Voucher berlaku selama 14 hari setelah pembelian voucher',
      'Voucher berlaku untuk semua lokasi Rapid Test and semua partner pada aplikasi Halodoc',
      'Voucher tidak dapat digabungkan dengan promo lain',
      'Voucher dapat dibeli lebih dari satu kali untuk setiap akun',
    ],
    text3: [
      'Buka aplikasi Halodoc dan pilih menu COVID-19.',
      'Pilih lokasi, tanggal dan waktu Rapid Test',
      'Pilih nama pasien.',
      'Isi nama dan tanggal lahir kemudian upload kartu identitas (KTP, SIM atau Paspor).',
      'Masukkan kode voucher dan pilih metode pembayaran.',
      'Selesaikan proses pembayaran (biaya akan terpotong melalui kode voucher) dan Rapid Test-mu akan terdaftar.',
    ],
  },
  'Voucher Sayur Box': {
    text1: [
      'Voucher Digital Sayur Box adalah Voucher non fisik yang dapat digunakan untuk berbelanja di outlet Sayurbox',
      sVoucherText47,
    ],
    text2: [sVoucherText40, sVoucherText41, sVoucherText42, sVoucherText43, sVoucherText44],
    text3: [
      'Download Sayurbox App di Play Store (Android) atau App Store (IOS).',
      'Cari buah dan sayur yang kamu inginkan',
      'Klik “Voucher” di halaman Checkout.',
      'Buka link yang dikirimkan Gajigesa di SMS, catat kode vouchernya.',
      'Masukkan kode voucher pada aplikasi Sayurbox dan klik Pakai.',
      'Lanjut ke halaman pembayaran dan selesaikan prosesnya.',
      'Check order status di halaman “My Order”.',
      'Untuk info lengkap buka https://www.sayurbox.com/shop',
    ],
  },
};

export const KTP_INFO_MODAL = {
  'GG-USER-05': {
    title: 'KTP_MODAL_TITLE_1',
    description: 'KTP_MODAL_DESCRIPTION_1',
    buttonText: 'KTP_MODAL_TEXT_1',
    hasTwoButtons: false,
    icon: SVG_ICONS.bottomSheet.dataFound,
  },
  KTP_ALREADY_REGISTERED: {
    title: 'KTP_MODAL_TITLE_2',
    description: 'KTP_MODAL_DESCRIPTION_2',
    buttonText: 'KTP_MODAL_TEXT_2',
    hasTwoButtons: false,
    icon: SVG_ICONS.bottomSheet.waiting,
  },
};

export const SECURITY_PIN_INFO_MODAL = {
  GG_01_FALLBACK: {
    title: 'SECURITY_PIN_FALLBACK_TITLE',
    description: 'SECURITY_PIN_FALLBACK_DESCRIPTION',
    buttonText: 'SECURITY_PIN_BUTTON_OK',
    hasTwoButtons: false,
    icon: SVG_ICONS.bottomSheet.success,
  },
  GG_01: {
    title: 'SECURITY_PIN_GG_01_TITLE',
    description: 'SECURITY_PIN_GG_01_DESCRIPTION',
    buttonText: 'SECURITY_PIN_GG_01_BUTTON1',
    buttonText2: 'SECURITY_PIN_GG_01_BUTTON2',
    hasTwoButtons: true,
    icon: SVG_ICONS.bottomSheet.success,
  },
  GG_01_PHONE_ENROLL: {
    title: 'SECURITY_PIN_GG_01_TITLE',
    description: 'SECURITY_PIN_GG_01_PHONE_ENROLL_DESCRIPTION',
    buttonText: 'SECURITY_PIN_ACTIVE_PHONE_ENROLL_BUTTON1',
    hasTwoButtons: false,
    icon: SVG_ICONS.bottomSheet.success,
  },
  GG_01_BANK_ENROLL: {
    title: 'SECURITY_PIN_GG_01_TITLE',
    description: 'SECURITY_PIN_GG_01_BANK_ENROLL_DESCRIPTION',
    buttonText: 'SECURITY_PIN_ACTIVE_BANK_ENROLL_BUTTON',
    hasTwoButtons: false,
    icon: SVG_ICONS.bottomSheet.success,
  },

  ACTIVE_01: {
    title: 'SECURITY_PIN_ACTIVE_01_TITLE',
    description: null,
    buttonText: 'SECURITY_PIN_ACTIVE_01_BUTTON1',
    buttonText2: 'SECURITY_PIN_ACTIVE_01_BUTTON2',
    hasTwoButtons: true,
    icon: SVG_ICONS.bottomSheet.pinLock,
  },
  ACTIVE_01_PHONE_ENROLL: {
    title: 'SECURITY_PIN_ACTIVE_01_TITLE',
    description: null,
    buttonText: 'SECURITY_PIN_ACTIVE_PHONE_ENROLL_BUTTON1',
    hasTwoButtons: false,
    icon: SVG_ICONS.bottomSheet.pinLock,
  },
  ACTIVE_01_BANK_ENROLL: {
    title: 'SECURITY_PIN_ACTIVE_01_TITLE',
    description: null,
    buttonText: 'SECURITY_PIN_ACTIVE_BANK_ENROLL_BUTTON',
    hasTwoButtons: false,
    icon: SVG_ICONS.bottomSheet.pinLock,
  },

  THREE_RETRY: {
    title: 'THREE_RETRY_TITLE',
    description: 'THREE_RETRY_DESCRIPTION',
    buttonText: 'THREE_RETRY_BUTTONTEXT1',
    hasTwoButtons: false,
    icon: SVG_ICONS.bottomSheet.logout,
  },
  THREE_RETRY_VERIFICATION: {
    title: 'THREE_RETRY_VERIFICATION_TITLE',
    description: 'THREE_RETRY_VERIFICATION_DESCRIPTION',
    buttonText: 'THREE_RETRY_VERIFICATION_BUTTONTEXT1',
    buttonText2: 'THREE_RETRY_VERIFICATION_BUTTONTEXT2',
    hasTwoButtons: true,
    icon: SVG_ICONS.bottomSheet.logout,
  },
  BEFORE_CREATE_PIN: {
    title: 'BEFORE_CREATE_PIN_TITLE',
    description: 'BEFORE_CREATE_PIN_DESCRIPTION',
    buttonText: 'THREE_RETRY_VERIFICATION_BUTTONTEXT1',
    buttonText2: 'BEFORE_CREATE_PIN_BUTTONTEXT2',
    hasTwoButtons: true,
    icon: SVG_ICONS.bottomSheet.pinLock,
  },
  NEED_CREATE_PIN: {
    title: 'NEED_CREATE_PIN_TITLE',
    description: 'NEED_CREATE_PIN_DESCRIPTION',
    buttonText: 'NEED_CREATE_PIN_BUTTONTEXT',
    hasTwoButtons: false,
    icon: SVG_ICONS.bottomSheet.pinLock,
  },
  VERIFY_PIN_LOCKED: {
    title: 'VERIFY_PIN_LOCKED_TITLE',
    description: 'VERIFY_PIN_LOCKED_DESCRIPTION',
    buttonText: 'VERIFY_PIN_LOCKED_BUTTONTEXT',
    hasTwoButtons: false,
    icon: SVG_ICONS.bottomSheet.pinLock,
  },
  PHONE_CHANGE_SUCCESS: {
    title: 'PHONE_CHANGE_SUCCESS_TITLE',
    description: 'PHONE_CHANGE_SUCCESS_DESCRIPTION',
    buttonText: 'PHONE_CHANGE_SUCCESS_BUTTONTEXT',
    hasTwoButtons: false,
    icon: SVG_ICONS.bottomSheet.success,
  },
  USER_BACK_PREVENT: {
    title: 'USER_BANK_PREVENT_TITLE',
    description: 'USER_BANK_PREVENT_DESCRIPTION',
    buttonText: 'USER_BANK_PREVENT_BUTTONTEXT1',
    buttonText2: 'USER_BANK_PREVENT_BUTTONTEXT2',
    hasTwoButtons: true,
    icon: '',
  },
};

export const BOTTOMSHEET_CONTENT = {
  [BOTTONSHEET_KEYS.BANK_DELETE]: {
    title: 'BANK_DELETE_TITLE',
    description: 'BANK_DELETE_DESCRIPTION',
    buttonText: 'BANK_DELETE_BUTTONTEXT1',
    buttonText2: 'BANK_DELETE_BUTTONTEXT2',
    hasTwoButtons: true,
    icon: SVG_ICONS.deleteModalIcon,
  },
  [BOTTONSHEET_KEYS.MAX_LIMIT]: {
    title: 'BANK_MAX_LIMIT_TITLE',
    description: 'BANK_MAX_LIMIT_DESCRIPTION',
    buttonText: 'BANK_MAX_BUTTONTEXT1',
    hasTwoButtons: false,
    icon: SVG_ICONS.bottomSheet.logout,
  },
  [BOTTONSHEET_KEYS.DUPLICATE_ENTRY]: {
    title: 'BANK_DUPLICATE_ENTRY_TITLE',
    description: 'BANK_DUPLICATE_ENTRY_DESCRIPTION',
    buttonText: 'BANK_DUPLICATE_BUTTONTEXT1',
    hasTwoButtons: false,
    icon: SVG_ICONS.duplicateModalIcon,
  },
};

// This is the hyper link on the bank list top right when user comes from transfer flow.
export const addBankTemplate1 = 'template1';
// This is the add button at the profile page with default bank when no banks are added
export const addBankTemplate2 = 'template2';
// This is the footer on the bank list from the profile screen
export const addBankTemplate3 = 'template3';

// This is the button to show when no phone numbers present (PhoneEmptyView)
export const addPhoneTemplate1 = 'template1';
// This is the button to show on the footer of the phone list
export const addPhoneTemplate2 = 'template2';
// This is the hyperlink to add phone from transaction phone list
export const addPhoneTemplate3 = 'template3';

export const analyticsTimeStampFormat = 'DD/MM/YYYY HH:MM:ss';

export const REDIRECTION_TYPES = {
  SCREEN_NAMES: 'screenName',
  DEEPLINK: 'deepLink',
  EXTERNAL_LINK: 'link',
};
